import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Teaser from '../reusable/Teaser';
import { ITeaser } from '../types';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { range } from '../../context/actions/common';
import { getHubExperts } from '../../context/actions/hubs';

interface Props {
  displayGrid?: boolean;
  hubName: string;
}

const FromExperts = ({ displayGrid, hubName }: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [teasers, setTeasers] = useState<ITeaser[]>([]);

  useEffect(() => {
    const handleExperts = async () => {
      setIsLoading(true);

      try {
        const response: any = await getHubExperts(hubName);
        const data = response?.data;
        setTeasers(data);
        setIsLoading(false);
      } catch (err) {
        setTeasers([]);
        setIsLoading(false);
      }
    };
    if (!!hubName) {
      handleExperts();
    }
  }, [hubName]);

  return (
    <div className='section__light'>
      <Container maxWidth='lg' style={{marginTop: '2rem', marginBottom: '4rem'}}>
        <div className='section_title-wrapper'>
          <h3
            className='section_title section_title_new'
            style={{color: `${theme.palette.primary.main}`, margin: '0'}}
          >
              Experten/&#8203;Interviews/&#8203;Podcasts
          </h3>
        </div>
        <Divider style={{height: '4px', marginBottom: '2.5rem'}}/>
        <Grid container spacing={deviceSm ? 2 : 4} justify='center'>
          {isLoading
            ? range(6).map((el: number, key: number) => (
                <Grid key={key} item xs={12} md={!!displayGrid ? 6 : 9}>
                  {key === 0 || (key === 1 && !deviceSm && !!displayGrid) ? null : (
                    <Divider style={{ margin: deviceSm ? '0 0 16px 0' : '0 0 32px 0' }} />
                  )}
                  <TeaserSkeleton />
                </Grid>
              ))
            : teasers.map((teaser: ITeaser, key) => (
                <Grid key={key} item xs={12} md={!!displayGrid ? 6 : 9}>
                  {key === 0 || (key === 1 && !deviceSm && !!displayGrid) ? null : (
                    <Divider style={{ margin: deviceSm ? '0 0 16px 0' : '0 0 32px 0' }} />
                  )}
                  <Teaser teaser={teaser} />
                </Grid>
              ))}
        </Grid>
      </Container>
    </div>
  );
};

export default FromExperts;
