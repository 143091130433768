/* eslint-disable no-unused-vars */
const path = require('path');
const merge = require('lodash/merge');

const requireProcessEnv = (name) => {
  if (!process.env[name]) {
    throw new Error('You must set the ' + name + ' environment variable')
  }
  return process.env[name]
}

const config = {
  all: {
    baseUrl: requireProcessEnv('REACT_APP_API_BASE_URL'),
  },
  test: {},
  development: {},
  production: {}
}

module.exports = merge(config.all, config[config.all.env]);
