import React from 'react';
import { Container } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';

interface Props {}

const Nutzungsbedingungen = (props: Props) => {
  return (
    <div style={{ padding: '3rem 0' }}>
      <Container maxWidth='lg'>
        <h1 className='article_title'>Nutzungsbedingungen</h1>

        <div className='content-wrapper'>
          <p>
            <b>Nutzungsbedingungen und Haftungsausschluss für die Webseite</b>
          </p>

          <div>
            <div>
              <h2>§ 1 Geltungsbereich</h2>
              <p>
                (1)&nbsp;Die nachfolgenden Nutzungsbedingungen gelten für die Nutzung der Webseite (nachfolgend
                "Online-Dienste") der Vidal MMI Germany GmbH, Monzastraße 4, 63225 Langen (nachfolgend "Anbieter"
                genannt).&nbsp;
              </p>
              <p>
                (2)&nbsp;Nutzer sind alle Personen, die Inhalte der Online-Dienste des Anbieters über das Internet
                aufrufen, als Newslettter per E-Mail beziehen oder als App herunterladen und nutzen.
              </p>
              <p>
                (3) Mit&nbsp; Aufruf, Download oder Bezug dieser Online-Dienste des Anbieters erkennt der Nutzer die
                vorliegenden Nutzungsbedingungen an. Ein Vertragsverhältnis zwischen dem Nutzer und dem Anbieter kommt
                hierdurch nicht zustande.
              </p>
              <h2>§ 2 Inhalt und Nutzung</h2>
              <p>
                &nbsp;(1) Die Online-Dienste des Anbieters sind ausschließlich zu Informationszwecken bestimmt und für
                Nutzer prinzipiell kostenlos zugänglich. Die Online-Dienste des Anbieters richten sich insbesondere an
                Patienten, aber auch an deren Angehörige sowie behandelnde Ärzte und medizinisches Fachpersonal.
              </p>
              <p>
                (2) Die innerhalb dieser Online-Dienste bereitgestellten Informationen sind auf keinen Fall Ersatz für
                eine professionelle Beratung oder Behandlung durch ausgebildete und anerkannte Ärzte oder Apotheker und
                vor allem auch keine Kauf- oder Anwendungsempfehlung für Medikamente, Diagnose- oder
                Behandlungsmethoden.
              </p>
              <p>
                (3) Für Unannehmlichkeiten oder Schäden, die Nutzern durch den Gebrauch oder Missbrauch dieser
                Online-Dienste entstehen, kann der Anbieter weder direkt noch indirekt zur Verantwortung gezogen werden.
              </p>
              <p>
                (4) Der Anbieter ist frei in der Gestaltung seiner Online-Dienste und behält sich ausdrücklich vor, die
                angebotenen Online-Dienste und Inhalte ohne gesonderte vorherige Ankündigung zu verändern, zu löschen
                oder zeitweise oder endgültig einzustellen.
              </p>
              <h2>§ 3 Datenschutz</h2>
              <p>
                Der Anbieter nimmt das Thema Datenschutz sehr ernst. Angegebene Daten des Nutzers werden strikt nach den
                Datenschutzbestimmungen des Anbieters und gültigem Recht behandelt. Die vollständigen
                Datenschutzbestimmungen zur Nutzung der Online-Dienste sind unter <a href='/datenschutz'>Datenschutz</a>{' '}
                zu finden.
              </p>
              <h2>§ 4 Urheberrecht</h2>
              <p>
                (1) Sämtliche Texte, Bilder und andere im Rahmen der Online-Dienste veröffentlichten Informationen und
                Daten unterliegen - sofern nicht anders gekennzeichnet - dem Urheberrecht (Copyright). Jede&nbsp; Form
                der Wiedergabe, Vervielfältigung und/oder Modifikation darf nur mit der schriftlichen Genehmigung durch
                den Anbieter erfolgen. Ansonsten behält sich der Anbieter das Recht vor, gerichtlich gegen&nbsp;
                Rechtsverletzungen vorzugehen. Alle Kosten, die durch eine Rechtsverletzung seitens eines Benutzers
                verursacht werden, werden diesem in Rechnung gestellt.
              </p>
              <p>
                (2) Ausgenommen hiervon ist die Nutzung der zur Verfügung gestellten Inhalte zu privaten Zwecken. Dabei
                ist das Herunterladen sowie die vorübergehende Speicherung zu ausschließlich privaten Zwecken auf einem
                Computer oder Bildschirm zulässig. Der Nutzer ist zur Vervielfältigung (zum Beispiel Ausdruck) der
                Inhalte zu privaten Zwecken berechtigt und darf die Inhalte ausschließlich zum eigenen Gebrauch nutzen.
                Diese Berechtigungen gelten nur, wenn Schutzvermerke (Copyright-Vermerke und ähnliches) sowie
                Wiedergaben von Marken und Namen in den Vervielfältigungsstücken unverändert erhalten bleiben.
              </p>
              <p>
                (3) Eine Archivierung und Sammlung von Inhalten ist nur zu privaten und internen Zwecken des Nutzers
                zulässig. Die archivierten Inhalte dürfen Dritten nicht zugänglich sein und nicht zu zusätzlichen
                Verwertungen oder Vervielfältigungen führen.
              </p>
              <p>
                (4) Für weiteren Nutzungen, vor allem für die Vervielfältigung zu gewerblichen Zwecken einschließlich
                der Archivierung, für die Überlassung an oder Verarbeitung durch Dritte für eigene oder fremde Zwecke
                oder zur öffentlichen Wiedergabe oder Verbreitung sowie für die Übersetzung, Bearbeitung oder
                Umarbeitung bedarf es der vorherigen, ausdrücklichen schriftlichen Zustimmung des Anbieters.
              </p>
              <p>
                (5) Eine systematische mechanische Sammlung und Archivierung von Inhalten und Dateien der
                Online-Dienste, insbesondere der Inhalte der Arzneimitteldatenbank des Anbieters, durch
                Computerprogramme (Robots/Crawler) ist explizit untersagt. Einzig davon ausgenommen sind Robots/Crawler
                zur Indizierung von Webseiten für öffentlich zugängliche Suchmaschinen.
              </p>
              <h2>§ 5 Haftungsbeschränkung</h2>
              <h4>(1) Haftung für redaktionelle Beiträge</h4>
              <p>
                Der Anbieter ist gemäß § 7 Abs.1 TMG für eigene Inhalte der angebotenen Online-Dienste nach den
                allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG ist der Anbieter&nbsp; jedoch nicht
                verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
                forschen, die auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur Entfernung oder Sperrung
                der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine
                diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung
                möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen wird der Anbieter diese Inhalte
                umgehend entfernen.
              </p>
              <p>
                Der Anbieter übernimmt keine Gewähr für die Richtigkeit, Vollständigkeit und Aktualität der
                veröffentlichten redaktionellen Beiträge. Die Nutzung der Informationen erfolgt auf eigene Gefahr des
                Nutzers. Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht zwingend
                die Meinung des Anbieters wieder. Der Anbieter macht sich die Meinung ausdrücklich nicht zu Eigen.
              </p>
              <h4>(2) Haftung für Arzneimittelinformationen</h4>
              <p>
                Die Online-Dienste des Anbieters beinhalten pharmazeutischen und medizischen Arzneimittelinformationen.
                Alle Dokumente oder Datenbankinhalte werden vom Anbieter mit größtmöglicher Sorgfalt gesammelt, geprüft
                und verarbeitet.
              </p>
              <p>
                Eine Gewährleistung oder Haftung für die Aktualität, Richtigkeit und Vollständigkeit der für zur
                Verfügung gestellten Dokumente, Informationen oder Datenbankinhalte wird vom Anbieter nicht übernommen.
                Maßgeblich sind die aktuellen Informationsmaterialien der pharmazeutischen Unternehmen sowie etwaige
                Informationen der zuständigen Bundesoberbehörden oder der Europäischen Arzneimittel-Agentur.
              </p>
              <h4>(3) Haftung für Links</h4>
              <p>
                Die Online-Dienste des Anbieters enthalten Links zu externen Webseiten von Drittanbietern, auf deren
                Inhalte der Anbieter selbst keinen Einfluss hat und für die er dementsprechend keine Gewähr übernimmt.
                Für die Inhalte der verlinkten Seiten ist stets der jeweilige Drittanbieter oder Betreiber der Seiten
                verantwortlich.
              </p>
              <p>
                Verlinkte Webseiten werden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft.
                Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente inhaltliche
                Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht
                zumutbar. Bei Bekanntwerden von Rechtsverletzungen wird der Anbieter derartige Links umgehend entfernen.
              </p>
              <h4>(4) Haftung für Werbeanzeigen</h4>
              <p>
                Der Anbieter übernimmt keine Haftung für den Inhalt und die Richtigkeit von Werbeanzeigen.&nbsp;Für den
                Inhalt einer Werbeanzeige als auch für den Inhalt einer beworbenen oder verlinkten Webseite ist einzig
                und allein der jeweilige Werbekunde verantwortlich und haftbar. Bei Bekanntwerden von entsprechenden
                Rechtsverletzungen wird der Anbieter diese Werbeanzeigen umgehend entfernen.
              </p>
              <h4>(5) Haftung für technische Verfügbarkeit</h4>
              <p>
                Der Anbieter bemüht sich, die Online-Dienste und Inhalte technisch stets zugänglich zu halten,
                gewährleistet aber keinen Anspruch auf die ständige Verfügbarkeit und Störungsfreiheit der
                Online-Dienste, insbesondere im Hinblick auf erforderliche Wartungsarbeiten und Ausfälle durch
                technische Störungen.
              </p>
              <p>
                Die Nutzung des Internets erfolgt auf eigene Gefahr des Nutzers. Der Diensteanbieter haftet vor allem
                nicht für den technisch bedingten Ausfall des Internets bzw. des Zugangs zum Internet.
              </p>
              <h2>§ 6 Änderungsvorbehalt</h2>
              <p>
                Der Anbieter hat jederzeit das Recht die Nutzungsbedingungen zu ändern. Diese Änderung wird dann auf der
                Webseite veröffentlicht bzw. den registrierten Benutzern per E-Mail übersandt.
              </p>
              <h2>§ 7 Salvatorische Klausel</h2>
              <p>
                Sind einzelne Formulierungen dieser Nutzungsbedingung nicht mehr ganz oder nicht mehr vollständig
                konform mit der geltenden Rechtslage, so ist davon auszugehen, dass die übrigen Regelungen der
                Nutzungsbedingungen bestehen bleiben.
              </p>
            </div>
            <p>&nbsp;</p>
          </div>
          <div>
            <div>
              <p style={{ fontSize: '1rem' }}>
                Autor: Vidal MMI Germany GmbH
                <br />
                Stand: 01.04.2021
              </p>
            </div>
          </div>

          <div style={{ display: 'flex', margin: '1.5rem -0.5rem -0.5rem -0.5rem' }}>
            <a
              href='https://twitter.com/intent/tweet?url=https%3a%2f%2fwww.mmi.de%2fnutzungsbedingungen&text=Nutzungsbedingungen+%7c+Vidal+MMI&lang=de'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <TwitterIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fwww.mmi.de%2fnutzungsbedingungen'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <FacebookIcon className='icon-button__primary' />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Nutzungsbedingungen;
