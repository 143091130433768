import React, { useEffect, useState } from 'react';
import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import HubCard from '../reusable/HubCard';

import Teaser from '../reusable/Teaser';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { range } from '../../context/actions/common';
import { getHomeTop } from '../../context/actions/home';
import HubCardSkeleton from '../reusable/HubCardSkeleton';
import _ from 'lodash';
import TeaserCard from '../reusable/TeaserCard';
import { useAppContext } from '../../context/AppContext';
import { useCookies } from 'react-cookie';
import { useHistory } from 'react-router-dom';
import { handler } from '../../context/actions/common';

interface Props {}

const TopSection = (props: Props) => {
  const { isLogged, setIsLogged } = useAppContext();
  const [cookies, setCookie, removeCookie] = useCookies();  
  const history = useHistory();  
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [teasers, setTeasers] = useState<any[]>([]);

  useEffect(() => {
    getHomeTop()
      .then(async (res) => {
        const { data }: any = await res;
        setTeasers(data);
        setIsLoading(false);
      })
      .catch((err) => {
        let handledError=handler(err);
        console.log('Oops: ', handledError.error);
        console.log('Oops2: ', handledError);
        setTeasers([]);
        setIsLoading(false);
        if(handledError.statusCode==403){
          console.error("400003");
          setIsLogged(false);
          removeCookie('X-MEDU');
          history.push('/welcome');
        }
      });
  }, []);

  return (
    <div className='section__light'>
      <Container maxWidth='lg'>
        <Grid container spacing={deviceSm ? 2 : 4}>
          <Grid item xs={12} md={7}>
            <Grid container spacing={deviceSm ? 2 : 4}>
              <Grid item xs={12}>
                {isLoading ? (
                  <HubCardSkeleton large />
                ) : teasers?.find((el) => el.placeid === 'LEFT_BIGPIC')?.objecttype?.toLowerCase() === 'contenthub' ? (
                  <HubCard large hub={teasers?.find((el) => el.placeid === 'LEFT_BIGPIC')} />
                ) : (
                  <TeaserCard large teaser={teasers?.find((el) => el.placeid === 'LEFT_BIGPIC')} />
                )}
              </Grid>
              {!deviceSm &&
                (isLoading ? (
                  range(3).map((el: number) => (
                    <Grid item xs={12} sm={4} key={el}>
                      <HubCardSkeleton />
                    </Grid>
                  ))
                ) : (
                  <>
                    <Grid item xs={12} sm={4}>
                      {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')?.objecttype?.toLowerCase() ===
                      'contenthub' ? (
                        <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')} />
                      ) : (
                        <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')?.objecttype?.toLowerCase() ===
                      'contenthub' ? (
                        <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')} />
                      ) : (
                        <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')} />
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')?.objecttype?.toLowerCase() ===
                      'contenthub' ? (
                        <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')} />
                      ) : (
                        <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')} />
                      )}
                    </Grid>
                  </>
                ))}
            </Grid>
          </Grid>

          <Grid item xs={12} md={5}>
            {isLoading ? (
              <Grid container spacing={deviceSm ? 2 : 4}>
                {range(4).map((el: number) => (
                  <Grid item xs={12} key={el}>
                    <TeaserSkeleton />
                  </Grid>
                ))}
              </Grid>
            ) : (
              <Grid
                container
                style={{
                  backgroundColor: '#E5F6FE',
                  borderRadius: '3px',
                  boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
                  padding: '30px',
                }}
              >
                {_.orderBy(
                  teasers?.filter((teaser) => teaser.placeid.toLowerCase().includes('right_teaser_')),
                  (o) => o.placeid,
                  'asc'
                ).map((teaser: any, key: number) => (
                  <Grid item xs={12} key={teaser.placeid}>
                    {(key !== 0) && <Divider style={{ margin: '2rem 0' }} />}
                    <Teaser teaser={teaser} />
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>

          {deviceSm &&
            (isLoading ? (
              range(3).map((el: number) => (
                <Grid item xs={12} sm={4} key={el}>
                  <HubCardSkeleton />
                </Grid>
              ))
            ) : (
              <>
                <Grid item xs={12} sm={4}>
                  {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')?.objecttype?.toLowerCase() ===
                  'contenthub' ? (
                    <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')} />
                  ) : (
                    <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_LEFT')} />
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')?.objecttype?.toLowerCase() ===
                  'contenthub' ? (
                    <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')} />
                  ) : (
                    <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_MIDDLE')} />
                  )}
                </Grid>
                <Grid item xs={12} sm={4}>
                  {teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')?.objecttype?.toLowerCase() ===
                  'contenthub' ? (
                    <HubCard hub={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')} />
                  ) : (
                    <TeaserCard teaser={teasers?.find((el) => el.placeid === 'LEFT_SMALLPIC_RIGHT')} />
                  )}
                </Grid>
              </>
            ))}
        </Grid>
      </Container>
    </div>
  );
};

export default TopSection;
