import React, { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import Latest from './Latest';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down.svg';
import { Link, useHistory } from 'react-router-dom';
import ArticlesScroll from './ArticlesScroll';
import FromExperts from './FromExperts';
import { getHub, getHubTabTeasersAmount } from '../../context/actions/hubs';
import { Skeleton } from '@material-ui/lab';
import { Element } from 'react-scroll';
import HubTabs from './HubTabs';
import { Container, useTheme } from '@material-ui/core';
import { handler } from '../../context/actions/common';
import { useCookies } from 'react-cookie';
import { useAppContext } from '../../context/AppContext';

interface Props {
  match: any;
}

interface ITab {
  name: string;
  value: string;
  count: number;
}

type State = {
  nachrichten: ITab;
  diagnostik: ITab;
  therapie: ITab;
  leitlinie: ITab;
  fallbericht: ITab;
  fachstudie: ITab;
  krankheitsbild: ITab;
  arztinfo: ITab;
  patienteninfo: ITab;
};

type Action = {
  type: string;
  payload: number;
};

const reducer = (state: any, action: Action) => ({
  ...state,
  [action.type]: {
    ...state[action.type],
    count: action.payload,
  },
});

const Hub = ({ match }: Props) => {
  const { isLogged, setIsLogged } = useAppContext();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [currentTab, setCurrentTab] = useState<string>('alle');
  const [prevTab, setPrevTab] = useState<string | null>(null);
  const [displayGrid, setDisplayGrid] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isTabsLoading, setIsTabsLoading] = useState<boolean>(true);
  const [hub, setHub] = useState<any>({});
  const { hubId } = match.params;

  const history = useHistory();

  const theme = useTheme();

  const initialTabs: State = {
    nachrichten: {
      name: 'Nachrichten',
      value: '["nachricht"]',
      count: 0,
    },
    diagnostik: {
      name: 'Diagnostik',
      value:
        '["diagnostik","externe resssource diagnostik","infografik diagnostik","podcast diagnostik","webcast diagnostik","advertorial diagnostik"]',
      count: 0,
    },
    therapie: {
      name: 'Therapie',
      value: '["therapie","externe resssource therapie","infografik therapie","podcast therapie","webcast therapie","advertorial therapie"]',
      count: 0,
    },
    leitlinie: {
      name: 'Leitlinien',
      value: '["leitlinie","advertorial leitlinien"]',
      count: 0,
    },    
    fallbericht: {
      name: 'Fallbericht',
      value:
        '["fallbericht","externe resssource fallbericht","infografik fallbericht","podcast fallbericht","webcast fallbericht","advertorial fallbericht"]',
      count: 0,
    },
    fachstudie: {
      name: 'Fachstudie',
      value:
        '["fachstudie","externe resssource fachstudie","infografik fachstudie","podcast fachstudie","externe resssource fachstudie","webcast fachstudie","advertorial study"]',
      count: 0,
    },
    krankheitsbild: { name: 'Krankheitsbild', value: '["krankheitsbild"]', count: 0 },
    arztinfo: {
      name: 'Arztinfo',
      value: '["arztinfo","externe resssource arztinfo","infografik arztinfo","podcast arztinfo","webcast arztinfo","advertorial arztinfo"]',
      count: 0,
    },
    // '["blaue-hand-brief"]' is fetched from ArticlesScroll component in order to be filtered separately
    patienteninfo: { name: 'Patienteninfo', value: '["patientinfo","advertorial patientinfo"]', count: 0 },
  };

  const [tabs, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialTabs);

  let mounted = useRef(true);

  useEffect(() => {
 
    mounted.current = true;

    setIsLoading(true);
    setIsTabsLoading(true);

    const handleTabTeasersAmount = async (tabsArr: string, hubName: string) => {
      try {
        const response: any = await getHubTabTeasersAmount(hubName, tabsArr);
        const data = response?.data;
        return data?.doccount;
      } catch (err) {
        let handledError=handler(err);
        if(handledError.statusCode==403){
          setIsLogged(false);
          removeCookie('X-MEDU');
          history.push('/welcome');
        }else{
          console.error('Oops: ', handledError.error);
          return true;
        }
      }
    };

    const getHubInfo = async () => {
      try {
        const response: any = await getHub(hubId);
        const data = response?.data;
        const status = response?.status;
        if (mounted.current) {
          if (status === 200) {
            setHub(data);

            for (const [key, value] of Object.entries(initialTabs)) {
              const count = (await handleTabTeasersAmount(value.value, data?.name)) || 0;
              dispatch({ type: key, payload: count });
            }
          } else {
            history.replace('/404');
          }
        }

        setIsLoading(false);
        setIsTabsLoading(false);
      } catch (err) {
        let handledError=handler(err);
        if(handledError.statusCode==403){
          setIsLogged(false);
          removeCookie('X-MEDU');
          history.push('/welcome');
        }else{
          console.error('Oops: ', handledError.error);
          history.replace('/404');
        }
      }
    };

    getHubInfo();

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hubId]);

  return (
    <div style={{marginTop: '5rem'}}>
      <Container maxWidth='lg' style={{marginBottom: '2rem'}}>
        <div className='page-title-wrapper'>
          {isLoading ? (
            <Skeleton width='30%' height='5rem' />
          ) : (
            <>
              <h2
                style={{color: `${theme.palette.primary.main}`}}
                className='page-title page_title_new'
                dangerouslySetInnerHTML={{ __html: `${hub?.displayname || hub?.name || 'Hub'}` }}
              />
            </>
          )}
          <Link to='/' className='breadcrumb-button'>
            Startseite<ChevronDownIcon style={{ transform: 'rotate(270deg)', margin: '0 0.5rem' }} /> 
            <span dangerouslySetInnerHTML={{ __html: `${hub?.displayname}` }} />
          </Link>
        </div>
      </Container>

      <HubTabs
        isLoading={isLoading || isTabsLoading}
        tabs={tabs}
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        displayGrid={displayGrid}
        setDisplayGrid={setDisplayGrid}
        setPrevTab={setPrevTab}
      />

      <Latest displayGrid={displayGrid} isLoading={isLoading} teasers={hub?.highlights} />

      <Element name='scrollSection'>
        <ArticlesScroll
          displayGrid={displayGrid}
          parentLoading={isLoading || isTabsLoading}
          tabs={tabs}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          prevTab={prevTab}
          setPrevTab={setPrevTab}
          hubName={hub?.name}
        />
      </Element>

      <FromExperts displayGrid={displayGrid} hubName={hub?.name} />
    </div>
  );
};

export default Hub;
