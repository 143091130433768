import { Dialog, Typography } from '@material-ui/core';
import React, { Dispatch, SetStateAction ,useState} from 'react';
import { ITeaser } from '../types';
import { getArticle } from '../../context/actions/article';

interface Props {
  teaser: ITeaser;
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
}

const ImgPreview = ({ teaser, open, onClose }: Props) => {
  const [imgDoc, setImgDoc] = useState<any>(null);
  console.log('teaser', teaser);
  getArticle(teaser?.documentid || teaser?.teaserid || '')
  .then((res: any) =>  {  
    if (res.data?.image !== null && res.data?.image !== undefined && res.data?.image !== '') {
      setImgDoc(res.data.image);
    } else {
      setImgDoc(res.data?.content);
    }
  })
  .catch((err) => {
    console.log('Oops: ', err);
  });
  return (
    <Dialog open={open} maxWidth='md' fullWidth onClose={onClose}>
      <img style={{height: '800px'}} src={`data:image/png;base64, ${imgDoc}`} alt='' />
    </Dialog>
  );
};

export default ImgPreview;
