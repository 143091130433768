import React, { useState, useRef, ChangeEvent, useEffect } from 'react';
import { ReactComponent as PodcastIcon } from '../../media/icons/podcast.svg';
import { ReactComponent as PlayBgIcon } from '../../media/icons/play-bg.svg';
import { ReactComponent as CloseIcon } from '../../media/icons/cross.svg';

import { PauseRounded, PlayArrowRounded } from '@material-ui/icons';
import { useAppContext } from '../../context/AppContext';
import { useDrag } from 'react-dnd';
import { Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { Link } from 'react-router-dom';

interface Props {
  doc?: any;
  src?: string;
  banner?: boolean;
  position?: any;
}

const AudioPlayer = ({ doc, src, position, banner }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [, drag] = useDrag(() => ({
    type: 'player',
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const {
    podcast,
    setPodcast,
    podcastPaused,
    setPodcastPaused,
    podcastDuration,
    setPodcastDuration,
    podcastCurrentTime,
    setPodcastCurrentTime,
    podcastProcessValue,
    setPodcastProcessValue,
  } = useAppContext();

  const isPodcast = doc?.documentid
    ? doc?.documentid === podcast?.documentid || doc?.documentid === podcast?.objectid
    : doc?.objectid
    ? doc?.objectid === podcast?.objectid || doc?.objectid === podcast?.documentid
    : false;

  // const [muted, setMuted] = useState<boolean>(false);
  // const [volume, setVolume] = useState<number>(100);
  const [paused, setPaused] = useState<boolean>(true);
  const [duration, setDuration] = useState<number>(() => (banner ? podcastDuration : 1));
  const [currentTime, setCurrentTime] = useState<any>(() => (banner ? podcastCurrentTime : 0));
  const [processValue, setProcessValue] = useState<any>(() => (banner ? podcastProcessValue : 0));

  const audioRef = useRef<HTMLAudioElement>(null);
  const progressRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const node = audioRef.current;
    if (node && banner) {
      node.currentTime = (podcastDuration / 100) * podcastProcessValue;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioRef.current]);

  useEffect(() => {
    const progressNode = progressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #fff 0%, #fff ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) 100%)';
    }

    setProcessValue((100 / duration) * currentTime);
    if (duration === currentTime) {
      setPaused(true);
    }
  }, [duration, currentTime]);

  useEffect(() => {
    const node = audioRef.current;
    if (node && !paused) {
      setInterval(() => setCurrentTime(node.currentTime), 100);
    }
  }, [paused]);

  useEffect(() => {
    const node = audioRef.current;
    if (node && !podcastPaused) {
      setInterval(() => setPodcastCurrentTime(node.currentTime), 100);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [podcastPaused]);

  const setMetadata = (e: any) => {
    if (!banner) {
      setDuration(e.target.duration);
      setCurrentTime(e.target.currentTime);
      setProcessValue((100 / e.target.duration) * e.target.currentTime);
    }
  };

  const handlePlay = () => {
    if (audioRef.current) {
      if (paused) {
        audioRef.current.play();
        setPaused(false);
      } else {
        audioRef.current.pause();
        setPaused(true);
      }
    }
  };

  const handleRewind = (e: ChangeEvent<HTMLInputElement>) => {
    const node = audioRef.current;
    if (node) {
      setCurrentTime((duration / 100) * parseInt(e.target.value, 10));
      node.currentTime = (duration / 100) * parseInt(e.target.value, 10);
    }

    const progressNode = progressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #fff 0%, #fff ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) ' +
        progressNode.value +
        '%, rgba(255, 255, 255, 0.4) 100%)';
    }
  };

  const convertSeconds = (secs: number) => {
    let time = new Date(secs * 1000).toISOString().substr(14, 5);
    return time;
  };

  const handlePlayBg = () => {
    if (audioRef.current && !paused) {
      setPaused(true);
      audioRef.current.pause();
    }
    setPodcast({ ...doc, src });
    setPodcastDuration(duration);
    setPodcastCurrentTime(currentTime);
    setPodcastProcessValue(processValue);
  };

  const handleCloseBg = () => {
    setPodcast(null);
    setPodcastPaused(true);
    setPodcastDuration(1);
    setPodcastCurrentTime(0);
    setPodcastProcessValue(0);
  };

  // if (isDragging) {
  //   return <div ref={drag} />;
  // }

  return (
    <div
      ref={banner ? drag : null}
      style={
        banner
          ? {
              width: deviceXs ? 'calc(100vw - 6rem)' : `calc(${window.innerWidth / 2}px - 3rem)`,
              cursor: 'grab',
              position: 'fixed',
              transition: 'all 1s ease-in-out 0s',
              zIndex: 1500,
              ...position,
            }
          : { width: '100%' }
      }
    >
      <div id='audio-player'>
        {!isPodcast && (
          <audio ref={audioRef} onLoadedMetadata={setMetadata}>
            <source src={banner ? podcast.src : src} type='audio/mp3' />
            <p>
              Your browser doesn't support HTML5 audio. Here is a <a href='#'>link to the audio</a> instead.
            </p>
          </audio>
        )}
        {banner && (
          <div
            style={{
              width: '100%',
              padding: '0.5rem 0.75rem',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <div style={{ maxWidth: 'calc(100% - 1.5rem)' }}>
              <Tooltip
                title={
                  <>
                    <Typography
                      variant='body2'
                      color='inherit'
                      dangerouslySetInnerHTML={{
                        __html: `${podcast?.teasertitle || podcast?.title || ''}`,
                      }}
                    />
                  </>
                }
                arrow
              >
                <Link
                  to={{
                    pathname: `/audio/${podcast?.documentid || podcast?.objectid}`,
                    state: { teaser: podcast },
                  }}
                  className='action-button__light'
                  style={{ maxWidth: '100%' }}
                >
                  <Typography
                    variant='body1'
                    noWrap
                    dangerouslySetInnerHTML={{ __html: `${podcast?.teasertitle || podcast?.title || ''}` }}
                  />
                </Link>
              </Tooltip>
            </div>
            <div className='icon-button-wrapper' style={{ margin: '0 0 0 0.5rem' }}>
              <CloseIcon className='icon-button__light' onClick={handleCloseBg} />
            </div>
          </div>
        )}
        <div
          style={{
            width: '100%',
            height: '50px',
            background: '#3F4452',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '0 0.25rem',
          }}
        >
          <div style={{ height: '100%', display: 'flex', alignItems: 'center', margin: '0 0 0 0.5rem' }}>
            <PodcastIcon style={{ minWidth: '1.5rem' }} />
          </div>
          {!isPodcast ? (
            <>
              <button
                className='control-btn'
                style={{ margin: 0 }}
                type='button'
                data-state='play'
                onClick={handlePlay}
              >
                {paused ? <PlayArrowRounded fontSize='large' /> : <PauseRounded fontSize='large' />}
              </button>
              <input
                ref={progressRef}
                value={processValue}
                type='range'
                min='0'
                max='100'
                step='0.1'
                onChange={handleRewind}
              />
              <span className='media-time'>
                {convertSeconds(currentTime)} / {convertSeconds(duration)}
              </span>
              {!banner && (
                <Tooltip title='Im Hintergrund spielen' arrow>
                  <div className='icon-button-wrapper' style={{ marginRight: '0.5rem' }}>
                    <PlayBgIcon className='icon-button__light' onClick={handlePlayBg} />
                  </div>
                </Tooltip>
              )}
            </>
          ) : (
            <Typography
              variant='body1'
              noWrap
              style={{
                color: '#fff',
                fontSize: '1rem',
                margin: '0 0.5rem',
                whiteSpace: 'nowrap',
                textAlign: 'center',
                width: '100%',
              }}
            >
              Dieser Podcast läuft im Hintergrund
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default AudioPlayer;
