import CryptoJS from 'crypto-js';

const _0x2547 = [
  '\x6A\x39\x79\x21\x55\x73\x26\x33\x77\x76\x54\x3F\x35\x70\x34\x59',
  '\x70\x61\x72\x73\x65',
  '\x55\x74\x66\x38',
  '\x65\x6E\x63',
  '\x41\x31\x21\x7A\x33\x6C\x44\x34\x68\x26\x67\x48\x32\x73\x3F\x6E\x38\x63\x43\x3D\x64\x55\x36\x53\x70\x31\x75\x27\x59\x33\x6F\x6B',
  '\x43\x42\x43',
  '\x6D\x6F\x64\x65',
  '\x50\x6B\x63\x73\x37',
  '\x70\x61\x64',
  '\x64\x65\x63\x72\x79\x70\x74',
  '\x41\x45\x53',
];

export const decrypt = (_0x11f9x2) => {
  const _0x11f9x3 = CryptoJS[_0x2547[3]][_0x2547[2]][_0x2547[1]](_0x2547[0]);
  const _0x11f9x4 = CryptoJS[_0x2547[3]][_0x2547[2]][_0x2547[1]](_0x2547[4]);
  const _0x11f9x5 = CryptoJS[_0x2547[10]][_0x2547[9]](_0x11f9x2, _0x11f9x4, {
    keySize: 256 / 8,
    iv: _0x11f9x3,
    mode: CryptoJS[_0x2547[6]][_0x2547[5]],
    padding: CryptoJS[_0x2547[8]][_0x2547[7]],
  });
  const decryptedString = _0x11f9x5.toString(CryptoJS[_0x2547[3]].Utf8);
  const _0x11f9x6 = JSON[_0x2547[1]](decryptedString);

  return _0x11f9x6;
};
