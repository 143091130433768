import React from 'react'
import { Container } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../media/icons/linkedin.svg';
import { ReactComponent as XingIcon } from '../../media/icons/xing.svg';
import { useLocation } from 'react-router-dom';

interface Props {}

const Impressum = (props: Props) => {
  const location = useLocation();

  const isOnKontakt = location.pathname === '/kontakt';

  console.log('location', isOnKontakt);
  return (
    <div style={{ padding: '3rem 0' }}>
      <Container maxWidth='lg'>
        <h1 className='article_title'>
          {isOnKontakt ? 'Kontakt' : 'Impressum'}
        </h1>

        <div className='content-wrapper'>
          {!isOnKontakt && (
            <>
              <p>
                <b>Impressum der Vidal MMI Germany GmbH (Vidal MMI).</b>
              </p>
              <h2>Herausgeber und verantwortlicher Diensteanbieter gem. § 5 Telemediengesetz (TMG):</h2>
            </>
          )}
          <p>
            Vidal MMI Germany GmbH
            <br />
            Monzastraße 4<br />
            63225 Langen
          </p>
          <p>
            Telefon: <a href='tel:0610320760'>06103-2076-0</a>
            <br />
            Telefax:&nbsp;06103-2076-188
            <br />
            E-Mail: <a href='mailto:info@mmi.de'>info@mmi.de</a>
          </p>
          {!isOnKontakt && (
            <>
              <p>
                Vertreten durch: Marijo Jurasovic, Vincent Bouvier
                <br />
                Handelsregistereintrag: Amtsgericht Offenbach/Main HRB 8014
                <br />
                Umsatzsteuer-Identifikationsnummer gemäß §27 a Umsatzsteuergesetz: DE113524692
              </p>
  
              <h2>Verantwortlich für den Inhalt gem. § 55 Abs. 2 RStV:</h2>
              <p>
                Marijo Jurasovic, Vincent Bouvier
                <br />
                Monzastraße 4<br />
                63225 Langen
              </p>
              <p>
                Telefon: <a href='tel:0610320760'>06103-2076-0</a>
                <br />
                Telefax:&nbsp;06103-2076-188
                <br />
                E-Mail: <a href='mailto:info@mmi.de'>info@mmi.de</a>
              </p>
              <h2>Quellenangaben für die verwendeten Bilder und Grafiken:</h2>
  
              <ul>
                <li>
                  Für Bilder und Grafiken auf dieser Webseite bestehen Lizenzvereinbarungen u.a. mit Getty Images (
                  <a href='https://www.gettyimages.de' target='_blank' rel='noreferrer'>
                    www.gettyimages.de
                  </a>
                  ,{' '}
                  <a href='https://www.istockphoto.com' target='_blank' rel='noreferrer'>
                    www.istockphoto.com
                  </a>
                  ), Shutterstock Inc. (
                  <a href='https://www.shutterstock.com/de' target='_blank' rel='noreferrer'>
                    www.shutterstock.com
                  </a>
                  ) und Fotolia (
                  <a href='https://www.fotolia.de' target='_blank' rel='noreferrer'>
                    www.fotolia.de)
                  </a>
                  . Entsprechende Copyright-Verweise finden sich auf den Bildern.
                </li>
                <li>
                  Bilder ohne einen besonderen Copyright-Vermerk sind entweder Eigentum der Vidal MMI Germany GmbH (Vidal
                  MMI) oder wurden Vidal MMI von den Rechteinhabern zur Veröffentlichung freigegeben.
                </li>
                <li>Eine Weiterverwendung der Fotos ist Dritten nicht gestattet.</li>
              </ul>
  
              <h2>Allgemeine Geschäftsbedingungen</h2>
              <ul>
                <li>
                  <a href='/agb'>Allgemeine Geschäftsbedingungen für Anzeigenkunden und Mediaservices</a>
                </li>
              </ul>
  
              <h2>Nutzungsbedingungen und Datenschutz</h2>
              <ul>
                <li>
                  <a href='/nutzungsbedingungen'>Nutzungsbedingungen</a>
                </li>
                <li>
                  <a href='/datenschutz'>Datenschutzbestimmungen</a>
                </li>
              </ul>
            </>
          )}

          <div style={{ display: 'flex', margin: '1.5rem -0.5rem -0.5rem -0.5rem' }}>
            <a
              href='https://twitter.com/intent/tweet?text=Impressum&amp;url=https%3a%2f%2fmmi.de%2fimpressum&amp;lang=de'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <TwitterIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fmmi.de%2fimpressum'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <FacebookIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='http://www.linkedin.com/shareArticle?url=https%3a%2f%2fmmi.de%2fimpressum'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <LinkedInIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.xing.com/spi/shares/new?sc_p=xing-share&amp;url=https%3a%2f%2fmmi.de%2fimpressum'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <XingIcon className='icon-button__primary' />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Impressum;
