import React, { Dispatch, SetStateAction } from 'react';
import { Container, makeStyles, Tab, Tabs, useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { ReactComponent as GridIcon } from '../../media/icons/grid.svg';
import { ReactComponent as ListIcon } from '../../media/icons/list.svg';
import { scroller } from 'react-scroll';

interface Props {
  isLoading: boolean;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
  setPrevTab: Dispatch<SetStateAction<string | null>>;
  tabs: any;
  displayGrid: boolean;
  setDisplayGrid: Dispatch<SetStateAction<boolean>>;
}

const useStyles = makeStyles(theme => ({
  tabItmes: {
    '& .Mui-selected': {
      '& .MuiTab-wrapper': {
        color: theme.palette.primary.main,
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: theme.palette.primary.main,
      height: '2px',
    }
  },
  tabItem: {
    '& .MuiTab-wrapper': {
      fontWeight: 'bold',
    },
  },
}));

const HubTabs = ({ isLoading, currentTab, setCurrentTab, setPrevTab, tabs, displayGrid, setDisplayGrid }: Props) => {
  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (displayGrid && newValue !== 'alle') {
      scroller.scrollTo('scrollSection', {
        duration: 700,
        delay: 0,
        smooth: true,
      });
    }
    setCurrentTab((prevState) => {
      setPrevTab(prevState);
      return newValue;
    });
  };

  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  return (
    <div style={{ padding: 0 }}>
<Container maxWidth='lg'>
  <Tabs
    value={currentTab}
    onChange={handleTabChange}
    variant='scrollable'
    scrollButtons='auto'
    className={classes.tabItmes}
    // indicatorColor='primary'
  >
    <Tab
      label={isLoading ? <Skeleton width={50} height='2rem' /> : 'Alle'} value='alle' disableRipple
      className={classes.tabItem}
    />

    {tabs.nachrichten.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={65} height='2rem' /> : tabs.nachrichten.name}
        value={tabs.nachrichten.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.diagnostik.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={70} height='2rem' /> : tabs.diagnostik.name}
        value={tabs.diagnostik.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.therapie.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={100} height='2rem' /> : tabs.therapie.name}
        value={tabs.therapie.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.leitlinie.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={100} height='2rem' /> : tabs.leitlinie.name}
        value={tabs.leitlinie.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.fallbericht.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={80} height='2rem' /> : tabs.fallbericht.name}
        value={tabs.fallbericht.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.fachstudie.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={60} height='2rem' /> : tabs.fachstudie.name}
        value={tabs.fachstudie.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.krankheitsbild.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={90} height='2rem' /> : tabs.krankheitsbild.name}
        value={tabs.krankheitsbild.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.arztinfo.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={85} height='2rem' /> : tabs.arztinfo.name}
        value={tabs.arztinfo.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}

    {tabs.patienteninfo.count > 0 && (
      <Tab
        label={isLoading ? <Skeleton width={95} height='2rem' /> : tabs.patienteninfo.name}
        value={tabs.patienteninfo.value}
        disableRipple
        disabled={isLoading}
        className={classes.tabItem}
      />
    )}
  </Tabs>
</Container>
    </div>
  );
};

export default HubTabs;
