import React from 'react';
import { Typography, TypographyProps, withStyles, WithStyles, makeStyles, Theme } from '@material-ui/core';
import { createStyles, StyleRules,styled } from '@material-ui/core/styles';

// Define the styles
const LandingTypography = withStyles((theme) => ({
  root: {
    verticalAlign: 'center',
  },
  h1: {
    fontFamily: 'Noto Serif SC',
    fontWeight: 500,
    fontSize: '3rem',
    lineHeight: 1.2,

    [theme.breakpoints.down('xs')]: {
      fontSize: '2.25rem',
    },
  },
  h2: {
    fontFamily: 'DM Sans',
    fontWeight: 500,
    fontSize: '2.25rem',
    lineHeight: 1,

    [theme.breakpoints.down('xs')]: {
      fontSize: '2rem',
    },
  },
  h3: {
    fontFamily: 'DM Sans',
    fontWeight: 'bold',
    fontSize: '1.5rem',
    lineHeight: 1.3,

    [theme.breakpoints.down('xs')]: {
      fontSize: '1.25rem',
    },
  },
  h4: {
    fontFamily: 'DM Sans',
    fontWeight: 'bold',
    fontSize: '1.125rem',
    letterSpacing: '0.05rem',
    lineHeight: 1.2,
  },
  body1: {
    fontFamily: 'Noto Sans',
    fontSize: '1rem',
    lineHeight: 1.65,
  },
  body2: {
    fontFamily: 'Noto Sans',
    fontSize: '0.875rem',
    lineHeight: 1,
  },
}))(Typography); 

const LandingTypographyComponent: React.FC<TypographyProps> = (props) => {
  return <LandingTypography {...props} />;
};

// Export the component with styles
export default LandingTypographyComponent;
