import React from 'react';
import { Container, Grid, useMediaQuery, useTheme,Typography, makeStyles } from '@material-ui/core';
import logoWhite from '../media/landing/logo-white.svg';
import LandingTypographyComponent  from './landing/LandingTypography';
import { Link, useLocation } from 'react-router-dom';
import logoVidalMMI from '../media/landing/logo-vidal-mmi.svg';

interface Props {
  
}

export const Footer = (props: Props) => {
  const theme = useTheme();
  const less780 = useMediaQuery(theme.breakpoints.down(780));
  const less460 = useMediaQuery(theme.breakpoints.down(460));

  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const currentUrl = useLocation().pathname;

  return (
    currentUrl === '/welcome' ? (
      <footer className='landing_footer'>
        <Container maxWidth='lg'>
          <div style={{ height: '3rem', display: 'flex', justifyContent: 'flex-start', marginBottom: '3rem' }}>
            <img src={logoWhite} alt='' style={{ maxHeight: '100%' }} />
          </div>
          <Grid container spacing={4} justify='center'>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <a href='https://account.leitmed.de/Login'>
                    <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Login
                    </LandingTypographyComponent>
                  </a>

                  <a href='https://account.leitmed.de/Registrierung'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Registrieren
                    </LandingTypographyComponent>
                  </a>
                  <Link to='/kontakt'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Kontakt
                  </LandingTypographyComponent>
                  </Link>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Link to='/impressum'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Impressum
                    </LandingTypographyComponent>
                  </Link>

                  <Link to='/datenschutz'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Datenschutz
                    </LandingTypographyComponent>
                  </Link>

                  <Link to='/nutzungsbedingungen'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: '2rem' }}>
                      Nutzungsbedingungen
                    </LandingTypographyComponent>
                  </Link>

                  <Link to='/agb'>
                  <LandingTypographyComponent variant='h4' className='landing_text__light'>
                      AGB
                    </LandingTypographyComponent>
                  </Link>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} md={6}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: deviceSm ? 'center' : 'flex-end',
                  paddingTop: deviceSm ? '4rem' : 0,
                }}
              >
                <div
                  style={{
                    height: '6rem',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-end',
                    marginBottom: '3rem',
                  }}
                >
                  <LandingTypographyComponent variant='body1' className='landing_text__light' style={{ marginRight: '1.5rem' }}>
                    Eine Marke von
                  </LandingTypographyComponent>
                  <img src={logoVidalMMI} alt='' style={{ maxHeight: '100%' }} />
                </div>
                <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ textAlign: 'end' }}>
                  © 2023 VIDAL MMI
                </LandingTypographyComponent>
              </div>
            </Grid>
          </Grid>
        </Container>
      </footer>
    ) : (
      <footer className='landing_footer'>
        <Container maxWidth='lg'>
          <Grid container  justify='center'>
            <Grid container justify='space-between' direction={less460 ? 'column' : 'row'}>
              <Grid item style={{marginBottom: less460 ? '2rem' : 'none'}}>
                <Grid container direction='column' style={{height: '100%'}} justify='space-between'>
                  <Grid item style={{ height: '3rem', display: 'flex', justifyContent: 'flex-start', marginBottom: '3rem' }}>
                    <img src={logoWhite} alt='' style={{ maxHeight: '100%' }} />
                  </Grid>

                  <Grid item container={less780 ? false : true} spacing={3}>
                  <Grid item>
                      <a href="/document/6189844a-f6fa-44f2-8ede-3bbda4bfb6dd">
                        <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: less780 ? '0.5rem' : 'none' }}>
                          Kontakt
                        </LandingTypographyComponent>
                      </a>
                    </Grid>
                  <Grid item>
                      <a href="/document/770b2889-f3f1-4253-8fb7-93f1e4e7d7ff">
                        <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: less780 ? '0.5rem' : 'none' }}>
                          Redaktion
                        </LandingTypographyComponent>
                      </a>
                    </Grid>
                    <Grid item>
                      <Link to='/impressum'>
                        <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: less780 ? '0.5rem' : 'none' }}>
                          Impressum
                        </LandingTypographyComponent>
                      </Link>
                    </Grid>

                    <Grid item>
                      <Link to='/datenschutz'>
                        <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: less780 ? '0.5rem' : 'none' }}>
                          Datenschutz
                        </LandingTypographyComponent>
                      </Link>
                    </Grid>

                    <Grid item>
                      <Link to='/nutzungsbedingungen'>
                        <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ marginBottom: less780 ? '0.5rem' : 'none' }}>
                          Nutzungsbedingungen
                        </LandingTypographyComponent>
                      </Link>
                    </Grid>

                    <Grid item>
                      <Link to='/agb'>
                        <LandingTypographyComponent variant='h4' className='landing_text__light'>
                          AGB
                        </LandingTypographyComponent>
                      </Link>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      height: '6rem',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'flex-end',
                      marginBottom: '3rem',
                    }}
                  >
                    <LandingTypographyComponent variant='body1' className='landing_text__light' style={{ marginRight: '1.5rem' }}>
                      Eine Marke von
                    </LandingTypographyComponent>
                    <img src={logoVidalMMI} alt='' style={{ maxHeight: '100%' }} />
                  </div>
                  <LandingTypographyComponent variant='h4' className='landing_text__light' style={{ textAlign: 'end' }}>
                    © 2023 VIDAL MMI
                  </LandingTypographyComponent>
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </footer>
    )
  )
}

