import { useMediaQuery, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

interface Props {
  large?: boolean;
}

const HubCardSkeleton = ({ large }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Skeleton
      variant='rect'
      width='100%'
      height={!deviceXs && large ? '20rem' : '10rem'}
      style={{ borderRadius: '4px' }}
    />
  );
};

export default HubCardSkeleton;
