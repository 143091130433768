import React, { MouseEvent, useEffect, useState } from 'react';
import { Chip, Grid } from '@material-ui/core';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '../../media/icons/bookmark-filled.svg';
import { Link } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import HubCard from './HubCard';
import {
  addBookmark,
  formatDate,
  getYouTubeMetadata,
  humanizeTagTitle,
  removeBookmark,
} from '../../context/actions/common';
import { getMedia } from '../../context/actions/article';
import { Skeleton } from '@material-ui/lab';
import ImgPreview from './ImgPreview';
import YouTubePlayer from './YouTubePlayer';
import { IBookmark, ITeaser } from '../types';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useAppContext } from '../../context/AppContext';

interface Props {
  teaser: ITeaser;
  clickable?: boolean;
}

const Teaser = ({ teaser, clickable = true }: Props) => {
  const [teaserData, setTeaserData] = useState<ITeaser>(() => teaser);
  const [mediaUrl, setMediaUrl] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isAdvertising, setAdvertising] = useState<boolean>(true);
  const [preview, setPreview] = useState<boolean>(false);

  const { bookmarks, setBookmarks } = useAppContext();

  const [setInfo] = useCustomSnackbar('info');

  const withImage =
    teaser?.teaserimage &&
    teaser?.mediatype?.toLowerCase() !== 'audio' &&
    !teaser?.teaserurl?.includes('youtube');

  const withVideo = teaser?.mediatype?.toLowerCase() === 'video';
  const withAudio = teaser?.mediatype?.toLowerCase() === 'audio';
  const withUrl = teaser?.mediatype?.toLowerCase() === 'url';
  const withYouTube =
    teaser?.mediatype?.toLowerCase() === 'url' && teaser?.teaserurl?.toLowerCase()?.includes('youtube');

  useEffect(() => {
    if(teaser?.documenttypeid=="1fa9ca7c-a17e-4324-97b7-74a1bf7f5895"||
      teaser?.documenttypeid=="48c6ded9-4d31-4852-a7ca-792cffa57ddb"||
      teaser?.documenttypeid=="0b706225-bcd7-411e-8cd5-46f9ed0589fc"||
      teaser?.documenttypeid=="fafa52c5-76fa-4a19-a785-f5b48be4d10b"||
      teaser?.documenttypeid=="7ff16aad-bc62-419f-8757-aa1d67332c0e"||
      teaser?.documenttypeid=="ab476270-7bd5-4323-8b76-29aeea2e585f"||
      teaser?.documenttypeid=="037f1eae-7620-42ee-9478-10d7f6861f91"||
      teaser?.documenttypeid=="2e9e07ea-99c2-4489-80f2-ec40c4066a4b"||
      teaser?.documenttypeid=="176c7803-b502-40a7-b1cb-3cf8cc2c933b"
    ){
      setAdvertising(true);
    }else{
      setAdvertising(false);
    }
    if (withAudio) {
      getMedia(teaser?.documentid || teaser?.teaserid || '')
        .then((res: any) => res?.data)
        .then((url: string) => {
          setMediaUrl(url);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log('Oops: ', err);
          setMediaUrl('');
          setIsLoading(false);
        });
    } else if (withYouTube) {
      getYouTubeMetadata(teaser?.teaserurl).then((data) => {
        if (data?.title) {
          setTeaserData({ ...teaserData, teasertitle: data.title });
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaser, withAudio, withYouTube]);


  const handleAudio = (e: MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const isImage = (teaser?.documenttypeid === 'f4fbb189-7167-48bf-bd4d-7b85cbe9651d') ||
    (teaser?.documenttypeid === '55203af2-7cd2-4447-9850-d9e0a36eec2b') ||
    (teaser?.documenttypeid === '9e0f43bc-fb9b-4913-b13e-d3492a285f69') ||
    (teaser?.documenttypeid === '6218a2fd-247f-4407-8037-aa30e0cc3aed') ||
    (teaser?.documenttypeid === '20bc60a2-12de-4558-8f25-a102f5726939');

  const toggleImgPreview = () => {
    if (isImage) setPreview(!preview);

    if (teaser?.mediatype?.toLowerCase() === 'image'
    ) {
      setPreview(!preview);
    }
  };

  const handleBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>, objecttype: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !!bookmarks
        ? bookmarks?.find(
            (el: IBookmark) =>
              el.objectid === teaserData?.documentid ||
              el.objectid === teaserData?.teaserid ||
              el.objectid === teaserData?.objectid
          )
        : !!teaserData?.bookmark
    ) {
      removeBookmark(objecttype, teaserData?.documentid || teaserData?.teaserid || teaserData?.objectid || '')
        .then(() => {
          setTeaserData({ ...teaserData, bookmark: 0 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks.filter(
                (el: IBookmark) =>
                  el.objectid !== teaserData?.documentid &&
                  el.objectid !== teaserData?.teaserid &&
                  el.objectid !== teaserData?.objectid
              ),
            ]);
          }
          setInfo('Aus Lesezeichen entfernt');
        })
        .catch((err) => console.log('Oops: ', err));
    } else {
      addBookmark(objecttype, teaserData?.documentid || teaserData?.teaserid || teaserData?.objectid || '')
        .then(() => {
          setTeaserData({ ...teaserData, bookmark: 1 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks,
              {
                objectid: teaserData?.documentid || teaserData.teaserid || teaserData.objectid,
                objecttype,
                title: teaserData.teasertitle,
              },
            ]);
          }
          setInfo('Zu Lesezeichen hinzugefügt');
        })
        .catch((err) => console.log('Oops: ', err));
    }
  };

  return (
    <>
      <Link
        to={
          clickable ? (
            teaserData?.mediatype?.toLowerCase() === 'image'
            ? ''
            : teaserData?.mediatype?.toLowerCase() === 'map'
            ? { pathname: `/map`, state: { teaser: teaserData } }
            : withAudio
            ? { pathname: `/audio/${teaserData?.documentid || teaserData?.objectid}`, state: { teaser: teaserData } }
            : withVideo || withYouTube
            ? {
                pathname: `/video/${teaserData?.documentid || teaserData?.objectid}`,
                state: { teaser: teaserData },
              }
            : `/document/${teaserData?.documentid || teaserData?.objectid}`
          ) : '#'
        }
        onClick={(e) => isImage ? e.preventDefault() : undefined}
        style={{display: 'flex', gap: '1rem'}}
      >
        {withVideo ? (
          <HubCard
            hub={{
              title: 'Diagnostic',
              contentTitle: 'Sustained Remission Achieved from Anti-CD19',
              contentSubtitle: 'Bachanov et al.',
              video: true,
            }}
            large={false}
          />
        ) : withYouTube ? (
          <YouTubePlayer title={teaserData?.teasertitle} src={teaserData?.teaserurl} />
        ) : null }
        <div onClick={toggleImgPreview} className='teaser-wrapper'>
          {(!!teaserData?.source || !!teaserData?.documenttype) && (
            <div className='teaser_label-wrapper'>
              <span className='article_label'>
                {teaserData?.source}{' '}
                {!!teaserData?.documenttype && !!teaserData?.source && (
                  <span className='teaser_text-secondary'>in</span>
                )}{' '}
                {teaserData?.documenttype}
              </span>
            </div>
          )}
          <Grid container justify='space-between' alignItems="flex-start">
            <Grid item xs={11}>
              {/* {teaserData?.tags?.length ? (
                <div style={{ margin: '-0.25rem', marginBottom: '0.5rem' }}>
                  {teaserData.tags.map((tag: string, key: number) => (
                    <Chip
                      key={key}
                      color='primary'
                      label={<span dangerouslySetInnerHTML={{ __html: `${humanizeTagTitle(tag)}` }} />}
                      size='medium'
                      style={{ margin: '0.25rem' }}
                    />
                  ))}
                </div>
              ) : null} */}
              <h4
                className='teaser_title'
                dangerouslySetInnerHTML={{
                  __html: teaserData?.teasertitle || '',
                }}
              ></h4>
            </Grid>
            <Grid item>
            {isAdvertising
             ?
            (
              <h6>Anzeige</h6>
            ):
            (   
              <span className='teaser_button'
                onClick={(e) =>
                  handleBookmark(
                    e,
                    withVideo
                      ? 'VIDEO'
                      : withUrl
                      ? 'URL'
                      : withAudio
                      ? 'AUDIO'
                      : teaser?.mediatype?.toLowerCase() === 'image'
                      ? 'IMAGE'
                      : 'DOCUMENT'
                  )
                }
              >
                {(
                  !!bookmarks
                    ? bookmarks?.find(
                        (el: IBookmark) =>
                          el.objectid === teaserData?.documentid ||
                          el.objectid === teaserData?.teaserid ||
                          el.objectid === teaserData?.objectid
                      )
                    : !!teaserData?.bookmark
                ) ? (
                  <>
                    <BookmarkFilledIcon />
                  </>
                ) : (
                  <>
                    <BookmarkIcon />
                  </>
                )}
              </span>
            )}
            </Grid>
          </Grid>
          <div style={{ width: '100%' }}>
            <Grid container spacing={1} justify='space-between' style={{ height: '100%' }}>
              {withImage && (
                <Grid item xs={2}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                    <img
                      src={teaserData?.teaserimage ? `data:image/png;base64, ${teaserData.teaserimage}` : ''}
                      alt=''
                      className='article_image'
                    />
                  </div>
                </Grid>
              )}
              <Grid item xs>
                <div>
                  {teaserData?.teaserdesc && (
                    <p className='teaser_text' dangerouslySetInnerHTML={{ __html: teaserData.teaserdesc || '' }}></p>
                  )}
                  {teaserData?.mediatype?.toLowerCase() === 'audio' &&
                    (isLoading ? (
                      <Skeleton variant='rect' width='100%' height={50} style={{ marginBottom: '2rem' }} />
                    ) : (
                      mediaUrl && (
                        <div style={{ margin: '0.5rem 0', maxWidth: '100%' }} onClick={handleAudio}>
                          <AudioPlayer doc={teaserData} src={mediaUrl} />
                        </div>
                      )
                    ))}
                </div>
              </Grid>
            </Grid>
          </div>

          {(formatDate(teaserData?.documentdate) || typeof teaserData?.readingtime === 'number') && (
            <div className='teaser_info-wrapper'>
              <span className='teaser_text-secondary'>
                {formatDate(teaserData?.documentdate) && (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: formatDate(teaserData?.documentdate),
                    }}
                  ></span>
                )}
                {/* {' '}
                {formatDate(teaserData?.documentdate) && typeof teaserData?.readingtime === 'number' && (
                  <span style={{ color: 'rgba(44, 54, 79, 0.5)' }}>&middot; </span>
                )}{' '}
                {typeof teaserData?.readingtime === 'number' && (
                  <span>{teaserData.readingtime} mins to read </span>
                )} */}
              </span>
            </div>
          )}

          {/*Old teaser content*/}
          {/* <div style={{ width: '100%' }}>
            <Grid container spacing={1} justify='space-between' style={{ height: '100%' }}>
              {(withImage || withVideo || withYouTube) && (
                <Grid item xs={withVideo || withYouTube ? 6 : 4} lg={withVideo || withYouTube ? 5 : 3}>
                  <div style={{ display: 'flex', alignItems: 'center', width: '100%', height: '100%' }}>
                    {withVideo ? (
                      <HubCard
                        hub={{
                          title: 'Diagnostic',
                          contentTitle: 'Sustained Remission Achieved from Anti-CD19',
                          contentSubtitle: 'Bachanov et al.',
                          video: true,
                        }}
                        large={false}
                      />
                    ) : withYouTube ? (
                      <YouTubePlayer title={teaserData?.teasertitle} src={teaserData?.teaserurl} />
                    ) : (
                      <img
                        src={teaserData?.teaserimage ? `data:image/png;base64, ${teaserData.teaserimage}` : ''}
                        alt=''
                        className='article_image'
                      />
                    )}
                  </div>
                </Grid>
              )}
              <Grid
                item
                xs={withImage ? 8 : withVideo || withYouTube ? 6 : 12}
                md={withImage ? 8 : withVideo || withYouTube ? 6 : 12}
                lg={withImage ? 9 : withVideo || withYouTube ? 7 : 12}
              >
                <div>
                  {teaserData?.teaserdesc && (
                    <p className='teaser_text' dangerouslySetInnerHTML={{ __html: teaserData.teaserdesc || '' }}></p>
                  )}
                  {teaserData?.mediatype?.toLowerCase() === 'audio' &&
                    (isLoading ? (
                      <Skeleton variant='rect' width='100%' height={50} style={{ marginBottom: '2rem' }} />
                    ) : (
                      mediaUrl && (
                        <div style={{ margin: '0.5rem 0', maxWidth: '100%' }} onClick={handleAudio}>
                          <AudioPlayer doc={teaserData} src={mediaUrl} />
                        </div>
                      )
                    ))}

                  {(formatDate(teaserData?.documentdate) || typeof teaserData?.readingtime === 'number') && (
                    <div className='teaser_info-wrapper'>
                      <span className='teaser_text-secondary'>
                        {formatDate(teaserData?.documentdate) && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: formatDate(teaserData?.documentdate),
                            }}
                          ></span>
                        )}
                      </span>
                    </div>
                  )}
                </div>
              </Grid>
            </Grid>
          </div> */}
        </div>
      </Link>
      {(teaserData?.mediatype?.toLowerCase() === 'image' || isImage) && (
        <ImgPreview teaser={teaserData} open={preview} onClose={() => setPreview(false)} />
      )}
    </>
  );
};

export default Teaser;
