import { Button, Divider, Grid, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { range } from '../../context/actions/common';
import { getLatestInterviews } from '../../context/actions/home';
import { LandingButton, LandingButtonB } from '../landing/Landing';
import HubCard from '../reusable/HubCard';
import Teaser from '../reusable/Teaser';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { ITeaser } from '../types';

interface Props {}

const InterviewsColumn = (props: Props) => {
  const classes=LandingButton();
  const [teasers, setTeasers] = useState<ITeaser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      getLatestInterviews()
        .then(async (res) => {
          const { data }: any = await res;
          // Sort teasers by document date in descending order
          data.sort((a: ITeaser, b: ITeaser) => {
            // Parse dates in the format "dd-mm-yyyy" if documentdate is defined
            const dateA = a.documentdate ? new Date(
              a.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            const dateB = b.documentdate ? new Date(
              b.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            // Compare the dates
            return dateB - dateA; // Sort in descending order
          });
          setTeasers(data);
          setIsLoading(false);
        })
        .catch(() => {
          setTeasers([]);
          setIsLoading(false);
        });
    }, 2000);
  }, []);

  // useEffect(() => {
  //      getLatestInterviews()
  //       .then(async (res) => {
  //         const { data }: any = await res;
  //         setTeasers(data);
  //         setIsLoading(false);
  //       })
  //       .catch(() => {
  //         setTeasers([]);
  //         setIsLoading(false);
  //       });
  // }, []);

  return (
    <div>
      <div className='section_title-wrapper'>
        <h3
          className='section_title section_title_new'
          style={{color: `${theme.palette.primary.main}`}}
        >
          Videos/&#8203;Webcasts'
        </h3>
      </div>
      <Divider style={{height: '4px', marginBottom: '1.5rem', backgroundColor: 'white'}}/>
      <Grid container spacing={2}>
        {isLoading
          ? range(3).map((el: number, key: number) => (
              <Grid key={key} item xs={12}>
                <TeaserSkeleton />
              </Grid>
            ))
          : teasers.map((teaser: ITeaser, key: number) => (
              <Grid key={teaser.documentid} item xs={12}>
                {key !== 0 && <Divider style={{ margin: '0 0 16px 0' }} />}
                <Teaser teaser={teaser} />
              </Grid>
            ))}
      </Grid>
      <Divider style={{marginTop: '1rem'}}/>
      <div className='landing_buttons-wrapper' style={{marginTop: '2rem'}}>
        <Link to={{ pathname: '/interviews/all' }}>
          <LandingButtonB  variant='contained' color='secondary'>
            Alles sehen
          </LandingButtonB>
        </Link>
      </div>
    </div>
  );
};

export default InterviewsColumn;
