import { Container, Divider, Grid, useMediaQuery, useTheme, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as ChevronDownIcon } from '../../media/icons/chevron-down.svg';
import { IHub } from '../types';
import HubCard from '../reusable/HubCard';
import { getHubs, getPopularHubs } from '../../context/actions/hubs';
import { range } from '../../context/actions/common';
import HubCardSkeleton from '../reusable/HubCardSkeleton';
import _ from 'lodash';

const ContentHubs = () => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const { state }: any = useLocation();
  const parentPopularHubs: IHub[] | undefined = state?.parentPopularHubs;
  const parentHubs: IHub[] | undefined = state?.parentHubs;

  const [popularHubs, setPopularHubs] = useState<IHub[]>([]);
  const [hubs, setHubs] = useState<IHub[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedCategory, setSelectedCategory] = useState<string>('ALLE');
  const [filteredHubs, setFilteredHubs] = useState<IHub[]>([]);
  const [filteredPopularHubs, setFilteredPopularHubs] = useState<IHub[]>([]);

  useEffect(() => {
    const getAllHubs = async () => {
      if (!parentPopularHubs) {
        await getPopularHubs().then((res) => {
          const data: any = res?.data;
          setPopularHubs(data);
        });
      } else {
        setPopularHubs(parentPopularHubs);
      }

      await getHubs().then((res) => {
        const data: any = res?.data;
        setHubs(data);
      });

      setIsLoading(false);
    };

    getAllHubs();
  }, [parentHubs, parentPopularHubs]);

  useEffect(() => {
    const popularHubsIDs = popularHubs.map((popularHub: IHub) => popularHub.hubid);
    const uniqueHubs = hubs.filter((hub: IHub) => !popularHubsIDs.includes(hub.hubid));
    // Filter hubs based on selected category
    if (selectedCategory === 'ALLE') {
      setFilteredHubs(uniqueHubs);
    } else if (selectedCategory === 'ANDERE') {
      setFilteredHubs(uniqueHubs.filter(hub => hub.availablecategories.length === 0));
    } else {
      setFilteredHubs(uniqueHubs.filter(hub => hub.availablecategories.includes(`FG_${selectedCategory}`)));
    }
    if (selectedCategory === 'ALLE') {
      setFilteredPopularHubs(popularHubs);
    } else if (selectedCategory === 'ANDERE') {
      const tempHubs=popularHubs.filter(hub => hub.availablecategories.length == 0)
      setFilteredPopularHubs(tempHubs);
    } else {
      const tempHubs=popularHubs.filter(hub => hub.availablecategories.includes(`FG_${selectedCategory}`));
      setFilteredPopularHubs(tempHubs);
    }    
  }, [selectedCategory, hubs]);

  return (
    <div>
      <div className='page-title-wrapper'>
        <Link to='/' className='home-page-button'>
          <ChevronDownIcon style={{ transform: 'rotate(90deg)' }} /> Startseite
        </Link>
      </div>
      <div className='section__light'>
        <Container maxWidth='lg'>
          <div className='section_title-wrapper'>
            <h3 className='section_title'>Unsere Themen</h3>
          </div>
          <Grid container spacing={deviceSm ? 2 : 4}>
            <Grid item xs={12}>
              {/* Filter combobox */}
              <FormControl>
                <InputLabel id="category-select-label">Kategorie</InputLabel>
                <Select
                  labelId="category-select-label"
                  id="category-select"
                  value={selectedCategory}
                  onChange={(event) => setSelectedCategory(event.target.value as string)}
                >
                  {['ALLE', ..._.uniq(_.flatMap(hubs, hub => hub.availablecategories.map((category:string) => category.replace('FG_', ''))))].sort().map((option, index) => (
                    <MenuItem key={index} value={option} selected={option === 'ALLE'}>{option}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {isLoading
              ? range(4).map((el: number) => (
                  <Grid item xs={6} sm={3} key={el}>
                    <HubCardSkeleton />
                  </Grid>
                ))
              : _.orderBy(filteredPopularHubs, ['score', 'sortorder'], ['asc', 'asc']).map((hub: any) => (
                  <Grid item xs={6} sm={3} key={hub.hubid}>
                    <HubCard large={false} hub={hub} />
                  </Grid>
                ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {isLoading
              ? range(8).map((el: number) => (
                  <Grid item xs={6} sm={3} key={el}>
                    <HubCardSkeleton />
                  </Grid>
                ))
              : _.orderBy(filteredHubs, ['score', 'sortorder'], ['asc', 'asc'])?.map((hub: any) => (
                  <Grid item xs={6} sm={3} key={hub.hubid}>
                    <HubCard large={false} hub={hub} />
                  </Grid>
                ))}
          </Grid>
        </Container>
      </div>
    </div>
  );
};

export default ContentHubs;
