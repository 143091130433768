import { axiosInstance } from '../AppContext';
import { AxiosResponse } from 'axios';
import { handler } from './common';

export const getTeaser = async (documentId: string) => {
  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(`/teaser/${documentId}`);
    return res;
  } catch (err) {
    return console.log('error', err);
  }
};

export const getArticle = async (documentId: string) => {
  //try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(`/document/${documentId}`);
    return res;
  /*} catch (err) {
    handler(err);
  }*/
};

export const searchByTag = async (searchTag: string) => {
  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(`/document/search?q=${searchTag}`);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const searchByIds = async (ids: string[]) => {
  try {
    const res: AxiosResponse<any[]> = await axiosInstance.post('/document/list/ids', ids);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getMedia = async (id: string) => {
  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(`/media/generateurl/${id}`);
    return res;
  } catch (err) {
    handler(err);
  }
};
