import React from 'react';
import { Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Teaser from '../reusable/Teaser';
import HubCardSkeleton from '../reusable/HubCardSkeleton';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { range } from '../../context/actions/common';
import HubTeaserCard from './HubTeaserCard';

interface Props {
  displayGrid?: boolean;
  teasers?: any;
  isLoading?: boolean;
}

const Latest = ({ displayGrid, teasers, isLoading }: Props) => {
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div className='section__light'>
      <Container maxWidth='lg'>
        <Grid container spacing={deviceSm ? 2 : 4} justify='center'>
          <Grid item xs={12} md={!!displayGrid ? 7 : 9}>
            <Grid container spacing={2} style={{height: '100%'}}>
              {isLoading ? (
                <>
                  <Grid item xs={12}>
                    <HubCardSkeleton large />
                  </Grid>
                  {!deviceSm && !!displayGrid && (
                    <Grid item xs={12}>
                      <TeaserSkeleton />
                    </Grid>
                  )}
                </>
              ) : (
                <>
                  <Grid item xs={12} style={{height: '100%'}}>
                    <HubTeaserCard large={true} teaser={teasers[0]} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} md={!!displayGrid ? 5 : 9}>
            <Grid container spacing={2}>
              {isLoading
                ? range(3).map((el: number) => (
                    <Grid key={el} item xs={12}>
                      <TeaserSkeleton />
                    </Grid>
                  ))
                : teasers?.slice(2)?.map((teaser: any, key: number) => (
                    <Grid item xs={12} key={teaser.documentid}>
                      {(key !== 0 || deviceSm || !displayGrid) && <Divider style={{ margin: '0 0 16px 0' }} />}
                      <Teaser teaser={teaser} />
                    </Grid>
                  ))
              }
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Latest;
