import React, { useState, useRef, useEffect, useCallback, MouseEvent } from 'react';
import {
  Button,
  AppBar,
  ClickAwayListener,
  Container,
  createStyles,
  Fab,
  Grow,
  makeStyles,
  Paper,
  Popper,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useScrollTrigger,
  useTheme,
  Zoom,
} from '@material-ui/core';
import { Link, useLocation } from 'react-router-dom';
import logo from '../media/logo.svg';
import { ReactComponent as BookmarkIcon } from '../media/icons/bookmark.svg';
import { ReactComponent as AvatarIcon } from '../media/icons/user.svg';
import { ReactComponent as BurgerIcon } from '../media/icons/burger.svg';
import { ReactComponent as ChevronDownIcon } from '../media/icons/chevron-down.svg';
import { ReactComponent as LoginIcon } from '../media/icons/icon-login.svg';
import { ReactComponent as DeleteIcon } from '../media/icons/delete.svg';
import SearchInput from './search/SearchInput';
import { animateScroll } from 'react-scroll';
import LandingTypographyComponent  from './landing/LandingTypography';
import { LandingButton, LandingButtonB } from './landing/Landing';
import { useAppContext } from '../context/AppContext';
import { useDrop } from 'react-dnd';
import AudioPlayer from './reusable/AudioPlayer';
import { getBookmarks, range, removeBookmark } from '../context/actions/common';
import { IBookmark } from './types';
import { Skeleton } from '@material-ui/lab';
import useCustomSnackbar from '../hooks/useCustomSnackbar';
import ImgPreview from './reusable/ImgPreview';
import { Footer } from './Footer';
import { LoginPopper } from './reusable/LoginPopper';

interface Props {
  children: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      maxWidth: '340px',
      boxShadow: '0px 3px 26px rgb(0 0 0 / 16%)',
      borderRadius: '20px',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

const Nav = ({ children }: Props) => {
  const classesB=LandingButton();
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const less600 = useMediaQuery(theme.breakpoints.down(600));
  const less450 = useMediaQuery(theme.breakpoints.down(450));

  const { podcast, isLogged, logout, bookmarks, setBookmarks } = useAppContext();

  const [searchTag, setSearchTag] = useState<string>('');
  const [openProfileMenu, setOpenProfileMenu] = useState<boolean>(false);
  const [openBookmarks, setOpenBookmarks] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [preview, setPreview] = useState<any>(null);

  const menuRef = useRef(null);
  const bookmarkRef = useRef(null);

  const { pathname } = useLocation();

  const marginX = '3rem';
  const marginY = '4.5rem';
  const [position, setPosition] = useState<any>({ top: marginY, right: marginX });

  const moveBox = useCallback((x: any, y: any) => {
    let positionObj: any = {};

    let bannerCenterX = x + window.innerWidth / 4;
    let bannerCenterY = y + 25;

    if (bannerCenterX < window.innerWidth / 2) {
      positionObj.left = marginX;
    } else {
      positionObj.right = marginX;
    }

    if (bannerCenterY < window.innerHeight / 2) {
      positionObj.top = marginY;
    } else {
      positionObj.bottom = marginY;
    }

    setPosition(positionObj);
  }, []);

  const [, drop] = useDrop(
    () => ({
      accept: 'player',
      drop(item: any, monitor) {
        const currentOffset = monitor.getSourceClientOffset();
        moveBox(currentOffset?.x, currentOffset?.y);
        return undefined;
      },
    }),
    [moveBox]
  );

  useEffect(() => {
    const onSearchPage = pathname?.includes('/search/');

    if (!onSearchPage) {
      setSearchTag('');
    }
  }, [pathname]);

  const showBackToTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  const handleBackToTop = (event: React.MouseEvent<HTMLDivElement>) => {
    animateScroll.scrollToTop({
      duration: 700,
      delay: 0,
      smooth: true,
    });
  };

  const handleBookmarks = async () => {
    setOpenBookmarks(!openBookmarks);

    if (!bookmarks) {
      setIsLoading(true);

      try {
        const response = await getBookmarks();
        setBookmarks(response?.data || []);
        setIsLoading(false);
      } catch (err) {
        setBookmarks([]);
        console.error('Oops: ', err);
        setIsLoading(false);
      }
    }
  };

  const [setInfo] = useCustomSnackbar('info');

  const deleteBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>, bookmark: IBookmark) => {
    e.preventDefault();
    e.stopPropagation();

    removeBookmark(bookmark.objecttype, bookmark.objectid)
      .then(() => {
        const bookmarksCopy = !!bookmarks ? [...bookmarks] : [];
        const idx = bookmarksCopy.findIndex((el: IBookmark) => el.objectid === bookmark.objectid);
        bookmarksCopy.splice(idx, 1);
        setBookmarks(bookmarksCopy);
        setInfo('Aus Lesezeichen entfernt');
      })
      .catch((err) => console.log('Oops: ', err));
  };

   const deviceUpXs = useMediaQuery(theme.breakpoints.up(600));

  let appbar = window.document.querySelector('.toolbar');

  const [headerHeight, setHeaderHeight] = useState(63);

  useEffect(() => {
    setHeaderHeight(appbar?.clientHeight as number);
  }, [deviceXs, deviceUpXs, less600, deviceSm, appbar?.clientHeight]);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div>
      <AppBar
        position='fixed'
        color='transparent'
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
          backgroundColor: '#fff',
          paddingBottom: less600 ? '1rem' : '0px',
        }}
      >
        <Container maxWidth='lg'>
          <Toolbar
            className="toolbar"
            style={{
              minHeight: !isLogged ? '6rem' : deviceXs ? '2rem' : '4rem',
              padding: !isLogged ? '1.5rem 0' : deviceXs ? '0.5rem 0' : '1rem 0',
            }}
          >
            <Link to={!isLogged ? '/welcome' : '/'}>
              <img
                src={logo}
                alt=''
                style={{
                  transition: 'all 0.3s ease 0s',
                  marginRight: '1rem',
                  height: '4rem',
                  maxHeight: !isLogged ? '4rem' : deviceXs ? '1rem' : '4rem',
                }}
              />
            </Link>
            {!isLogged ? (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Link to='/kontakt' style={{ display: 'flex', alignItems: 'center' }}>
                  {!less450 && (
                    <LandingTypographyComponent  color='primary' variant='h4'>
                      KONTAKT
                    </LandingTypographyComponent>
                  )}
                  {/* <TelIcon style={{ margin: deviceXs ? '0 0 0 0.5rem' : '0 1rem 0 0.5rem' }} />
                  {!deviceXs && (
                    <LandingTypography color='primary' variant='h4'>
                      0800-7427646
                    </LandingTypography>
                  )} */}
                </Link>
                <LandingButtonB
                  variant='contained'
                  color='secondary'
                  startIcon={!deviceXs && <LoginIcon />}
                  style={{ marginLeft: deviceSm ? '1rem' : '3rem' }}
                  aria-describedby={id}
                  onClick={handleClick}
                >
                  Login
                </LandingButtonB>
                <LoginPopper
                  anchorEl={anchorEl}
                  setAnchorEl={setAnchorEl}
                >
                  <LandingTypographyComponent variant="body1" color="initial">
                    Sie sind bereits auf der LeitMed, Gelbe Liste oder data4doc als approbierter Arzt oder Apotheker registriert? Dann loggen Sie sich hier ein:
                  </LandingTypographyComponent>
                  <a href='https://account.leitmed.de/Login'>
                    <LandingButtonB
                      variant='contained'
                      color='secondary'
                      style={{ marginTop: '8px' }}
                    >
                      Weiter zu Login
                    </LandingButtonB>
                  </a>
                </LoginPopper>
              </div>
            ) : (
              <>
                {!deviceXs ? (
                  <>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <SearchInput searchTag={searchTag} setSearchTag={setSearchTag} />
                      <BookmarkIcon
                        ref={bookmarkRef}
                        onClick={handleBookmarks}
                        className='icon-button__dark search_input'
                        style={{ margin: '0 .5rem 0 1rem' }}
                      />
                      <Popper
                        open={openBookmarks}
                        anchorEl={bookmarkRef.current}
                        role={undefined}
                        transition
                        disablePortal
                        style={{ zIndex: 1 }}
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                          >
                            <Paper elevation={1}>
                              <ClickAwayListener onClickAway={() => setOpenBookmarks(false)}>
                                <div
                                  style={{
                                    padding: '0.75rem',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    width: '320px',
                                    maxHeight: '90vh',
                                    overflow: 'auto',
                                  }}
                                >
                                  {isLoading ? (
                                    range(10).map((el: number) => (
                                      <Skeleton
                                        width='calc(320px - 1.5rem)'
                                        height='1.5rem'
                                        style={{ margin: '0.75rem 0' }}
                                        key={el}
                                      />
                                    ))
                                  ) : !!bookmarks?.length ? (
                                    bookmarks?.map((bookmark: IBookmark) => (
                                      <div
                                        style={{
                                          width: '100%',
                                          margin: '0.75rem 0',
                                          display: 'flex',
                                          flexWrap: 'nowrap',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          overflow: 'hidden',
                                        }}
                                        key={bookmark.objectid}
                                      >
                                        <Link
                                          to={
                                            bookmark.objecttype.toLowerCase() === 'document'
                                              ? `/document/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'audio'
                                              ? `/audio/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'video' ||
                                                bookmark.objecttype.toLowerCase() === 'url'
                                              ? `/video/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'contenthub'
                                              ? `/hub/${bookmark.objectid}`
                                              : ''
                                          }
                                          className='action-button__default'
                                          onClick={() => {
                                            setOpenBookmarks(false);
                                            if (bookmark.objecttype.toLowerCase() === 'image') {
                                              setPreview(bookmark);
                                            }
                                          }}
                                          style={{
                                            lineHeight: '1rem',
                                            maxWidth: 'calc(100% - 1.5rem)',
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              <>
                                                <LandingTypographyComponent
                                                  variant='body2'
                                                  color='inherit'
                                                  dangerouslySetInnerHTML={{
                                                    __html: `${bookmark.title}`,
                                                  }}
                                                />
                                              </>
                                            }
                                            arrow
                                          >
                                            <LandingTypographyComponent
                                              variant='body1'
                                              noWrap
                                              dangerouslySetInnerHTML={{ __html: `${bookmark.title}` }}
                                            />
                                          </Tooltip>
                                        </Link>
                                        <div className='icon-button-wrapper' style={{ marginLeft: '0.5rem' }}>
                                          <DeleteIcon
                                            className='icon-button__primary'
                                            onClick={(e) => deleteBookmark(e, bookmark)}
                                          />
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <span className='no-results-msg'>keine Lesezeichen gespeichert</span>
                                  )}
                                </div>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                      {/* <div style={{ margin: '0 .5rem' }}>
                            <Badge color='primary' overlap='circle' variant='dot'>
                              <BellIcon className='icon-button__dark' />
                            </Badge>
                          </div> */}
                    </div>

                    <div
                      ref={menuRef}
                      onClick={() => setOpenProfileMenu(!openProfileMenu)}
                      className='action-button__default'
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <AvatarIcon style={{ marginRight: '0.5rem' }} />
                      <ChevronDownIcon
                        style={{
                          transition: 'all 0.3s ease 0s',
                          transform: openProfileMenu ? 'rotate(180deg)' : 'rotate(0deg)',
                        }}
                      />
                    </div>
                    <Popper
                      open={openProfileMenu}
                      anchorEl={menuRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                              <div style={{ padding: '0.75rem', display: 'flex', flexDirection: 'column' }}>
                                <a
                                  href='https://account.leitmed.de/Profil'
                                  target='_blank'
                                  rel='noreferrer'
                                  style={{
                                    marginBottom: '1rem',
                                  }}
                                >
                                  <span
                                    className='action-button__default'
                                    style={{
                                      marginBottom: '1rem',
                                      lineHeight: '1rem',
                                    }}
                                    onClick={() => setOpenProfileMenu(false)}
                                  >
                                    Profil
                                  </span>
                                </a>
                                <span
                                  className='action-button__default'
                                  onClick={() => {
                                    logout();
                                    setOpenProfileMenu(false);
                                  }}
                                  style={{
                                    lineHeight: '1rem',
                                  }}
                                >
                                  Ausloggen
                                </span>
                                {/* <Link
                                      to='/profile/change-password'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ lineHeight: '1rem' }}
                                    >
                                      Passwort ändern
                                    </Link> */}
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </>
                ) : (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <BookmarkIcon
                      ref={bookmarkRef}
                      onClick={handleBookmarks}
                      className='icon-button__dark'
                      style={{ margin: '0 1rem' }}
                    />
                    <Popper
                      open={openBookmarks}
                      anchorEl={bookmarkRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={() => setOpenBookmarks(false)}>
                              <div
                                style={{
                                  padding: '0.75rem',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  width: '320px',
                                  maxHeight: '90vh',
                                  overflow: 'auto',
                                }}
                              >
                                {isLoading
                                  ? range(10).map((el: number) => (
                                      <Skeleton
                                        width='calc(320px - 1.5rem)'
                                        height='1.5rem'
                                        style={{ margin: '0.75rem 0' }}
                                        key={el}
                                      />
                                    ))
                                  : bookmarks?.map((bookmark: IBookmark) => (
                                      <div
                                        style={{
                                          width: '100%',
                                          margin: '0.75rem 0',
                                          display: 'flex',
                                          flexWrap: 'nowrap',
                                          alignItems: 'center',
                                          justifyContent: 'space-between',
                                          overflow: 'hidden',
                                        }}
                                        key={bookmark.objectid}
                                      >
                                        <Link
                                          to={
                                            bookmark.objecttype.toLowerCase() === 'document'
                                              ? `/document/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'audio'
                                              ? `/audio/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'video' ||
                                                bookmark.objecttype.toLowerCase() === 'url'
                                              ? `/video/${bookmark.objectid}`
                                              : bookmark.objecttype.toLowerCase() === 'contenthub'
                                              ? `/hub/${bookmark.objectid}`
                                              : ''
                                          }
                                          className='action-button__default'
                                          onClick={() => {
                                            setOpenBookmarks(false);
                                            if (bookmark.objecttype.toLowerCase() === 'image') {
                                              setPreview(bookmark);
                                            }
                                          }}
                                          style={{
                                            lineHeight: '1rem',
                                            maxWidth: 'calc(100% - 1.5rem)',
                                          }}
                                        >
                                          <Tooltip
                                            title={
                                              <>
                                                <LandingTypographyComponent
                                                  variant='body2'
                                                  color='inherit'
                                                  dangerouslySetInnerHTML={{
                                                    __html: `${bookmark.title}`,
                                                  }}
                                                />
                                              </>
                                            }
                                            arrow
                                          >
                                            <LandingTypographyComponent
                                              variant='body1'
                                              noWrap
                                              dangerouslySetInnerHTML={{ __html: `${bookmark.title}` }}
                                            />
                                          </Tooltip>
                                        </Link>
                                        <div className='icon-button-wrapper' style={{ marginLeft: '0.5rem' }}>
                                          <DeleteIcon
                                            className='icon-button__primary'
                                            onClick={(e) => deleteBookmark(e, bookmark)}
                                          />
                                        </div>
                                      </div>
                                    ))}
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                    <BurgerIcon
                      ref={menuRef}
                      className='icon-button__dark'
                      onClick={() => setOpenProfileMenu(!openProfileMenu)}
                    />
                    <Popper
                      open={openProfileMenu}
                      anchorEl={menuRef.current}
                      role={undefined}
                      transition
                      disablePortal
                      style={{ zIndex: 1 }}
                    >
                      {({ TransitionProps, placement }) => (
                        <Grow
                          {...TransitionProps}
                          style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                          }}
                        >
                          <Paper elevation={1}>
                            <ClickAwayListener onClickAway={() => setOpenProfileMenu(false)}>
                              <div
                                style={{
                                  padding: '0.75rem',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'flex-end',
                                }}
                              >
                                <a
                                  href='https://account.leitmed.de/Profil'
                                  target='_blank'
                                  rel='noreferrer'
                                  style={{
                                    marginBottom: '1rem',
                                  }}
                                >
                                  <span
                                    className='action-button__default'
                                    onClick={() => setOpenProfileMenu(false)}
                                    style={{
                                      lineHeight: '1rem',
                                    }}
                                  >
                                    Profil
                                  </span>
                                </a>
                                <span
                                  className='action-button__default'
                                  onClick={() => {
                                    logout();
                                    setOpenProfileMenu(false);
                                  }}
                                  style={{
                                    lineHeight: '1rem',
                                  }}
                                >
                                  Ausloggen
                                </span>
                                {/* <Link
                                      to='/profile/change-password'
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Passwort ändern
                                    </Link> */}
                                {/* <span
                                      className='action-button__default'
                                      onClick={() => setOpenProfileMenu(false)}
                                      style={{ marginBottom: '0.5rem', lineHeight: '1rem' }}
                                    >
                                      Notifications
                                    </span> */}
                              </div>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      )}
                    </Popper>
                  </div>
                )}
              </>
            )}
          </Toolbar>
          {!!isLogged &&
            deviceXs &&
            !window.location.href.includes('/auth') &&
            !window.location.href.includes('/profile') && (
              <SearchInput searchTag={searchTag} setSearchTag={setSearchTag} />
            )}
        </Container>
      </AppBar>
      <div
        ref={drop}
        style={{
          height: '100%',
          minHeight: `calc(100vh - ${!isLogged ? '6rem' : deviceXs ? '8rem' : '9.5rem'})`,
          marginTop: less600 ? `${headerHeight - 10}px` : `${headerHeight}px`,
        }}
      >
        {podcast && <AudioPlayer position={position} banner />}

        {children}
      </div>
      <Zoom in={showBackToTop}>
        <div onClick={handleBackToTop} role='presentation' style={{ position: 'fixed', bottom: '2rem', right: '2rem' }}>
          <Fab color='default' size='small' aria-label='scroll back to top' style={{ opacity: 0.7 }}>
            <ChevronDownIcon fill='#2C364F' style={{ transform: 'rotate(180deg)', width: '1rem', height: '1rem' }} />
          </Fab>
        </div>
      </Zoom>
      <Footer />
      {!!preview && <ImgPreview teaser={preview} open={!!preview} onClose={() => setPreview(null)} />}
    </div>
  );
};

export default Nav;
