import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { LandingButton ,LandingButtonB} from './Landing';
import LandingTypographyComponent  from './LandingTypography';
import { Link } from 'react-router-dom';
import { LoginPopper } from '../reusable/LoginPopper';

interface Props {
  img: any;
  title: string;
  content: string;
  hubId: string;
}

const LandingHub = ({ img, title, content, hubId }: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;  
  return (
    <Grid item xs={12} sm={9} md={4}>
      <div className='landing_box' style={{ height: '100%' }}>
        <div className='landing_img-wrapper'>
          <img className='landing_img' src={img} alt='' />
          {/* <div className="landing_img_gradient landing_img_gradient--h15rem"></div> */}
        </div>
        <div
          style={{
            padding: '3rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: 'calc(100% - 15rem)',
          }}
        >
          <div>
            <LandingTypographyComponent
              variant='h3'
              color='primary'
              style={{ marginBottom: '2rem' }}
              dangerouslySetInnerHTML={{ __html: title || '' }}
            />
            <LandingTypographyComponent
              variant='body1'
              color='textPrimary'
              style={{ marginBottom: '2rem' }}
              dangerouslySetInnerHTML={{ __html: content || '' }}
            />
          </div>
          <div className='landing_buttons-wrapper'>
              <LandingButtonB  variant='contained' color='primary' onClick={handleClick}>
                Mehr erfahren
              </LandingButtonB>
              <LoginPopper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        >
          <LandingTypographyComponent variant="body1" color="initial">
            Sie sind bereits auf der LeitMed, Gelbe Liste oder data4doc als approbierter Arzt oder Apotheker registriert? Dann loggen Sie sich hier ein:
          </LandingTypographyComponent>
          <a href='https://account.leitmed.de/Login'>
            <LandingButtonB
              variant='contained'
              color='secondary'
              style={{ marginTop: '8px' }}
            >
              Weiter zu Login
            </LandingButtonB>
          </a>
        </LoginPopper>

          </div>
        </div>
      </div>
    </Grid>
  );
};

export default LandingHub;
