import React from 'react';
import { Container } from '@material-ui/core';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';
import { ReactComponent as LinkedInIcon } from '../../media/icons/linkedin.svg';
import { ReactComponent as XingIcon } from '../../media/icons/xing.svg';

interface Props {}

const Datenschutz = (props: Props) => {
  return (
    <div style={{ padding: '3rem 0' }}>
      <Container maxWidth='lg'>
        <h1 className='article_title'>Datenschutzerklärung</h1>

        <div className='content-wrapper'>
          <h6 style={{ fontSize: '1rem' }}>
            Nachfolgend finden Sie die für alle Produkte und Geschäftsvorgänge gültige Datenschutzerklärung der Vidal
            MMI Germany GmbH ("Vidal MMI"). Datenschutz und Datensicherheit haben für uns eine hohe Priorität. Bei der
            Erhebung, Verarbeitung, Sicherung und Nutzung Ihrer Daten richten wir uns nach den datenschutzrechtlichen
            Vorschriften, insbesondere denen des Bundesdatenschutzgesetzes (BDSG) des Telemediengesetzes (TMG) und der
            Datenschutz-Grundverordnung der Europäischen Union (EU-DSGVO).
          </h6>
          <div>
            <h2 id='Kontaktdaten'>Kontaktdaten</h2>
            <p>
              <strong>Verantwortlicher gem. Art.&nbsp;4 Nr.&nbsp;7 EU-Datenschutz-Grundverordnung (DS-GVO)</strong>
              <br />
              <br />
              Vidal MMI Germany GmbH
              <br />
              Monzastraße 4<br />
              63225 Langen
              <br />
              Telefon: 06103-2076-0
              <br />
              E-Mail: <a href='mailto:datenschutz@mmi.de'>datenschutz@mmi.de</a>
              <br />
              <br />
              <strong>Zuständiger Datenschutzbeauftragter</strong>
              <br />
              <br />
              CTM-COM GmbH
              <br />
              In den Leppsteinswiesen 14
              <br />
              64380 Roßdorf
              <br />
              Telefon: 06154 57605-111
              <br />
              E-Mail: <a href='mailto:datenschutz@ctm-com.de'>datenschutz@ctm-com.de</a>
              <br />
              <br />
              <strong>Zuständige Datenschutz-Aufsichtsbehörde</strong>
              <br />
              <br />
              Der Hessische Beauftragte für Datenschutz und Informationsfreiheit
              <br />
              Gustav-Stresemann-Ring 1<br />
              65189 Wiesbaden
            </p>
            <p>
              Bei Ihrer Kontaktaufnahme mit uns per Post, Telefon, Fax, E-Mail oder über ein Online-Kontaktformular
              werden die von Ihnen mitgeteilten Daten (Ihre E-Mail-Adresse, ggf. Ihr Name und Ihre Telefonnummer) von
              uns gespeichert, um Ihre Fragen zu beantworten. Die in diesem Zusammenhang anfallenden Daten löschen wir,
              nachdem die Speicherung nicht mehr erforderlich ist, oder schränken die Verarbeitung ein, falls
              gesetzliche Aufbewahrungspflichten bestehen.
            </p>
            <p>
              Falls wir für einzelne Funktionen unseres Angebots auf beauftragte Dienstleister zurückgreifen oder Ihre
              Daten für werbliche Zwecke nutzen möchten, werden wir Sie untenstehend im Detail über die jeweiligen
              Vorgänge informieren. Dabei nennen wir auch die festgelegten Kriterien der Speicherdauer.
            </p>
            <div></div>
            <h2 id='Ihre-Rechte'>Ihre Rechte</h2>
            <p>
              Sie haben als betroffene Person dieser Datenverarbeitung folgende Rechte, die Sie gegenüber uns und/oder
              unseren Dienstleistern wahrnehmen können:
            </p>
            <ul>
              <li>Recht auf Auskunft,</li>
              <li>Recht auf Berichtigung oder Löschung,</li>
              <li>Recht auf Einschränkung der Verarbeitung,</li>
              <li>Recht auf Widerspruch oder Widerruf gegen die Verarbeitung,</li>
              <li>Recht auf Datenübertragbarkeit.</li>
            </ul>
            <p>
              Gerne können Sie sich an die E-Mail-Adresse <a href='mailto:datenschutz@mmi.de'>datenschutz@mmi.de</a>{' '}
              wenden um Ihre Betroffenenrechte wahrzunehmen. Sie haben ebenfalls das Recht, sich bei der zuständigen
              Aufsichtsbehörde (Adresse siehe oben) zu beschweren, wenn eine Datenschutzverletzung oder der Verdacht auf
              eine Datenschutzverletzung vorliegt.
            </p>
            <h2>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</h2>
            <p>
              Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese jederzeit
              widerrufen. Ein solcher Widerruf beeinflusst die Zulässigkeit der Verarbeitung Ihrer personenbezogenen
              Daten, nachdem Sie ihn gegenüber uns ausgesprochen haben.
            </p>
            <p>
              Soweit wir die Verarbeitung Ihrer personenbezogenen Daten auf die Interessenabwägung stützen, können Sie
              Widerspruch gegen die Verarbeitung einlegen. Dies ist der Fall, wenn die Verarbeitung insbesondere nicht
              zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, was von uns jeweils bei der nachfolgenden
              Beschreibung der Funktionen dargestellt wird. Bei Ausübung eines solchen Widerspruchs bitten wir um
              Darlegung der Gründe, weshalb wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten
              sollten. Im Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die
              Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen Gründe aufzeigen,
              aufgrund derer wir die Verarbeitung fortführen.
            </p>
            <p>
              Selbstverständlich können Sie der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
              Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter folgenden
              Kontaktdaten informieren:
              <br />
              <br />
              Datenschutzkoordinator der Vidal MMI Germany GmbH
              <br />
              Vidal MMI Germany GmbH
              <br />
              Monzastraße 4<br />
              63225 Langen
              <br />
              E-Mail: <a href='mailto:datenschutz@mmi.de'>datenschutz@mmi.de</a>
            </p>
          </div>
          <div>
            <h2 id='Information-über-die-Verarbeitung-personenbezogener-Daten'>
              Information über die Verarbeitung personenbezogener Daten
            </h2>
            <p>
              Im Folgenden informieren wir über die Verarbeitung personenbezogener Daten bei der Nutzung unserer
              Produkten und Angebote. Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte
              oder identifizierbare natürliche Person (betroffene Person) beziehen, wie z.B. Name, Adresse, E-Mail,
              Telefonnummer, etc.
            </p>
            <p>
              <strong>
                Zweck der Verarbeitung Ihrer personenbezogenen Daten sowie die Rechtsgrundlage zur Verarbeitung
              </strong>
            </p>
            <p>Wir verarbeiten Ihre personenbezogenen Daten zu folgenden Zwecken:</p>
            <table style={{ width: '100%' }}>
              <thead>
                <tr>
                  <th scope='col'>Zweckbeschreibung:</th>
                  <th scope='col'>Rechtsgrundlage:</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Datenverarbeitung für eine einfachere Kommunikation im Rahmen eines Vertragsverhältnisses.</td>
                  <td>Art. 6 Abs. 1 lit. b DS-GVO</td>
                </tr>
                <tr>
                  <td>Datenverarbeitung erfolgt zur Durchführung eines Vertrages.</td>
                  <td>Art. 6 Abs. 1 lit. b DS-GVO</td>
                </tr>
                <tr>
                  <td>
                    Datenverarbeitung erfolgt zur Begründung, Durchführung oder Beendigung eines
                    Beschäftigungsverhältnisses.
                  </td>
                  <td>Art. 88 DS-GVO i.V. § 26 BDSG</td>
                </tr>
                <tr>
                  <td>
                    Datenverarbeitung erfolgt durch Registrierung zur Nutzung unserer digitalen Angebote im Rahmen des
                    Heilmittelwerbegesetzes.
                  </td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
                <tr>
                  <td>
                    Datenverarbeitung erfolgt durch anonymisierte Analyse von Nutzerverhalten auf unseren digitalen
                    Angeboten zur Optimierung dieser digitalen Angebote
                  </td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
                <tr>
                  <td>Datenverarbeitung erfolgt zur Zustellung von Newslettern.</td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
                <tr>
                  <td>Datenverarbeitung erfolgt zur Weiterleitung Ihrer Anfragen an Rehakliniken</td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
                <tr>
                  <td>Datenverarbeitung erfolgt zum Versand von Arzneimittelmustern</td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
                <tr>
                  <td>Datenverarbeitung erfolgt zur Validierung von Adressdaten und beruflichen Angaben</td>
                  <td>Art. 6 Abs. 1 lit. a DS-GVO</td>
                </tr>
              </tbody>
            </table>
            <p>&nbsp;</p>
            <p>
              <strong>Erhobene Datenkategorien:</strong>
            </p>
            <ul>
              <li>
                Bewerberdaten, z. B. Name, Vorname, Titel, Adress- und Kontaktdaten, Daten aus dem Lebenslauf,
                Zeugniskopien, Lichtbilddaten, ggf. weitere Daten;
              </li>
              <li>
                Personaldaten, z. B. Name, Vorname, Titel, Adress- und Kontaktdaten, Lichtbilddaten, Gesundheitsdaten
                (Krankheiten, Gesundheitszustand (Schwerbehinderung etc.), ggf. weitere Daten;
              </li>
              <li>
                Nutzerdaten, z. B. Name, Vorname, Titel, E-Mail-Adresse, Postleitzahl, Wohnort, Straße, Land, Beruf,
                Fachbereich, Qualifikationen, Tätigkeitsbereich, LANR, EFN, Gesundheitsdaten, ggf. weitere Daten)
              </li>
              <li>Bankdaten, z. B. BLZ, IBAN, BIC</li>
            </ul>
            <p>
              <strong>Empfänger der Daten:</strong>
            </p>
            <ul>
              <li>
                Interne Empfänger: Geschäftsführung, Personalabteilung, Marketing- und Vertriebsabteilungen,
                IT-Mitarbeiter, Supportmitarbeiter, ggf. weitere Empfänger;
              </li>
              <li>
                Externe Empfänger: Dienstleister (Firma/Name, Adress- und Kontaktdaten), Hosting-Provider (Firma/Name,
                Adress- und Kontaktdaten, Nutzungsdaten), Geschäftspartner wie Rehakliniken oder Softwarehäuser
                (Firma/Name, Adress- und Kontaktdaten), Behörden (Name, Adress- und Kontaktdaten), ggf. weitere
                Empfänger;
              </li>
            </ul>
            <p>
              <strong>Dauer der Datenspeicherung:</strong>
            </p>
            <p>
              Bereitgestellte personenbezogene Daten werden ausschließlich zweckgebunden verarbeitet und nur solange
              gespeichert, wie dies für den vorgesehenen Zweck erforderlich oder zur Erfüllung von gesetzlichen
              Aufbewahrungsvorschiften notwendig ist. Anschließend, oder bei Wegfall der Rechtsgrundlage, werden Ihre
              personenbezogenen Daten gelöscht.
            </p>
          </div>
          <div>
            <h2 id='Erhebung-personenbezogener-Daten-bei-Nutzung-unserer-digitalen-Angebote'>
              Erhebung personenbezogener Daten bei Nutzung unserer digitalen Angebote
            </h2>
            <p>
              Bei der bloß informatorischen Nutzung unserer digitalen Angebote (Websites, App oder sonstige
              Online-Dienste), also auch, wenn Sie sich nicht registrieren oder uns anderweitig Informationen
              übermitteln, erheben wir nur die personenbezogenen Daten, die Ihr Browser an unseren Server übermittelt.
              Wenn Sie unsere Website betrachten möchten, erheben wir die folgenden Daten, die für uns technisch
              erforderlich sind, um Ihnen unsere Website anzuzeigen und die Stabilität und Sicherheit zu gewährleisten.
              Rechtsgrundlage für die vorübergehende Speicherung der Daten und der Logfiles ist Art.&nbsp;6 Abs.&nbsp;1
              S.&nbsp;1 lit. f DS-GVO:
            </p>
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Anfrage</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
              <li>Inhalt der Anforderung (konkrete Seite)</li>
              <li>Zugriffsstatus/HTTP-Statuscode</li>
              <li>jeweils übertragene Datenmenge</li>
              <li>Website, von der die Anforderung kommt</li>
              <li>Typ, Version und Sprache der Browsersoftware</li>
              <li>Betriebssystem und dessen Oberfläche</li>
            </ul>
          </div>
          <div>
            <h2 id='Einsatz-von-Cookies'>Einsatz von Cookies</h2>
            <ol>
              <li>
                Beim Besuch unserer Websites werden Cookies auf Ihrem Rechner gespeichert. Bei Cookies handelt es sich
                um kleine Textdateien, die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert
                werden und durch welche der Stelle, die den Cookie setzt (hier durch uns), bestimmte Informationen
                zufließen. Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. Sie dienen
                dazu, das Internetangebot insgesamt nutzerfreundlicher und effektiver zu machen.
              </li>
              <li>
                Diese Website nutzt folgende Arten von Cookies, deren Umfang und Funktionsweise im Folgenden erläutert
                werden:
                <br />
                <strong>Transiente Cookies: </strong>Transiente Cookies werden automatisiert gelöscht, wenn Sie den
                Browser schließen. Dazu zählen insbesondere die Session-Cookies. Diese speichern eine sogenannte
                Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen
                lassen. Dadurch kann Ihr Rechner wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die
                Session-Cookies werden gelöscht, wenn Sie sich ausloggen oder den Browser schließen.
                <br />
                <strong>Persistente Cookies:</strong> Persistente Cookies werden automatisiert nach einer vorgegebenen
                Dauer gelöscht, die sich je nach Cookie unterscheiden kann. Sie können die Cookies in den
                Sicherheitseinstellungen Ihres Browsers jederzeit löschen.
              </li>
              <li>
                Sie können Ihre Browser-Einstellung entsprechend Ihren Wünschen konfigurieren und z. B. die Annahme von
                Third-Party-Cookies oder allen Cookies ablehnen. Wir weisen Sie darauf hin, dass Sie eventuell nicht
                alle Funktionen dieser Website nutzen können.
              </li>
              <li>
                Wir setzen Cookies ein, um Sie für Folgebesuche identifizieren zu können, falls Sie über ein Nutzerkonto
                bei uns verfügen. Andernfalls müssten Sie sich für jeden Besuch erneut einloggen.
              </li>
              <li>Unsere Apps verwenden statt Cookies eine in ihrer Funktion vergleichbare Technologie.</li>
            </ol>
          </div>
          <div>
            <h2 id='Erhebung-personenbezogener-Daten-bei-Nutzung-unserer-mobilen-Apps'>
              Erhebung personenbezogener Daten bei Nutzung unserer mobilen Apps
            </h2>
            <p>
              Bei Herunterladen unserer mobilen Apps werden die erforderlichen Informationen an den jeweiligen App Store
              übertragen, also insbesondere Nutzername, E-Mail-Adresse und Kundennummer Ihres Accounts, Zeitpunkt des
              Downloads, ggf. Zahlungsinformationen und die individuelle Gerätekennziffer. Auf diese Datenerhebung haben
              wir keinen Einfluss und sind nicht dafür verantwortlich. Wir verarbeiten die Daten nur, soweit es für das
              Herunterladen der mobilen App auf Ihr mobiles Endgerät notwendig ist.
            </p>
          </div>
          <div>
            <h2 id='Erhebung-personenbezogener-Daten-bei-Anmeldung-Registrierung'>
              Erhebung personenbezogener Daten bei Anmeldung/Registrierung
            </h2>
            <p>
              Unsere Websites oder Apps erfordern zur vollumfänglichen Nutzung eine Registrierung unter Angabe
              persönlicher Daten. Die Verarbeitung dieser Daten dient der Sicherstellung, dass angemeldeten Nutzern
              sowohl werbliche als auch redaktionelle medizinische Informationen angezeigt werden können, die nach den
              Bestimmungen des deutschen{' '}
              <a
                href='https://www.gesetze-im-internet.de/heilmwerbg/BJNR006049965.html'
                target='_blank'
                rel='noreferrer'
              >
                Heilmittelwerbegesetzes
              </a>{' '}
              nur für approbierte Ärzte und Apotheker und/oder für weitere Angehörige bestimmter medizinischer
              Fachkreise zulässig sein können. Weiterhin dient sie dem Zweck, redaktionelle und werbliche Informationen
              nur passenden regionalen oder beruflichen Nutzergruppen darzustellen und damit die Qualität der Angebote
              zu optimieren.
            </p>
            <p>
              Die Angabe der nachfolgend genannten Daten ist in diesem Fall verpflichtend, alle weiteren Informationen
              können Sie freiwillig durch Nutzung unseres Portals bereitstellen:
            </p>
            <ul>
              <li>E-Mail-Adresse (als eindeutiger Nutzername)</li>
              <li>selbst gewähltes Passwort</li>
              <li>Anrede</li>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Postleitzahl/Ort</li>
              <li>Land</li>
              <li>Beruf und ggf. berufliches Fachgebiet</li>
            </ul>
            <p>
              Es besteht kein Klarnamenszwang, eine pseudonyme Nutzung ist möglich. Wir verwenden für die Registrierung
              das sog. Double-opt-in-Verfahren, d. h. Ihre Registrierung ist erst abgeschlossen, wenn Sie zuvor Ihre
              Anmeldung über eine Ihnen zu diesem Zweck zugesandte Bestätigungs-E-Mail durch Klick auf den darin
              enthaltenem Link bestätigt haben. Falls Ihre diesbezügliche Bestätigung nicht binnen 30 Tagen erfolgt,
              wird Ihre Anmeldung automatisch aus unserer Datenbank gelöscht.
            </p>
            <p>
              Wenn Sie unser Portal nutzen, speichern wir Ihre zur Vertragserfüllung erforderlichen Daten, bis Sie Ihren
              Zugang endgültig löschen. Weiterhin speichern wir die von Ihnen angegebenen freiwilligen Daten für die
              Zeit Ihrer Nutzung des Portals, soweit Sie diese nicht zuvor löschen. Alle Angaben können Sie im
              geschützten Kundenbereich verwalten und ändern. Rechtsgrundlage sind Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1
              lit. a DS-GVO und Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.
            </p>
            <p>
              Um unberechtigte Zugriffe Dritter auf Ihre persönlichen Daten zu verhindern, wird die Verbindung per
              SSL-Technik verschlüsselt
            </p>
            <h2 id='newsletter'>Verarbeitung personenbezogener Daten zum Newsletterversand</h2>
            <p>
              Bei Nutzung unserer Angebote haben Sie die Möglichkeit Newsletter zu abonnieren, die die Angabe
              persönlicher Daten erfordern. Die Verarbeitung dieser Daten dient der persönlichen Ansprache bei
              Zustellung sowie der Sicherstellung, dass angemeldeten Nutzern sowohl werbliche als auch redaktionelle
              medizinische Informationen angezeigt werden können, die nach den Bestimmungen des deutschen{' '}
              <a
                href='https://www.gesetze-im-internet.de/heilmwerbg/BJNR006049965.html'
                target='_blank'
                rel='noreferrer'
              >
                Heilmittelwerbegesetzes
              </a>{' '}
              nur für approbierte Ärzte und Apotheker und/oder für weitere Angehörige bestimmter medizinischer
              Fachkreise zulässig sein können. Weiterhin dient sie dem Zweck, redaktionelle und werbliche Informationen
              nur passenden regionalen oder beruflichen Nutzergruppen darzustellen und damit die Qualität der Angebote
              zu optimieren. Rechtsgrundlage ist Art. 6 Abs. 1 lit. a DSGVO.
            </p>
            <p>
              Jeder Newsletter kann jederzeit durch einen bereitgestellten Link im Newsletter oder im jeweiligen
              Angebot, z. B. in Ihrem persönlichen Nutzerprofil, selbst abbestellt werden. Die erhobenen Daten werden in
              diesem Fall gesperrt. Sie haben das Recht, eine erteilte Einwilligung für die Nutzung Ihrer Daten zum
              Newsletterversand mit Wirkung für die Zukunft jederzeit zu widerrufen. Bitte wenden Sie sich dazu formlos
              an uns unter{' '}
              <a href='mailto:datenschutz@mmi.de?subject=Datenschutzanfrage%20zu%20Gelbe%20Liste%20online'>
                datenschutz@mmi.de
              </a>
              .
            </p>
            <p>Folgende Daten werden für den Versand von Newslettern verarbeitet:</p>
            <ul>
              <li>E-Mail-Adresse</li>
              <li>Anrede</li>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Postleitzahl/Ort</li>
              <li>Land</li>
              <li>Beruf und ggf. berufliches Fachgebiet</li>
            </ul>
            <p>
              Sofern Sie einen oder mehrere Newsletter abonniert haben, werden Ihre hierfür erforderlichen Daten sowohl
              von MMI als auch vom beauftragten Dienstleister ausschließlich für den Betrieb des Newsletters verwendet
              und nur kumuliert und anonymisiert zu statistischen Zwecken ausgewertet. Rechtsgrundlage für die
              Verarbeitung Ihrer persönlichen Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. a DS-GVO.
            </p>
            <h3>Den PraxisLetter versenden wir in Zusammenarbeit mit dem Verlagshaus</h3>
            <p>
              Medical Tribune Verlagsgesellschaft mbH
              <br />
              Unter den Eichen 5<br />
              65195 Wiesbaden
              <br />
              Deutschland
            </p>
            <p>
              Der PraxisLetter wird in Zusammenarbeit mit der und durch die Medical Tribune Verlagsgesellschaft mbH
              versendet. Um den Versand des PraxisLetters zu ermöglichen, werden bei der freiwilligen Bestellung
              folgende Daten an die Medical Tribune Verlagsgesellschaft mbH übermittelt und explizit nur zu diesem Zweck
              verwendet:
            </p>
            <ul>
              <li>E-Mail-Adresse</li>
              <li>Anrede</li>
              <li>Vorname</li>
              <li>Nachname</li>
              <li>Beruf</li>
            </ul>
            <p>
              Um neue Anmeldungen und Abmeldungen korrekt zu erfassen, findet für die Abonnenten des PraxisLetters ein
              regelmäßiger Datenabgleich zwischen Medizinischen Medien Informations GmbH und der Medical Tribune
              Verlagsgesellschaft mbH statt. Bei Abmeldung des PraxisLetters werden Ihre vorher übermittelten Daten
              umgehend aus der Datenbank der Medical Tribune Verlagsgesellschaft mbH gelöscht.
            </p>
            <p>
              Mit der Medical Tribune Verlagsgesellschaft mbH besteht hierzu ein Vertrag zur Auftragsverarbeitung.
              Weitere Informationen finden Sie auch auf der Seite{' '}
              <a href='https://www.medical-tribune.de/datenschutzbestimmungen/' target='_blank' rel='noreferrer'>
                Datenschutzbestimmungen
              </a>{' '}
              der Medical Tribune Verlagsgesellschaft mbH.
            </p>
            <h3>Alle Newsletter versenden wir in Zusammenarbeit mit dem technischen Dienstleister</h3>
            <p>
              CleverReach GmbH &amp; Co. KG
              <br />
              Mühlenstr. 43
              <br />
              26180 Rastede
              <br />
              Deutschland
            </p>
            <p>
              Hierfür werden Daten von CleverReach so gespeichert und verarbeitet, dass Dritte keinen Zugriff auf diese
              Daten haben. Mit der CleverReach GmbH &amp; Co. KG besteht hierzu ein Vertrag zur Auftragsverarbeitung.
              Weitere Informationen finden Sie auch auf den Seiten{' '}
              <a href='https://www.cleverreach.com/de/datenschutz/' target='_blank' rel='noreferrer'>
                Datenschutz
              </a>
              ,{' '}
              <a href='https://www.cleverreach.com/de/datensicherheit/' target='_blank' rel='noreferrer'>
                Datensicherheit
              </a>{' '}
              und{' '}
              <a href='https://www.cleverreach.com/de/antispam/' target='_blank' rel='noreferrer'>
                Antispam-Regeln
              </a>{' '}
              von CleverReach.
            </p>
          </div>
          <div>
            <h2 id='Verwendung-von-Online-Analysediensten'>Verwendung von Online-Analysediensten</h2>
            <h3>Adobe Analytics (Omniture)</h3>
            <p>
              Unsere Websites und Apps nutzen den Webanalysedienst Adobe Analytics (Omniture), um die Nutzung unserer
              Website analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser
              Angebot verbessern und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen
              personenbezogene Daten in die USA übertragen werden, hat sich Adobe dem EU-US Privacy Shield unterworfen,{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              . Rechtsgrundlage für die Nutzung von Adobe Analytics ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.
            </p>
            <p>
              Für diese Auswertung werden Cookies auf Ihrem Computer gespeichert, die eine Analyse der Benutzung der
              Website durch Sie ermöglichen. Die so erhobenen Informationen werden auf Servern des Adobe, auch in den
              USA, gespeichert. Wenn Sie die Speicherung der Cookies verhindern, weisen wir darauf hin, dass Sie dann
              gegebenenfalls diese Website nicht vollumfänglich nutzen können. Die Verhinderung der Speicherung von
              Cookies ist möglich durch die Einstellung in ihrem Browser oder indem Sie die Buttons "Abmelden" unter{' '}
              <a href='https://www.adobe.com/de/privacy/opt-out.html' target='_blank' rel='noreferrer'>
                https://www.adobe.com/de/privacy/opt-out.html
              </a>{' '}
              betätigen.
            </p>
            <p>
              Unsere Websites und App verwenden Adobe Analytics (Omniture) mit den Einstellungen „Before Geo-Lookup:
              Replace visitor’s last IP octet with 0“ und „Obfuscate IP-Removed“, durch die Ihre IP-Adresse um das
              letzte Oktett gekürzt und durch eine generische IP-Adresse, also eine nicht mehr zuordenbare, ersetzt
              wird. Eine Personenbeziehbarkeit ist damit auszuschließen.
            </p>
            <p>
              Informationen des Drittanbieters: Adobe Systems Software Ireland Limited, Ireland, 4–6 Riverwalk, Citywest
              Business Campus, Dublin 24, Republic of Ireland; privacy@adobe.com; Datenschutzhinweise:{' '}
              <a href='https://www.adobe.com/de/privacy/policy.html' target='_blank' rel='noreferrer'>
                https://www.adobe.com/de/privacy/policy.html
              </a>
              .
            </p>
            <h3>Google Analytics</h3>
            <p>
              Unsere Websites und Apps nutzen den Webanalysedient Google Analytics, um die Nutzung unserer Website
              analysieren und regelmäßig verbessern zu können. Über die gewonnenen Statistiken können wir unser Angebot
              verbessern und für Sie als Nutzer interessanter ausgestalten. Für die Ausnahmefälle, in denen
              personenbezogene Daten in die USA übertragen werden, hat sich Google dem EU-US Privacy Shield unterworfen,{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              . Rechtsgrundlage für die Nutzung von Google Analytics ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f
              DS-GVO.
            </p>
            <p>
              Für diese Auswertung werden Cookies auf Ihrem Computer gespeichert, die eine Analyse der Benutzung der
              Website durch Sie ermöglichen. Die so erhobenen Informationen über Ihre Benutzung dieser Website werden in
              der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Im Falle der Aktivierung
              der IP-Anonymisierung auf dieser Website, wird Ihre IP-Adresse von Google jedoch innerhalb von
              Mitgliedstaaten der Europäischen Union oder in anderen Vertragsstaaten des Abkommens über den Europäischen
              Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen wird die volle IP-Adresse an einen Server von Google
              in den USA übertragen und dort gekürzt. Im Auftrag des Betreibers dieser Website wird Google diese
              Informationen benutzen, um Ihre Nutzung der Website auszuwerten, um Reports über die Website-Aktivitäten
              zusammenzustellen und um weitere mit der Website-Nutzung und der Internetnutzung verbundene
              Dienstleistungen gegenüber dem Website-Betreiber zu erbringen.
            </p>
            <p>
              Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
              von Google zusammengeführt.
            </p>
            <p>
              Sie können die Speicherung der Cookies durch eine entsprechende Einstellung Ihrer Browser-Software
              verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall gegebenenfalls nicht sämtliche
              Funktionen dieser Website vollumfänglich werden nutzen können. Sie können darüber hinaus die Erfassung der
              durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an
              Google sowie die Verarbeitung dieser Daten durch Google verhindern, indem sie das unter dem folgenden Link
              verfügbare Browser-Plug-in herunterladen und installieren:{' '}
              <a href='https://tools.google.com/dlpage/gaoptout?hl=de' target='_blank' rel='noreferrer'>
                https://tools.google.com/dlpage/gaoptout?hl=de
              </a>
              .
            </p>
            <p>
              Wir verwenden Google Analytics mit der Erweiterung "<strong>_anonymizeIp()</strong>". Dadurch werden
              IP-Adressen gekürzt weiterverarbeitet, eine Personenbeziehbarkeit kann damit ausgeschlossen werden. Soweit
              den über Sie erhobenen Daten ein Personenbezug zukommt, wird dieser also sofort ausgeschlossen und die
              personenbezogenen Daten damit umgehend gelöscht.
            </p>
            <p>
              Informationen des Drittanbieters: Google Dublin, Google Ireland Ltd., Gordon House, Barrow Street, Dublin
              4, Ireland, Fax: +353 (1) 436 1001. Nutzungsbedingungen:{' '}
              <a href='https://www.google.com/analytics/terms/de.html' target='_blank' rel='noreferrer'>
                https://www.google.com/analytics/terms/de.html
              </a>
              , Datenschutzhinweise:{' '}
              <a href='https://policies.google.com/privacy?hl=de' target='_blank' rel='noreferrer'>
                https://policies.google.com/privacy?hl=de
              </a>
              .
            </p>
            <h1>Verwendung von Online-Werbediensten</h1>
            <h3>Google AdSense</h3>
            <p>
              Unsere Websites nutzen den Online-Werbedienst Google Adsense, durch den Ihnen auf Ihre Interessen
              ausgelegte Werbung präsentiert werden kann. Wir verfolgen damit das Interesse, Ihnen Werbung anzuzeigen,
              die für Sie von Interesse sein könnte, um unsere Website für Sie interessanter zu gestalten. Hierzu werden
              statistische Informationen über Sie erfasst, die durch unsere Werbepartner verarbeitet werden. Diese
              Werbeanzeigen sind über den Hinweis „Google-Anzeigen“ in der jeweiligen Anzeige erkennbar. Rechtsgrundlage
              für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.
            </p>
            <p>
              Durch den Besuch unserer Websites erhält Google die Information, dass Sie unsere Website aufgerufen haben.
              Dazu nutzt Google einen Web Beacon, um einen Cookie auf Ihrem Rechner zu setzen. Es werden die unter
              §&nbsp;3 dieser Erklärung genannten Daten übermittelt. Wir haben weder Einfluss auf die erhobenen Daten,
              noch ist uns der volle Umfang der Datenerhebung und die Speicherdauer bekannt. Ihre Daten werden in die
              USA übertragen und dort ausgewertet. Wenn Sie mit Ihrem Google-Account eingeloggt sind, können Ihre Daten
              diesem direkt zugeordnet werden. Wenn Sie die Zuordnung mit Ihrem Google-Profil nicht wünschen, müssen Sie
              sich ausloggen. Es ist möglich, dass diese Daten an Vertragspartner von Google an Dritte und Behörden
              weitergegeben werden. Unsere Websites haben auch Google AdSense-Anzeigen von Drittanbietern aktiviert. Die
              vorgenannten Daten können an diese Drittanbieter (benannt unter{' '}
              <a href='https://support.google.com/dfp_sb/answer/94149' target='_blank' rel='noreferrer'>
                https://support.google.com/dfp_sb/answer/94149
              </a>
              ) übertragen werden.
            </p>
            <p>
              Sie können die Installation der Cookies von Google AdSense auf verschiedene Weise verhindern:
              <br />
              a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von
              Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten;
              <br />
              b) durch Deaktivierung der interessenbezogenen Anzeigen bei Google über den Link{' '}
              <a href='https://www.google.de/ads/preferences' target='_blank' rel='noreferrer'>
                https://www.google.de/ads/preferences
              </a>
              , wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
              <br />
              c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der
              Selbstregulierungs-Kampagne „About Ads“ sind, über den Link{' '}
              <a href='http://www.aboutads.info/choices' target='_blank' rel='noreferrer'>
                http://www.aboutads.info/choices
              </a>
              , wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
              <br />
              d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internetexplorer oder Google Chrome unter dem
              Link{' '}
              <a href='https://www.google.com/settings/ads/plugin' target='_blank' rel='noreferrer'>
                https://www.google.com/settings/ads/plugin
              </a>
              . Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots
              vollumfänglich nutzen können.
            </p>
            <p>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung sowie weitere
              Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
              Privatsphäre erhalten Sie bei: Google Inc., 1600 Amphitheater Parkway, Mountainview, California 94043,
              USA; Datenschutzbedingungen für Werbung:{' '}
              <a href='https://www.google.de/intl/de/policies/technologies/ads' target='_blank' rel='noreferrer'>
                https://www.google.de/intl/de/policies/technologies/ads
              </a>
              . Google hat sich dem EU-US Privacy Shield unterworfen,{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              .
            </p>
            <h3>Google Ads</h3>
            <p>
              Unsere Websites nutzen den Online-Werbedienst Google Ads, um mit Hilfe von Werbemitteln (sogenannten
              Google Adwords) auf externen Webseiten auf unsere attraktiven Angebote aufmerksam zu machen. Wir können in
              Relation zu den Daten der Werbekampagnen ermitteln, wie erfolgreich die einzelnen Werbemaßnahmen sind. Wir
              verfolgen damit das Interesse, Ihnen Werbung anzuzeigen, die für Sie von Interesse ist, unsere Website für
              Sie interessanter zu gestalten und eine faire Berechnung von Werbe-Kosten zu erreichen. Rechtsgrundlage
              für die Verarbeitung Ihrer Daten ist Art.&nbsp;6 Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.
            </p>
            <p>
              Diese Werbemittel werden durch Google über sogenannte "AdServer" ausgeliefert. Dazu nutzen wir AdServer
              Cookies, durch die bestimmte Parameter zur Erfolgsmessung, wie Einblendung der Anzeigen oder Klicks durch
              die Nutzer, gemessen werden können. Sofern Sie über eine Google-Anzeige auf unsere Website gelangen, wird
              von Google Ads ein Cookie in ihrem PC gespeichert. Diese Cookies verlieren in der Regel nach 30&nbsp;Tagen
              ihre Gültigkeit und sollen nicht dazu dienen, Sie persönlich zu identifizieren. Zu diesem Cookie werden in
              der Regel als Analyse-Werte die Unique Cookie-ID, Anzahl Ad Impressions pro Platzierung (Frequency),
              letzte Impression (relevant für Post-View-Conversions) sowie Opt-out-Informationen (Markierung, dass der
              Nutzer nicht mehr angesprochen werden möchte) gespeichert.
            </p>
            <p>
              Diese Cookies ermöglichen Google, Ihren Internetbrowser wiederzuerkennen. Sofern ein Nutzer bestimmte
              Seiten der Webseite eines Adwords-Kunden besucht und das auf seinem Computer gespeicherte Cookie noch
              nicht abgelaufen ist, können Google und der Kunde erkennen, dass der Nutzer auf die Anzeige geklickt hat
              und zu dieser Seite weitergeleitet wurde. Jedem Google Ads-Kunden wird ein anderes Cookie zugeordnet.
              Cookies können somit nicht über die Webseiten von Adwords-Kunden nachverfolgt werden. Wir selbst erheben
              und verarbeiten in den genannten Werbemaßnahmen keine personenbezogenen Daten. Wir erhalten von Google
              lediglich statistische Auswertungen zur Verfügung gestellt. Anhand dieser Auswertungen können wir
              erkennen, welche der eingesetzten Werbemaßnahmen besonders effektiv sind. Weitergehende Daten aus dem
              Einsatz der Werbemittel erhalten wir nicht, insbesondere können wir die Nutzer nicht anhand dieser
              Informationen identifizieren.
            </p>
            <p>
              Aufgrund der eingesetzten Marketing-Tools baut Ihr Browser automatisch eine direkte Verbindung mit dem
              Server von Google auf. Wir haben keinen Einfluss auf den Umfang und die weitere Verwendung der Daten, die
              durch den Einsatz dieses Tools durch Google erhoben werden und informieren Sie daher entsprechend unserem
              Kenntnisstand: Durch die Einbindung von Google Ads Conversion erhält Google die Information, dass Sie den
              entsprechenden Teil unseres Internetauftritts aufgerufen oder eine Anzeige von uns angeklickt haben.
              Sofern Sie bei einem Dienst von Google registriert sind, kann Google den Besuch Ihrem Account zuordnen.
              Selbst wenn Sie nicht bei Google registriert sind bzw. sich nicht eingeloggt haben, besteht die
              Möglichkeit, dass der Anbieter Ihre IP-Adresse in Erfahrung bringt und speichert.
            </p>
            <p>
              Sie können die Teilnahme an diesem Tracking-Verfahren auf verschiedene Weise verhindern:
              <br />
              a) durch eine entsprechende Einstellung Ihrer Browser-Software, insbesondere führt die Unterdrückung von
              Drittcookies dazu, dass Sie keine Anzeigen von Drittanbietern erhalten;
              <br />
              b) durch Deaktivierung der Cookies für Conversion-Tracking, indem Sie Ihren Browser so einstellen, dass
              Cookies von der Domain "www.googleadservices.com" blockiert werden,{' '}
              <a href='https://www.google.de/settings/ads' target='_blank' rel='noreferrer'>
                https://www.google.de/settings/ads
              </a>
              , wobei diese Einstellung gelöscht werden, wenn Sie Ihre Cookies löschen;
              <br />
              c) durch Deaktivierung der interessenbezogenen Anzeigen der Anbieter, die Teil der
              Selbstregulierungs-Kampagne "About Ads" sind, über den Link{' '}
              <a href='http://www.aboutads.info/choices' target='_blank' rel='noreferrer'>
                http://www.aboutads.info/choices
              </a>
              , wobei diese Einstellung gelöscht wird, wenn Sie Ihre Cookies löschen;
              <br />
              d) durch dauerhafte Deaktivierung in Ihren Browsern Firefox, Internet Explorer oder Google Chrome unter
              dem Link{' '}
              <a href='https://www.google.com/settings/ads/plugin' target='_blank' rel='noreferrer'>
                https://www.google.com/settings/ads/plugin
              </a>
              . Wir weisen Sie darauf hin, dass Sie in diesem Fall gegebenenfalls nicht alle Funktionen dieses Angebots
              vollumfänglich nutzen können.
            </p>
            <p>
              Weitere Informationen zum Datenschutz bei Google finden Sie hier:{' '}
              <a href='https://www.google.com/intl/de/policies/privacy' target='_blank' rel='noreferrer'>
                https://www.google.com/intl/de/policies/privacy
              </a>
              . Alternativ können Sie die Webseite der Network Advertising Initiative (NAI) unter{' '}
              <a href='http://www.networkadvertising.org' target='_blank' rel='noreferrer'>
                http://www.networkadvertising.org
              </a>{' '}
              besuchen. Google hat sich dem EU-US Privacy Shield unterworfen,{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              .
            </p>
            <h3>Google Remarketing</h3>
            <p>
              Unsere Websites nutzen den Online-Werbedienst Google Remarketing. Hierbei handelt es sich um ein
              Verfahren, mit dem wir Sie erneut ansprechen möchten. Durch diese Anwendung können Ihnen nach Besuch
              unserer Website bei Ihrer weiteren Internetnutzung unsere Werbeanzeigen eingeblendet werden. Dies erfolgt
              mittels in Ihrem Browser gespeicherter Cookies, über die Ihr Nutzungsverhalten bei Besuch verschiedener
              Websites durch Google erfasst und ausgewertet wird. So kann von Google Ihr vorheriger Besuch unserer
              Website festgestellt werden. Eine Zusammenführung der im Rahmen des Remarketings erhobenen Daten mit Ihren
              personenbezogenen Daten, die ggf. von Google gespeichert werden, findet durch Google laut eigenen Aussagen
              nicht statt. Insbesondere wird laut Google beim Remarketing eine Pseudonymisierung eingesetzt.
            </p>
            <p>
              Wenn Sie Google zugestimmt haben, dass ihr Browserverlauf mit ihrem Google-Konto verknüpft wird und
              Informationen aus ihrem Google-Konto von Google zum Personalisieren von Werbeanzeigen verwendet werden,
              kann Google diese Daten außerdem zusammen mit Google Analytics-Daten verwenden und dadurch
              Zielgruppenlisten für geräteübergreifendes Marketing erstellt werden. Sollten Sie mit dieser Art der
              Datenerhebung durch Google nicht einverstanden sein, können Sie diese Technologie unter{' '}
              <a href='https://www.google.de/settings/ads' target='_blank' rel='noreferrer'>
                https://www.google.de/settings/ads
              </a>{' '}
              oder auf der Webseite der Network Advertising Initiative (NAI) unter{' '}
              <a href='http://www.networkadvertising.org' target='_blank' rel='noreferrer'>
                http://www.networkadvertising.org
              </a>{' '}
              deaktivieren.
            </p>
            <h3>Revive AdServer</h3>
            <p>
              Unsere Websites und Apps verwenden die "Ad Server" Software Revive zur Einbindung und anonymisierten
              statistischen Auswertung von Werbeanzeigen (Banner). Persönliche Daten werden über Revive weder erfasst,
              noch verarbeitet oder gespeichert. Rechtsgrundlage für die Nutzung des Revive AdServers ist Art.&nbsp;6
              Abs.&nbsp;1 S.&nbsp;1 lit. f DS-GVO.
            </p>
            <p>
              Um die Anzeige der Werbeanzeigen zu steuern und zu messen, setzt Revive ein Cookie auf Ihren Computer,
              sofern Sie Cookies in Ihrem Browser aktiviert haben. Sie können das Setzen von Cookies in Ihren
              Browsereinstellungen oder mithilfe eines Werbeblockers deaktivieren, was allerdings zur dauerhaft
              eingeschränkten Nutzungsmöglichkeit dieser und anderer Webseiten führen kann.
            </p>
            <h2>Verwendung weiterer Online-Dienste</h2>
            <h3>Google Maps</h3>
            <p>
              Unsere Websites nutzen den geographischen Kartendienst Google Maps. Dadurch können wir Ihnen interaktive
              Karten direkt in der Website anzeigen und ermöglichen Ihnen die komfortable Nutzung der Karten-Funktion.
            </p>
            <p>
              Durch den Besuch auf der Website erhält Google die Information, dass Sie die entsprechende Unterseite
              unserer Website aufgerufen haben. Zudem werden folgende Daten übermittelt:
            </p>
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Anfrage</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
              <li>Inhalt der Anforderung (konkrete Seite)</li>
              <li>Zugriffsstatus/HTTP-Statuscode</li>
              <li>jeweils übertragene Datenmenge</li>
              <li>Verweisende Website (Referrer)</li>
              <li>Sprache und Version der Browsersoftware</li>
              <li>Betriebssystem und dessen Oberfläche</li>
            </ul>
            <p>
              Dies erfolgt unabhängig davon, ob Google ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder
              ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto
              zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei Google nicht wünschen, müssen Sie sich vor
              Aktivierung des Buttons ausloggen. Google speichert Ihre Daten als Nutzungsprofile und nutzt sie für
              Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
              Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter
              Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
              informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich
              zur Ausübung dessen an Google richten müssen.
            </p>
            <p>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch den
              Plug-in-Anbieter erhalten Sie in den Datenschutzerklärungen des Anbieters. Dort erhalten Sie auch weitere
              Informationen zu Ihren diesbezüglichen Rechten und Einstellungsmöglichkeiten zum Schutze Ihrer
              Privatsphäre:{' '}
              <a href='https://policies.google.com/privacy?hl=de' target='_blank' rel='noreferrer'>
                https://policies.google.com/privacy?hl=de
              </a>
              . Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US Privacy Shield
              unterworfen:{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              .
            </p>
            <h3>YouTube Videos</h3>
            <p>
              Unsere Websites nutzen den Online-Dienst YouTube zur Darstellung von Videos, die auf{' '}
              <a href='https://www.youtube.com' target='_blank' rel='noreferrer'>
                https://www.youtube.com
              </a>{' '}
              gespeichert sind und von unserer Website aus direkt abspielbar sind. Diese sind alle im "
              <strong>erweiterten Datenschutz-Modus</strong>" eingebunden, d. h. dass keine Daten über Sie als Nutzer an
              YouTube übertragen werden, wenn Sie die Videos nicht abspielen. Erst wenn Sie die Videos abspielen, werden
              die nachfolgend genannten Daten übertragen. Auf diese Datenübertragung haben wir keinen Einfluss.
            </p>
            <p>
              Durch den Besuch auf der Website erhält YouTube die Information, dass Sie die entsprechende Unterseite
              unserer Website aufgerufen haben. Zudem werden folgende Daten übermittelt:
            </p>
            <ul>
              <li>IP-Adresse</li>
              <li>Datum und Uhrzeit der Anfrage</li>
              <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
              <li>Inhalt der Anforderung (konkrete Seite)</li>
              <li>Zugriffsstatus/HTTP-Statuscode</li>
              <li>jeweils übertragene Datenmenge</li>
              <li>Verweisende Website (Referrer)</li>
              <li>Sprache und Version der Browsersoftware</li>
              <li>Betriebssystem und dessen Oberfläche</li>
            </ul>
            <p>
              Dies erfolgt unabhängig davon, ob YouTube ein Nutzerkonto bereitstellt, über das Sie eingeloggt sind, oder
              ob kein Nutzerkonto besteht. Wenn Sie bei Google eingeloggt sind, werden Ihre Daten direkt Ihrem Konto
              zugeordnet. Wenn Sie die Zuordnung mit Ihrem Profil bei YouTube nicht wünschen, müssen Sie sich vor
              Aktivierung des Buttons ausloggen. YouTube speichert Ihre Daten als Nutzungsprofile und nutzt sie für
              Zwecke der Werbung, Marktforschung und/oder bedarfsgerechten Gestaltung seiner Website. Eine solche
              Auswertung erfolgt insbesondere (selbst für nicht eingeloggte Nutzer) zur Erbringung von bedarfsgerechter
              Werbung und um andere Nutzer des sozialen Netzwerks über Ihre Aktivitäten auf unserer Website zu
              informieren. Ihnen steht ein Widerspruchsrecht zu gegen die Bildung dieser Nutzerprofile, wobei Sie sich
              zur Ausübung dessen an YouTube richten müssen.
            </p>
            <p>
              Weitere Informationen zu Zweck und Umfang der Datenerhebung und ihrer Verarbeitung durch YouTube erhalten
              Sie in der Datenschutzerklärung. Dort erhalten Sie auch weitere Informationen zu Ihren Rechten und
              Einstellungsmöglichkeiten zum Schutze Ihrer Privatsphäre:{' '}
              <a href='https://www.google.com/intl/de/policies/privacy' target='_blank' rel='noreferrer'>
                https://www.google.com/intl/de/policies/privacy
              </a>
              . Google verarbeitet Ihre personenbezogenen Daten auch in den USA und hat sich dem EU-US-Privacy-Shield
              unterworfen:{' '}
              <a href='https://www.privacyshield.gov/EU-US-Framework' target='_blank' rel='noreferrer'>
                https://www.privacyshield.gov/EU-US-Framework
              </a>
              .
            </p>
          </div>

          <div style={{ display: 'flex', margin: '1.5rem -0.5rem -0.5rem -0.5rem' }}>
            <a
              href='https://twitter.com/intent/tweet?text=Impressum&amp;url=https%3a%2f%2fmmi.de%2fimpressum&amp;lang=de'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <TwitterIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fwww.mmi.de%2fdatenschutz'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <FacebookIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='http://www.linkedin.com/shareArticle?url=https%3a%2f%2fmmi.de%2fdatenschutz'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <LinkedInIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.xing.com/spi/shares/new?sc_p=xing-share&url=https%3a%2f%2fmmi.de%2fdatenschutz'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <XingIcon className='icon-button__primary' />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Datenschutz;
