import React, { useEffect, useState } from 'react';
import Columns from './Columns';
import MostPopular from './MostPopular';
import News from './News';
// import Topics from './Topics';
import TopSection from './TopSection';

import { getUser } from '../../context/actions/home';
import { User } from '../types';
import { useHistory } from 'react-router-dom';
import { Modal, Typography } from '@material-ui/core';
import { useAppContext } from '../../context/AppContext';
import { useCookies } from 'react-cookie';

interface Props {}

const Home = (props: Props) => {
  const { isLogged, setIsLogged } = useAppContext();
  const [cookies, setCookie, removeCookie] = useCookies();  
  const [user, setUser] = useState<User>();
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  console.log('user', user);

  useEffect(() => {
    (async () => {
      try{
        const userApi = await getUser();
        console.log('userApi', userApi);
        setUser(userApi?.data);
      } catch (err) {
        setIsLogged(false);
        removeCookie('X-MEDU');
        history.push('/welcome');       
      }
    })();
  }, []);

  if (user) {
    if (
      user.name_profession !== 'Arzt'
      && user.name_profession !== 'Apotheker'
      && user.name_profession !== 'Zahnarzt'
      && user.name_profession !== 'Tierarzt'
    ) {
      setIsLogged(false);
      removeCookie('X-MEDU');
      history.push('/welcome');
    }
  }

  const handleClose = () => {
    setOpen(false);
    history.push('/welcome');
  };

  return (
    <div style={{marginTop: '5rem'}}>
      <TopSection />
      {/* <Topics /> */}
      <MostPopular />
      <Columns />
      <News />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Typography variant="body1" color="initial">
          You don’t have access to this platform.
        </Typography>
      </Modal>
    </div>
  );
};

export default Home;
