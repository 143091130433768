import React, { useEffect, useState } from 'react';
import { Button, Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import Teaser from '../reusable/Teaser';
import { ITeaser } from '../types';
import { Link } from 'react-router-dom';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { range } from '../../context/actions/common';
import { getLatestNews } from '../../context/actions/home';
import { LandingButton, LandingButtonB } from '../landing/Landing';

interface Props {}

const News = (props: Props) => {
  const classes=LandingButton();
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [teasers, setTeasers] = useState<ITeaser[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      getLatestNews()
        .then(async (res) => {
          const { data }: any = await res;
          // Sort teasers by document date in descending order
          data.sort((a: ITeaser, b: ITeaser) => {
            // Parse dates in the format "dd-mm-yyyy" if documentdate is defined
            const dateA = a.documentdate ? new Date(
              a.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            const dateB = b.documentdate ? new Date(
              b.documentdate.split('-').reverse().join('-')
            ).getTime() : 0; // Use 0 if date is undefined
            
            // Compare the dates
            return dateB - dateA; // Sort in descending order
          });
          setTeasers(data);
          setIsLoading(false);
        })
        .catch(() => {
          setTeasers([]);
          setIsLoading(false);
        });
    }, 2500);
  }, []);

  return (
    <div className='section__light'>
      <Container maxWidth='lg' style={{marginBottom: '6rem'}}>
        <div className='section_title-wrapper'>
          <h3
            className='section_title section_title_new_large'
            style={{color: `${theme.palette.primary.main}`}}
          >
            Nachricht
          </h3>
        </div>
        <Divider style={{height: '4px', marginBottom: '2.5rem'}}/>
        <Grid container spacing={deviceSm ? 2 : 4}>
          {isLoading
            ? range(6).map((el: number, key: number) => (
                <Grid key={key} item xs={12} md={6}>
                  <TeaserSkeleton />
                </Grid>
              ))
            : teasers.map((teaser: ITeaser, key: number) => (
                <Grid key={teaser.documentid} item xs={12} md={6}>
                  {key === 0 || (key === 1 && !deviceSm) ? null : (
                    <Divider style={{ margin: deviceSm ? '0 0 16px 0' : '0 0 32px 0' }} />
                  )}
                  <Teaser teaser={teaser} />
                </Grid>
              ))}
        </Grid>
        <div className='landing_buttons-wrapper' style={{marginTop: '2rem'}}>
        <Link to={{ pathname: '/news/all' }}>
          <LandingButtonB  variant='contained' color='secondary'>
            Alles sehen
          </LandingButtonB>
        </Link>
      </div>
      </Container>
    </div>
  );
};

export default News;
