import axios, { AxiosResponse } from 'axios';
import React, { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import { decrypt } from './actions/decrypt';
import webConfig from '../config/config';
import { IBookmark } from '../components/types';

interface Props {
  children: ReactNode;
}

interface IDecriptedToken {
  medu: string;
  aku: string;
}

const sixMonthsInSeconds = 30 * 6 * 86400;

export const axiosInstance = axios.create({
  baseURL: `${webConfig.baseUrl}`,
});

export const AppContext = createContext<Partial<any>>({});

export const useAppContext = () => useContext(AppContext);

const useQuery = () => {
  const { search } = useLocation();
  if (!!search) {
    return new URLSearchParams(search);
  }
};

const AppContextProvider = ({ children }: Props) => {
  const [isLogged, setIsLogged] = useState<boolean | null>(null);

  const [bookmarks, setBookmarks] = useState<IBookmark[] | null>(null);

  const [podcast, setPodcast] = useState<any>(null);
  const [podcastPaused, setPodcastPaused] = useState<boolean>(true);
  const [podcastDuration, setPodcastDuration] = useState<number>(1);
  const [podcastCurrentTime, setPodcastCurrentTime] = useState<any>(0);
  const [podcastProcessValue, setPodcastProcessValue] = useState<any>(0);

  const history = useHistory();

  const query = useQuery();

  const {pathname} = useLocation();
  
  const [cookies, setCookie, removeCookie] = useCookies();
  const checkLogin = async () => {
    var deepLinkToken = query?.get('mdpl');
    if(!deepLinkToken){
      deepLinkToken = query?.get('AutoLogKey');
    }
    if (!!deepLinkToken) {
      const url = `/autologin/${deepLinkToken}`;
      axiosInstance.put(url).then(
        resp=>{
            if(!!resp.data){
              login(resp.data["medicSession"]);
              setCookie('X-MEDU', resp.data["medicSession"], { path: '/', maxAge: sixMonthsInSeconds });
              setCookie('X-AKU', "-", { path: '/', maxAge: sixMonthsInSeconds });
              history.push(pathname);
            }
        }
      ).catch(err => {
          // Handle Error Here
          console.error(err);
      });        
    }    
  }  
  checkLogin();
  const login = async (sessionKey: string) => {
    // axiosInstance.defaults.headers.common['X-AKU'] = cookies['X-AKU'];
    axiosInstance.defaults.headers.common['SESSIONKEY'] = sessionKey;
    setIsLogged(true);
  };

  const logout = useCallback(() => {
    removeCookie('X-MEDU');
    removeCookie('X-AKU');
    setIsLogged(false);
  }, [removeCookie]);

  const getLogInfo = async () => {
    if (!!cookies['X-AKU'] && !!cookies['X-MEDU']) {
      login(cookies['X-MEDU']);
    } else {
      logout();
    }
  };

  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const response = await err.response;

      if (response.status === 401) {
        logout();
      }

      return Promise.reject(err);
    }
  );

  useEffect(() => {
    const authToken = query?.get('param');
    if (!!authToken) {
      const decryptedObject: IDecriptedToken = decrypt(authToken);
      const { medu, aku } = decryptedObject;

      setCookie('X-MEDU', medu, { path: '/', maxAge: sixMonthsInSeconds });
      setCookie('X-AKU', aku, { path: '/', maxAge: sixMonthsInSeconds });

      login(medu);
      history.push('/');
    /*}else if (!isLogged) {
      const deepLinkToken = query?.get('mdpl');
      if (!!deepLinkToken) {
        const url = `/autologin/${deepLinkToken}`;
        axiosInstance.put(url).then(
          resp=>{
              if(!!resp.data){
                login(resp.data["medicSession"]);
                setCookie('X-MEDU', resp.data["medicSession"], { path: '/', maxAge: sixMonthsInSeconds });
                setCookie('X-AKU', "-", { path: '/', maxAge: sixMonthsInSeconds });
              }
          }
        ).catch(err => {
            // Handle Error Here
            console.error(err);
        });        
      }else{
        getLogInfo();
      }  */
    } else {
      getLogInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  const context = useMemo(
    () => ({
      isLogged,
      setIsLogged,
      login,
      logout,
      podcast,
      setPodcast,
      podcastPaused,
      setPodcastPaused,
      podcastDuration,
      setPodcastDuration,
      podcastCurrentTime,
      setPodcastCurrentTime,
      podcastProcessValue,
      setPodcastProcessValue,
      bookmarks,
      setBookmarks,
    }),
    [
      isLogged,
      logout,
      podcast,
      podcastPaused,
      podcastDuration,
      podcastCurrentTime,
      podcastProcessValue,
      bookmarks,
      setBookmarks,
    ]
  );

  return <AppContext.Provider value={context}>{children}</AppContext.Provider>;
};

export { AppContextProvider };
