import { Button, Typography, withStyles } from '@material-ui/core';
import React from 'react'
import { LoginPopper } from '../reusable/LoginPopper';
import { LandingButton ,LandingButtonB} from './Landing';
import LandingTypographyComponent  from './LandingTypography';

const LandingButtonWhite = withStyles({
  root: {
    borderRadius: '30px',
    fontSize: '1rem',
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    backgroundColor: 'white',
    '& *': {
      fontFamily: 'inherit',
      textTransform: 'initial',
      color: '#00c3ff'
    },
  },
})(Button);

type Props = {
  withText?: boolean;
  isLoginBtnWhite?: boolean;
}

const RegisterNow = (props: Props) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const { withText = false, isLoginBtnWhite = false } = props;

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  const [anchorElRegister, setAnchorElRegister] = React.useState<null | HTMLElement>(null);
  const openRegister = Boolean(anchorEl);
  const idRegister = openRegister ? 'transitions-popper' : undefined;
  const handleClickRegister = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRegister(anchorElRegister ? null : event.currentTarget);
  };

  return (
    <>
      {withText || (
        <>
          <LandingTypographyComponent  variant='h3' color='primary' style={{ marginBottom: '2rem' }}>
            Jetzt hier registrieren
          </LandingTypographyComponent>
  
          <LandingTypographyComponent  variant='body1' color='textPrimary' style={{ marginBottom: '2rem' }}>
            Als Portal für medizinische Fachkreise bietet LeitMed aktuelle Therapieleitlinien, die für
            approbierte Ärzte und Apotheker zugelassen sind. Loggen Sie sich ein oder registrieren Sie sich
            hier:
          </LandingTypographyComponent>
        </>
      )}

      <div className='landing_buttons-wrapper' style={{justifyContent: withText ? 'left' : 'center'}}>
        {isLoginBtnWhite ? (
          <LandingButtonWhite
          variant='contained'
          color='default'
          aria-describedby={id}
          onClick={handleClick}
        >
          Login
        </LandingButtonWhite>
        ) : (
          <LandingButtonB
            variant='contained'
            color='secondary'
            aria-describedby={id}
            onClick={handleClick}
          >
            Login
          </LandingButtonB>
        )}
        <LoginPopper
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
        >
          <LandingTypographyComponent variant="body1" color="initial">
            Sie sind bereits auf der LeitMed, Gelbe Liste oder data4doc als approbierter Arzt oder Apotheker registriert? Dann loggen Sie sich hier ein:
          </LandingTypographyComponent>
          <a href='https://account.leitmed.de/Login'>
            <LandingButtonB
              variant='contained'
              color='secondary'
              style={{ marginTop: '8px' }}
            >
              Weiter zu Login
            </LandingButtonB>
          </a>
        </LoginPopper>

        <LandingButtonB
          variant='contained'
          color='primary'
          aria-describedby={idRegister}
          onClick={handleClickRegister}
        >
          Jetzt registrieren
        </LandingButtonB>
        <LoginPopper
          anchorEl={anchorElRegister}
          setAnchorEl={setAnchorElRegister}
        >
          <LandingTypographyComponent variant="body1" color="initial">
            LeitMed enthält Inhalte, die nur für approbierte Ärzte und Apotheker zugelassen sind.
          </LandingTypographyComponent>
          <a href='https://account.leitmed.de/Registrierung'>
            <LandingButtonB
              variant='contained'
              color='secondary'
              style={{ marginTop: '8px' }}
            >
              Weiter zur Registrierung
            </LandingButtonB>
          </a>
        </LoginPopper>
      </div>
    </>
  )
}

export default RegisterNow;
