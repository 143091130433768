import React, { useEffect, useState, MouseEvent } from 'react';
import { Button, Avatar, Chip, Container, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as ChevronLeftIcon } from '../../media/icons/chevron-left.svg';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '../../media/icons/bookmark-filled.svg';
import { IBookmark, ITeaser } from '../types';
import { Link, useLocation, useParams, useHistory } from 'react-router-dom';
import AudioPlayer from './AudioPlayer';
import VideoPlayer from './VideoPlayer';
import { getArticle, getMedia, getTeaser } from '../../context/actions/article';
import { Skeleton } from '@material-ui/lab';
import { addBookmark, formatDate, humanizeTagTitle, removeBookmark } from '../../context/actions/common';
import YouTubePlayer from './YouTubePlayer';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useAppContext } from '../../context/AppContext';
import { LandingButton, LandingButtonB } from '../landing/Landing';

interface Props {
  video?: boolean;
}

interface IState {
  teaser?: ITeaser;
}

interface IParams {
  id: string;
}

const Media = ({ video }: Props) => {
  const classesB=LandingButton();
  const { state: { teaser } = {} } = useLocation<IState>();
  const { id } = useParams<IParams>();
  const { bookmarks, setBookmarks } = useAppContext();
  const history = useHistory();
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceUpXs = useMediaQuery(theme.breakpoints.up(600));

  const appbar = window.document.querySelector('.toolbar');

  const [headerHeight, setHeaderHeight] = useState(appbar?.clientHeight);


  useEffect(() => {
    setHeaderHeight(appbar?.clientHeight);
  }, [deviceXs, deviceUpXs]);

  const [mediaUrl, setMediaUrl] = useState<string>('');
  const [document, setDocument] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [setInfo] = useCustomSnackbar('info');
  useEffect(() => {
    // Fetch teaser if not available
    //if (!teaser) {
        getArticle(id)
            .then(async (res: any) => {
                const { data, status }: any = await res;
                if (status === 200) {
                    console.log('Teaser fetched successfully');
                    setDocument(data);
                }
            })
            .catch((err) => {
                console.log('Error fetching teaser: ', err);
            });
    //}else{
    //  setDocument(teaser);
    //}
}, [id, teaser]);

useEffect(() => {
    if (document) {
      console.log(document.mediatype);
        if (document?.mediatype?.toLowerCase() === 'url') {
            setMediaUrl(document?.teaserurl);
            setIsLoading(false);
        } else {
            // Fetch media if not available
            if (!mediaUrl) {
                getMedia(id)
                    .then((res: any) => res?.data)
                    .then((url: string) => {
                        console.log('Media fetched successfully');
                        setMediaUrl(url);
                        setIsLoading(false);
                    })
                    .catch((err) => {
                        console.log('Error fetching media: ', err);
                        setMediaUrl('');
                        setIsLoading(false);
                    });
            } else {
                setIsLoading(false); // No need to fetch, set loading to false
            }
        }
    }
}, [id, document, mediaUrl]);
  /*useEffect(() => {
    if (teaser) {
      setDocument(teaser);
    } else {
      getTeaser(id)
      .then(async (res: any) => {
        const { data, status }: any = await res;
          if ( status === 200) {
            console.log('200 OK');
            setDocument(data);            
          }
      })
      .catch((err) => {
        console.log('Oops: ', err);
        setMediaUrl('');
      });     
    }
    if (document) {
      if (document?.mediatype?.toLowerCase() === 'url') {
        setMediaUrl(document?.teaserurl);
        setIsLoading(false);
      } else {
        getMedia(id)
          .then((res: any) => res?.data)
          .then((url: string) => {
            console.log('Media OKKK2');
            setMediaUrl(url);
            setIsLoading(false);
          })
          .catch((err) => {
            console.log('Oops: ', err);
            setMediaUrl('');
            setIsLoading(false);
          });
      }
    } 

  }, [id,document, teaser]);*/

  const handleBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>, objecttype: string) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !!bookmarks
        ? bookmarks?.find(
            (el: IBookmark) =>
              el.objectid === document?.documentid ||
              el.objectid === document?.documenid ||
              el.objectid === document?.teaserid ||
              el.objectid === document?.objectid
          )
        : !!document?.bookmark
    ) {
      removeBookmark(
        objecttype,
        document?.documentid || document?.documenid || document?.teaserid || document?.objectid || ''
      )
        .then(() => {
          setDocument({ ...document, bookmark: 0 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks.filter(
                (el: IBookmark) =>
                  el.objectid !== document?.documentid &&
                  el.objectid !== document?.documenid &&
                  el.objectid !== document?.teaserid &&
                  el.objectid !== document?.objectid
              ),
            ]);
          }
          setInfo('Aus Lesezeichen entfernt');
        })
        .catch((err) => console.log('Oops: ', err));
    } else {
      addBookmark(
        objecttype,
        document?.documentid || document?.documenid || document?.teaserid || document?.objectid || ''
      )
        .then(() => {
          setDocument({ ...document, bookmark: 1 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks,
              {
                objectid: document.documentid || document.documenid || document.teaserid || document.objectid,
                objecttype,
                title: document.title || document.teasertitle,
              },
            ]);
          }
          setInfo('Zu Lesezeichen hinzugefügt');
        })
        .catch((err) => console.log('Oops: ', err));
    }
  };
  const handleBackToOverview = () => {
    history.goBack();
  }
  return (
    <div style={{marginTop: `${headerHeight} + 20 + 'px'`}}>
      <Container maxWidth='md'>
        <div className='breadcrumbs-wrapper' style={{justifyContent: 'center'}}>
          <Link to='/' className='breadcrumb-button'>
            Startseite<ChevronLeftIcon style={{ transform: 'rotate(180deg)', margin: '0 0.5rem' }} />
          </Link>
          {(isLoading || (document?.contenthubs && document?.contenthubs[0]?.hubid)) && (
            <Link
              to={!isLoading && !!document?.contenthubs ? { pathname: `/hub/${document?.contenthubs[0]?.hubid}` } : {}}
              className='breadcrumb-button'
            >
              <ChevronLeftIcon />{' '}
              {isLoading ? (
                <Skeleton width={100} height='2rem' style={{ display: 'inline-block' }} />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      document?.contenthubs[0]?.displayname ||
                      (document?.contenthubs[0]?.name ? humanizeTagTitle(document?.contenthubs[0]?.name) : 'Hub'),
                  }}
                ></span>
              )}
            </Link>
          )}
        </div>

        {isLoading ? (
          <Skeleton width={320} height='5rem' style={{ marginBottom: '1.75rem' }} />
        ) : (
          <h2
            className='article_title'
            dangerouslySetInnerHTML={{ __html: document?.title || document?.teasertitle || '' }}
          ></h2>
        )}

        {isLoading ? (
          <Skeleton width={300} height='2rem' style={{ margin: '1rem 0' }} />
        ) : (
          (!!document?.source || !!document?.documenttype) && (
            <div className='article_label-wrapper'>
              <span className='article_label'>
                {document?.source}{' '}
                {!!document?.documenttype && !!document?.source && <span className='article_text-secondary'>in</span>}{' '}
                {document?.documenttype}
              </span>
            </div>
          )
        )}

        <div className='article_info-wrapper'>
          {isLoading ? (
            <Skeleton width={280} height='2rem' />
          ) : (
            <span className='article_text-secondary'>
              {!!document?.documentdate && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: formatDate(document?.documentdate),
                  }}
                ></span>
              )}{' '}
              <span
                className='article_button'
                onClick={(e) =>
                  handleBookmark(
                    e,
                    document?.mediatype?.toLowerCase() === 'video'
                      ? 'VIDEO'
                      : document?.mediatype?.toLowerCase() === 'url'
                      ? 'URL'
                      : document?.mediatype?.toLowerCase() === 'audio'
                      ? 'AUDIO'
                      : 'DOCUMENT'
                  )
                }
              >
                {(formatDate(document?.documentdate) || typeof document?.readingtime === 'number') && (
                  <span style={{ color: 'rgba(44, 54, 79, 0.5)' }}>&middot; </span>
                )}{' '}
                {(
                  !!bookmarks
                    ? bookmarks?.find(
                        (el: IBookmark) =>
                          el.objectid === document?.documentid ||
                          el.objectid === document?.documenid ||
                          el.objectid === document?.teaserid ||
                          el.objectid === document?.objectid
                      )
                    : !!document?.bookmark
                ) ? (
                  <>
                    <BookmarkFilledIcon /> Lesezeichen entfernen
                  </>
                ) : (
                  <>
                    <BookmarkIcon /> Zu Lesezeichen hinzufügen
                  </>
                )}
              </span>
            </span>
          )}
        </div>

        {isLoading ? (
          <Skeleton width={310} height='2rem' style={{ marginBottom: '1.5rem' }} />
        ) : (
          !!document?.tags && (
            <div style={{ margin: '0 0 1.5rem 0' }}>
              {document?.tags?.map((tag: string, key: number) => (
                <Chip
                  key={key}
                  color='primary'
                  label={humanizeTagTitle(tag)}
                  size='small'
                  style={{ marginRight: '0.5rem' }}
                />
              ))}
            </div>
          )
        )}

        {(document?.mediatype?.toLowerCase() === 'video' || document?.mediatype?.toLowerCase() === 'url') &&
          (isLoading ? (
            <Skeleton variant='rect' width='100%' height={500} style={{ marginBottom: '2rem' }} />
          ) : (
            mediaUrl &&
            (document?.mediatype?.toLowerCase() === 'url' ? (
              <YouTubePlayer title={document?.title} src={mediaUrl} />
            ) : (
              (() => {
                const posterUrl = document?.teaserimage ? document?.teaserimage : document?.image;
                return <VideoPlayer src={mediaUrl} poster={posterUrl} />;
              })()
            ))
          ))}

        {document?.mediatype?.toLowerCase() === 'audio' &&
          (isLoading ? (
            <Skeleton variant='rect' width='100%' height={50} style={{ marginBottom: '2rem' }} />
          ) : (
            <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginBottom: '2rem' }}>
              {document?.avatar && (
                <Avatar src={document?.avatar} style={{ width: '4rem', height: '4rem', marginRight: '0.5rem' }} />
              )}
              {mediaUrl && <AudioPlayer doc={document} src={mediaUrl} />}
            </div>
          ))}

        {!isLoading && (
          <div
            className='content-wrapper'
            dangerouslySetInnerHTML={{ __html: `${document?.content || document?.teaserdesc || ''}` }}
          />
        )}
        <div className='landing_buttons-wrapper-std'>
          <LandingButtonB  variant='contained' color='secondary' onClick={handleBackToOverview}>
            Zur&uuml;ck zur &Uuml;bersicht
          </LandingButtonB>
        </div>  
      </Container>
    </div>
  );
};

export default Media;
