import React, { useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { animateScroll } from 'react-scroll';

function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      if (history.location.hash[0] !== '#') {
        animateScroll.scrollToTop({
          duration: 700,
          delay: 0,
          smooth: true,
        });
      }
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
