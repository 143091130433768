import React, { MouseEvent, useState } from 'react';
import { Card, CardActionArea, CardMedia, Tooltip, Typography } from '@material-ui/core';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '../../media/icons/bookmark-filled.svg';
import { IBookmark, IHub } from '../types';
import { Link } from 'react-router-dom';
import { addBookmark, removeBookmark } from '../../context/actions/common';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useAppContext } from '../../context/AppContext';

interface Props {
  large?: boolean;
  hub: any;
}

const HubCard = ({ hub, large }: Props) => {
  const [hubData, setHubData] = useState<IHub>(() => hub);

  const { bookmarks, setBookmarks } = useAppContext();

  const [setInfo] = useCustomSnackbar('info');

  const handleBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !!bookmarks
        ? bookmarks?.find((el: IBookmark) => el.objectid === hubData?.hubid || el.objectid === hubData?.objectid)
        : !!hubData?.bookmark
    ) {
      removeBookmark('CONTENTHUB', hubData?.hubid || hubData?.objectid || '')
        .then(() => {
          setHubData({ ...hubData, bookmark: 0 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks.filter(
                (el: IBookmark) => el.objectid !== hubData?.hubid && el.objectid !== hubData?.objectid
              ),
            ]);
          }
          setInfo('Aus Lesezeichen entfernt');
        })
        .catch((err) => console.log('Oops: ', err));
    } else {
      addBookmark('CONTENTHUB', hubData?.hubid || hubData?.objectid || '')
        .then(() => {
          setHubData({ ...hubData, bookmark: 1 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks,
              {
                objectid: hubData.hubid || hubData.objectid,
                objecttype: 'CONTENTHUB',
                title: hubData.displayname || hubData.name,
              },
            ]);
          }
          setInfo('Zu Lesezeichen hinzugefügt');
        })
        .catch((err) => console.log('Oops: ', err));
    }
  };

  return (
    <Link to={`/hub/${hubData?.hubid || hubData?.objectid}`}>
      <Card
        elevation={0}
        style={{
          width: '100%',
          height: !large ? '12rem' : '28rem',
          position: 'relative',
        }}
      >
        <CardActionArea style={{ height: '100%' }}>
          {hubData?.teaserimage && (
            <CardMedia
              component='img'
              alt=''
              height='100%'
              src={hubData?.teaserimage ? `data:image/png;base64, ${hubData.teaserimage}` : undefined}
            />
          )}
          <div className='card_gradient'></div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              position: 'absolute',
              top: large ? '1rem' : '.5rem',
              right: large ? '1rem' : '.5rem',
            }}
          >
            {(
              !!bookmarks
                ? bookmarks?.find(
                    (el: IBookmark) => el.objectid === hubData?.hubid || el.objectid === hubData?.objectid
                  )
                : !!hubData?.bookmark
            ) ? (
              <BookmarkFilledIcon className='icon-button__light' onClick={handleBookmark} />
            ) : (
              <BookmarkIcon className='icon-button__light' onClick={handleBookmark} />
            )}
          </div>

          <div
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              overflow: 'hidden',
              padding: large ? '1rem' : '.5rem',
              paddingBottom: large ? '1.6rem' : '.5rem',
            }}
          >
            {/* TODO: Add tooltips to all noWrap elements */}
            <Tooltip
              title={
                <>
                  <Typography
                    variant='body2'
                    color='inherit'
                    dangerouslySetInnerHTML={{
                      __html: `${hubData?.displayname || hubData?.teasertitle || hubData?.name || ''}`,
                    }}
                  />
                </>
              }
            >
              <Typography
                variant='body1'
                noWrap
                style={{
                  fontSize: large ? '2rem' : '1rem',
                  lineHeight: large ? '3rem' : '1.5rem',
                  color: '#fff',
                  fontFamily: 'Noto Serif SC',
                  fontWeight: 500,
                  // fontWeight: 'bold',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                }}
                dangerouslySetInnerHTML={{
                  __html: `${hubData?.displayname || hubData?.teasertitle || hubData?.name || ''}`,
                }}
              />
            </Tooltip>
          </div>
        </CardActionArea>
      </Card>
    </Link>
  );
};

export default HubCard;
