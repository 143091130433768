import React from 'react';

interface Props {
  title?: string;
  src?: string;
}

const YouTubePlayer = ({ title, src }: Props) => {
  let checkedSrc = src;
  checkedSrc = checkedSrc?.startsWith('www') ? 'https://' + checkedSrc : checkedSrc;

  return (
    <div className='youtube-container'>
      <iframe title={title} frameBorder='0' src={checkedSrc} allowFullScreen className='youtube-iframe'></iframe>
    </div>
  );
};

export default YouTubePlayer;
