import { Container } from '@material-ui/core';
import React from 'react';
import anzeigen from '../../docs/vidal-mmi-agb-verkauf-anzeigen-april-2021.pdf';
import medien from '../../docs/vidal-mmi-agb-verkauf-medien-april-2021.pdf';
import verbraucherinformationen from '../../docs/verbraucherinformationen-und-widerrufsbelehrung-april-2021.pdf';
import zertifikat from '../../docs/mmi-iso-9001-zertifikat.pdf';
import { Link } from 'react-router-dom';
import { ReactComponent as FacebookIcon } from '../../media/icons/facebook-logo.svg';
import { ReactComponent as TwitterIcon } from '../../media/icons/twitter.svg';

interface Props {}

const AGB = (props: Props) => {
  return (
    <div style={{ padding: '3rem 0' }}>
      <Container maxWidth='lg'>
        <h1 className='article_title'>Allgemeine Geschäftsbedingungen (AGB)</h1>

        <div className='content-wrapper'>
          <p>
            <b>
              Hier finden Sie die aktuellen Allgemeinen Geschäftsbedingungen (AGB) der Vidal MMI Germany GmbH,
              Monzastraße 4, 63225 Langen zum Download:
            </b>
          </p>

          <p>
            <a href={anzeigen} target='_blank' rel='noopener noreferrer'>
              Allgemeine Geschäftsbedingungen für Anzeigenkunden
            </a>
          </p>

          <p>
            <a href={medien} target='_blank' rel='noopener noreferrer'>
              Allgemeine Geschäftsbedingungen für den Verkauf von Medien
            </a>
          </p>

          <p>
            <a href={verbraucherinformationen} target='_blank' rel='noopener noreferrer'>
              Verbraucherinformationen und Widerrufsbelehrung
            </a>
          </p>

          <p>
            <a href={zertifikat} target='_blank' rel='noopener noreferrer'>
              MMI ISO 9001 Zertifikat
            </a>
          </p>

          <h2>Rechtliche Informationen</h2>

          <ul>
            <li>
              <Link to='/datenschutz'>Datenschutzerklärung</Link>
            </li>
            <li>
              <Link to='/nutzungsbedingungen'>Nutzungsbedingungen</Link>
            </li>
            <li>
              <Link to='/impressum'>Impressum</Link>
            </li>
          </ul>

          <h2>Mediadaten für Online-Werbung</h2>

          <ul>
            <li>
              <a href='https://www.gelbe-liste.de/mediadaten' target='_blank' rel='noreferrer'>
                Mediadaten Gelbe Liste Online und Gelbe Liste App
              </a>
            </li>
            <li>
              <a href='https://www.meine-gesundheit.de/mediadaten' target='_blank' rel='noreferrer'>
                Mediadaten Meine Gesundheit
              </a>
            </li>
          </ul>

          <p style={{ fontSize: '1rem' }}>
            Autor: Vidal MMI Germany GmbH
            <br />
            Stand: 01.04.2021
          </p>

          <div style={{ display: 'flex', margin: '1.5rem -0.5rem -0.5rem -0.5rem' }}>
            <a
              href='https://twitter.com/intent/tweet?url=https%3a%2f%2fwww.mmi.de%2fagb&text=Allgemeine+Gesch%c3%a4ftsbedingungen+%7c+Vidal+MMI&lang=de'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <TwitterIcon className='icon-button__primary' />
              </div>
            </a>

            <a
              href='https://www.facebook.com/sharer/sharer.php?u=https%3a%2f%2fwww.mmi.de%2fagb'
              target='_blank'
              rel='noreferrer'
            >
              <div className='icon-button-wrapper' style={{ margin: '0.5rem' }}>
                <FacebookIcon className='icon-button__primary' />
              </div>
            </a>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AGB;
