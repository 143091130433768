import { axiosInstance } from '../AppContext';
import { AxiosResponse } from 'axios';
import { handler } from './common';

export const getHubs = async () => {
  const url = '/hub/list';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getPins = async () => {
  const url = '/hub/CAR-T-CELLS/documents/full/Einrichtungen';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getPopularHubs = async () => {
  const url = '/hub/popular';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getHub = async (hubId: string) => {
  const url = `/hub/${hubId}`;

  //try {
    const res: AxiosResponse<any> = await axiosInstance.get(url);
    return res;
  /*} catch (err) {
    handler(err);
  }*/
};
export const getGuestTeasers = async () => {
  const url = `/guesthighlight`;

  //try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  /*} catch (err) {
    handler(err);
  }*/
};
export const getHubTabTeasersAmount = async (hubName: string, tabsArr: string) => {
  const url = `/hub/${hubName}/documents/types/count/${encodeURIComponent(tabsArr)}`;

  //try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  /*} catch (err) {
    handler(err);
  }*/
};

export const getHubTabTeasers = async (hubName: string, tabsArr: string) => {
  const url = `/hub/${hubName}/documents/types/${encodeURIComponent(tabsArr)}`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getHubExperts = async (hubName: string) => {
  const url = `/hub/${hubName}/documents/types/%5B%22Podcast%20Therapie%22%2C%22Podcast%20Fachstudie%22%2C%22Podcast%20Diagnostik%22%2C%22Podcast%20Arztinfo%22%5D`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};
