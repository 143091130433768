import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

const useStyles = makeStyles((theme) => ({
  snacks: {
    color: theme.palette.primary.contrastText,
    '& *': {
      color: theme.palette.primary.contrastText,
    },
  },
  success: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '& *': {
      color: theme.palette.primary.contrastText,
    },
  },
  info: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '& *': {
      color: theme.palette.secondary.contrastText,
    },
  },
}));

interface Props {
  children: ReactNode;
}

export default function CustomSnackbarProvider({ children }: Props) {
  const classes = useStyles();

  return (
    <SnackbarProvider
      maxSnack={3}
      hideIconVariant
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      // preventDuplicate
      classes={{
        variantSuccess: classes.success,
        variantError: classes.snacks,
        variantWarning: classes.snacks,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}
