import React, { ChangeEvent, Fragment, useEffect, useState } from 'react';
import {
  Checkbox,
  Container,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { ITeaser } from '../types';

import { ReactComponent as CloseIcon } from '../../media/icons/cross.svg';
import { ReactComponent as CheckedIcon } from '../../media/icons/checked.svg';
import { ReactComponent as CheckboxBlankIcon } from '../../media/icons/checkbox-blank.svg';

import Teaser from '../reusable/Teaser';
import { useParams } from 'react-router-dom';
import { searchByTag, searchByIds } from '../../context/actions/article';
import { range } from '../../context/actions/common';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import CustomPagination from '../reusable/CustomPagination';

const Search = () => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceMd = useMediaQuery(theme.breakpoints.down('md'));

  const params: any = useParams();
  const searchTag = params?.searchTag;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [ids, setIds] = useState<any>([]);
  const [result, setResult] = useState<any>([]);
  const [page, setPage] = useState<number>(1);
  const [filters, setFilters] = useState<any>({ area: [], author: [], mediaType: [] });

  const { area, author, mediaType } = filters;

  const itemsPerPage: number = 10;

  useEffect(() => {
    setIsLoading(true);

    if (!!searchTag) {
      searchByTag(searchTag)
        .then((res: any) => res.data)
        .then((data: any) => setIds(data));
    }
  }, [searchTag]);

  useEffect(() => {
    setIsLoading(true);

    const targetIds = ids
      .filter((idObj: any) =>
        mediaType.length > 0 ? mediaType.find((mediatype: any) => idObj?.mediatype?.toLowerCase() === mediatype) : true
      )
      .slice((page - 1) * itemsPerPage, (page - 1) * itemsPerPage + itemsPerPage)
      .map((el: any) => el.documentid);

    if (targetIds?.length > 0) {
      searchByIds(targetIds)
        .then((res: any) => res.data)
        .then((data: any) => {
          setResult(data);
          setIsLoading(false);
        });
    } else {
      setResult([]);
      setIsLoading(false);
    }
  }, [ids, page, filters, mediaType]);

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    let section = filters[e.target.name];
    const index = section.indexOf(e.target.value);
    if (index > -1) {
      section.splice(index, 1);
      setFilters({ ...filters, [e.target.name]: [...section] });
    } else {
      setFilters({ ...filters, [e.target.name]: [...filters[e.target.name], e.target.value] });
    }
  };

  const handleDeleteFilter = (sectionTitle: string, elementTitle: string) => {
    let section = filters[sectionTitle];
    const index = section.indexOf(elementTitle);
    section.splice(index, 1);
    setFilters({ ...filters, [sectionTitle]: [...section] });
  };

  return (
    <div className='section__pagination'>
      <Fade in={!!searchTag} timeout={500}>
        <Container maxWidth='lg'>
          <div className='section_title-wrapper'>
            <h3 className='section_title'>
              Ergebnisse für {searchTag}:{' '}
              {
                ids.filter((idObj: any) =>
                  mediaType.length > 0
                    ? mediaType.find((mediatype: any) => idObj?.mediatype?.toLowerCase() === mediatype)
                    : true
                ).length
              }
            </h3>
          </div>
          <div style={{ display: 'flex' }}>
            {!deviceXs && (
              <div>
                <div
                  style={{ display: 'flex', flexDirection: 'column', padding: deviceMd ? '0 24px 0 0' : '0 6rem 0 0' }}
                >
                  {/* <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Area
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Covid-19')}
                              onChange={handleChecked}
                              name='area'
                              value='Covid-19'
                            />
                          }
                          label='Covid-19'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Cardiology')}
                              onChange={handleChecked}
                              name='area'
                              value='Cardiology'
                            />
                          }
                          label='Cardiology'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Radiology')}
                              onChange={handleChecked}
                              name='area'
                              value='Radiology'
                            />
                          }
                          label='Radiology'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={area.includes('Therapy')}
                              onChange={handleChecked}
                              name='area'
                              value='Therapy'
                            />
                          }
                          label='Therapy'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', marginBottom: '1.5rem' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Author
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('Bachanova')}
                              onChange={handleChecked}
                              name='author'
                              value='Bachanova'
                            />
                          }
                          label='Bachanova'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('Jane Doe')}
                              onChange={handleChecked}
                              name='author'
                              value='Jane Doe'
                            />
                          }
                          label='Jane Doe'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={author.includes('John Doe')}
                              onChange={handleChecked}
                              name='author'
                              value='John Doe'
                            />
                          }
                          label='John Doe'
                        />
                      </FormGroup>
                    </FormControl>
                  </div> */}
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl component='fieldset'>
                      <FormLabel component='legend' className='filter_section-title'>
                        Medientyp
                      </FormLabel>
                      <FormGroup>
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={mediaType.includes('video')}
                              onChange={handleChecked}
                              name='mediaType'
                              value='video'
                            />
                          }
                          label='Video'
                        />
                        <FormControlLabel
                          className='filter_option-title'
                          control={
                            <Checkbox
                              icon={<CheckboxBlankIcon />}
                              checkedIcon={<CheckedIcon />}
                              checked={mediaType.includes('audio')}
                              onChange={handleChecked}
                              name='mediaType'
                              value='audio'
                            />
                          }
                          label='Audio'
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            )}
            <div style={{ width: '100%' }}>
              <div
                style={{
                  borderLeft: !deviceXs ? '1px solid rgba(225, 228, 233, 0.7)' : 0,
                  padding: deviceXs ? 0 : deviceMd ? '0 0 0 24px' : '0 0 0 6rem',
                  minHeight: 'calc(100vh - 16rem)',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <div>
                  <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {area.length > 0 && (
                      <>
                        <span className='filter_section-label'>Area: </span>
                        {area.map((el: string, key: number) => (
                          <span key={key} className='filter_option-label'>
                            {el}{' '}
                            <CloseIcon
                              className='icon-button__default'
                              onClick={() => handleDeleteFilter('area', el)}
                            />
                          </span>
                        ))}
                      </>
                    )}
                    {author.length > 0 && (
                      <>
                        <span className='filter_section-label'>Author: </span>
                        {author.map((el: string, key: number) => (
                          <span key={key} className='filter_option-label'>
                            {el}{' '}
                            <CloseIcon
                              className='icon-button__default'
                              onClick={() => handleDeleteFilter('author', el)}
                            />
                          </span>
                        ))}
                      </>
                    )}
                    {mediaType.length > 0 && (
                      <>
                        <span className='filter_section-label'>Medientyp: </span>
                        {mediaType.map((el: string, key: number) => (
                          <span key={key} className='filter_option-label'>
                            {el[0].toUpperCase().concat(el.slice(1))}{' '}
                            <CloseIcon
                              className='icon-button__default'
                              onClick={() => handleDeleteFilter('mediaType', el)}
                            />
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                  {isLoading ? (
                    range(itemsPerPage).map((el: number, key: number) => (
                      <Fragment key={key}>
                        {key !== 0 && (
                          <Divider style={{ margin: deviceXs ? '16px 0' : deviceSm ? '24px 0' : '32px 0' }} />
                        )}
                        <TeaserSkeleton />
                      </Fragment>
                    ))
                  ) : result.length > 0 ? (
                    <div>
                      {result.map((teaser: ITeaser, key: number) => (
                        <Fragment key={key}>
                          {key !== 0 && (
                            <Divider style={{ margin: deviceXs ? '16px 0' : deviceSm ? '24px 0' : '32px 0' }} />
                          )}
                          <Teaser teaser={teaser} />
                        </Fragment>
                      ))}
                    </div>
                  ) : (
                    <span className='no-results-msg'>
                      Keine Ergebnisse für <b>{searchTag}</b>
                      {mediaType.length > 0 && ' mit aktivierten aktuellen Filtern'}
                    </span>
                  )}
                </div>
                <CustomPagination
                  count={ids.length > 0 ? Math.ceil(ids.length / itemsPerPage) : 1}
                  page={page}
                  setPage={setPage}
                />
              </div>
            </div>
          </div>
        </Container>
      </Fade>
    </div>
  );
};

export default Search;
