import React from 'react';
import './App.css';
import { createMuiTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import AuthRouter from './routers/AuthRouter';
import CustomSnackbarProvider from './context/CustomSnackbarProvider';

const theme = createMuiTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#a32f88',
      contrastText: '#fff',
    },
    secondary: {
      main: '#00c3ff',
      contrastText: '#fff',
    },
    text: {
      primary: '#2c364f',
    },
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
      },
      colorDefault: {
        color: '#2c364f',
        backgroundColor: 'rgba(44, 54, 79, 0.1)',
      },
    },
    MuiToolbar: {
      root: {
        justifyContent: 'space-between',
      },
    },
    MuiButton: {
      containedPrimary: {
        color: '#fff',
      },
      label: {
        color: 'inherit',
        textTransform: 'capitalize',
      },
    },
    MuiOutlinedInput: {
      root: {
        backgroundColor: '#fff',
        borderRadius: 5,
      },
      notchedOutline: {
        borderColor: '#D1D8E1',
        '&:hover': {
          borderColor: '#2C364F',
        },
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: '0px 2px 4px rgba(44, 54, 79, 0.1)',
      },
    },
    MuiChip: {
      sizeSmall: {
        height: '1.125rem',
      },
      labelSmall: {
        fontSize: '0.625rem',
        lineHeight: '1.125rem',
      },
      root: {
        height: '2.125rem',
      },
      colorPrimary: {
        '& > *': {
          color: '#fff',
        },
      },
      deletableColorPrimary: {
        '&:focus': {
          backgroundColor: '#a32f88',
        },
      },
      label: {
        fontSize: '0.875rem',
        lineHeight: '2.125rem',
      },
      outlined: {
        margin: '0 -1px',
        borderColor: '#E1E4E9',
        backgroundColor: 'rgba(225, 228, 233, 0.3)',
      },
    },
    MuiTabs: {
      indicator: {
        height: '1px',
        backgroundColor: '#2C364F',
        minWidth: '3px',
      },
    },
    MuiTab: {
      root: {
        minWidth: '0px !important',
        fontSize: '0.8rem',
        lineHeight: 1,
        overflow: 'visible',
      },
      textColorInherit: {
        opacity: 0.5,
        '&.Mui-disabled': {
          opacity: 0.2,
        },
      },
      wrapper: {
        alignItems: 'flex-start',
      },
    },
    MuiInputAdornment: {
      root: {
        minHeight: '100%',
        height: '100%',
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <CustomSnackbarProvider>
        <AuthRouter />
      </CustomSnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
