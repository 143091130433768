import React, { useEffect, useMemo, useRef, useState, Fragment } from 'react';
import * as d3 from 'd3';
import './map.css';
import useRect from '../../../hooks/useRect';
import { Container, Tooltip, withStyles, Divider, useMediaQuery, useTheme } from '@material-ui/core';
import Teaser from '../../reusable/Teaser';
import MapTeaser from './MapTeaser';
import RichTooltip from '../../reusable/RichTooltip';
import _ from 'lodash';

import { getPins } from '../../../context/actions/hubs';
import plzdata from '../../../plzdata.json';
import docHeaderPic from '../../../media/landing/landing-vidal.jpg';

const CustomTooltip = withStyles({
  tooltip: {
    zIndex: 1,
    background: '#fff',
    padding: '1rem',
    width: '30rem',
    maxWidth: '95vw',
    boxShadow: '0px 2px 4px rgb(44 54 79 / 10%)',
  },
  arrow: {
    color: '#fff',
  },
})(Tooltip);

const Map = () => {
  const containerRef = useRef(null);
  const gTagRef = useRef(null);
  const gSelectionRef = useRef(null);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [geoPath, setGeoPath] = useState(null);
  const [projection, setProjection] = useState(null);
  const [focused, setFocused] = useState(null);
  const [hovered, setHovered] = useState(null);
  const [regions, setRegions] = useState([]);
  const [pins, setPins] = useState([]);
  const [openTooltip, setOpenTooltip] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
console.log('pins', pins);

  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceUpXs = useMediaQuery(theme.breakpoints.up(600));

  const appbar = window.document.querySelector('.toolbar');

  const [headerHeight, setHeaderHeight] = useState(appbar?.clientHeight);

  useEffect(() => {
    setHeaderHeight(appbar?.clientHeight);
  }, [deviceXs, deviceUpXs, appbar?.clientHeight]);

  useEffect(() => {
    const getAllPins = async () => {
      await getPins().then((res) => {
        const data = res?.data;

        let pinsApi = null;

        if (data) {
          pinsApi = data.map(pinApi => {
            const pin = {};
            // console.log('pinApi', pinApi);

            pin.teasertitle = pinApi.title;
            pin.content = pinApi.content;

            const div = document.createElement('div');
            div.innerHTML = pinApi.content;
            const stringWithZIP = div.children[2].innerText;

            //const zipCode = stringWithZIP.match(/((?<!\d)\d{5}(?!\d))/g).toString();
            const zipCode = stringWithZIP.match(/\s\d{5}\s/g).toString().replace(/\s/g, "");

            if (!plzdata[zipCode]) {
              pin.location = {
                latitude: 0,
                longitude: 0,
              }
            } else {
              pin.location = {
                latitude: plzdata[zipCode].split(', ')[1],
                longitude: plzdata[zipCode].split(', ')[0],
              }
            }

            return pin;
          });
        }

        const uniquePins = _.uniqWith(pinsApi, function(arrVal, othVal) {
          return arrVal.location.latitude === othVal.location.latitude && arrVal.location.longitude === othVal.location.longitude;
        });

        const distributedPins = [];

        uniquePins.forEach(item => {
          distributedPins.push(pinsApi.filter(pin => pin.location.longitude === item.location.longitude && pin.location.latitude === item.location.latitude));
        })

        setPins(distributedPins);
      });
    };

    getAllPins();
  }, []);

  const svgDimensions = useRect(containerRef);

  useMemo(() => {
    setWidth(svgDimensions.width);
    setHeight(svgDimensions.height);
  }, [svgDimensions.height, svgDimensions.width]);

  const clickPath = (d) => {
    let x = width / 2;
    let y = height / 2;
    let k = 1;

    if (focused === null || !(focused === d)) {
      const centroid = geoPath.centroid(d);
      x = +centroid[0];
      y = +centroid[1];
      k = 1.75;

      setFocused(d);
    } else {
      setFocused(null);
    }

    gSelectionRef.current
      .transition()
      .duration(1000)
      .attr(
        'transform',
        'translate(' + width / 2 + ',' + height / 2 + ')scale(' + k + ')translate(' + -x + ',' + -y + ')'
      )
      .style('stroke-width', 1.75 / k + 'px');
  };

  const mouseEnterPath = (d) => {
    if (
      hovered === null ||
      !(hovered === d)
      // && (focused === null || !(focused === d))
    ) {
      setHovered(d);
    }
  };

  const mouseLeavePath = () => {
    setHovered(null);
    gSelectionRef.current.selectAll('text').remove();
  };

  useEffect(() => {
    if (!!width && !!height) {
      gSelectionRef.current = d3.select(gTagRef.current);

      d3.json('/dataBundesLander.json').then((collection) => {
        setRegions(collection.features);

        const bounds = d3.geoBounds(collection);
        const bottomLeft = bounds[0];
        const topRight = bounds[1];
        const rotLong = -(topRight[0] + bottomLeft[0]) / 2;
        const center = [(topRight[0] + bottomLeft[0]) / 2 + rotLong, (topRight[1] + bottomLeft[1]) / 2];

        //default scale projection
        const defaultProjection = d3
          .geoAlbers()
          .parallels([bottomLeft[1], topRight[1]])
          .rotate([rotLong, 0, 0])
          .translate([width / 2, height / 2])
          .center(center);

        const bottomLeftPx = defaultProjection(bottomLeft);
        const topRightPx = defaultProjection(topRight);
        const scaleFactor =
          1.0 * Math.min(width / (topRightPx[0] - bottomLeftPx[0]), height / (-topRightPx[1] + bottomLeftPx[1]));

        const adaptedProjection = d3
          .geoAlbers()
          .parallels([bottomLeft[1], topRight[1]])
          .rotate([rotLong, 0, 0])
          .translate([width / 2, height / 2])
          .scale(scaleFactor * 0.975 * 1000)
          .center(center);

        setProjection(() => adaptedProjection);

        const generatedGeoPath = d3.geoPath().projection(adaptedProjection);
        setGeoPath(() => generatedGeoPath);
      });
    }

    return () => undefined;
  }, [height, width]);

  //className='section__map'

  return (
    <section style={{marginTop: headerHeight}}>
      <div className='document__header'>
        <img src={docHeaderPic} alt="" className='document__header_pic' />
        <h1
          className='document__header_title'
          style={{
            whiteSpace: deviceUpXs ? 'nowrap' : 'normal',
            top: deviceUpXs ? '21%' : '22%',
            transform: deviceUpXs ? 'translate(-50%, -21%)' : 'translate(-50%, -22%)',
          }}
        >
          {hovered ? hovered.properties.NAME_1 : focused ? focused.properties.NAME_1 : 'Deutschland'}
        </h1>
      </div>
      {/* <Container maxWidth='lg'>
        <h3 className='map_title'>
          {hovered ? hovered.properties.NAME_1 : focused ? focused.properties.NAME_1 : 'Deutschland'}
        </h3>
      </Container> */}
      <Container
        style={{
          maxWidth: '1232px',
          padding: deviceXs ? '24px' : '40px 100px',
          marginBottom: '60px',
          backgroundColor: 'white',
          borderRadius: deviceXs ? '0' : '20px',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        }}
      >
        <div ref={containerRef} style={{ width: '100%', height: '70vh' }}>
          <svg width='100%' height='100%' style={{ display: 'block' }}>
            <g ref={gTagRef} id='states'>
              {!!geoPath &&
                regions.map((el) => {
                  const geo = geoPath(el);
                  return (
                    <path
                      key={el.properties.ID_1}
                      className={`feature ${
                        focused && el.properties.ID_1 === focused.properties.ID_1
                          ? 'active'
                          : hovered && el.properties.ID_1 === hovered.properties.ID_1
                          ? 'hovered'
                          : ''
                      }`}
                      d={geo}
                      onClick={() => clickPath(el)}
                      onMouseEnter={() => mouseEnterPath(el)}
                      onMouseLeave={() => mouseLeavePath(el)}
                    ></path>
                  );
                })}
  
              {!!projection &&
                pins.map((pin, key) => {
                  return (
                    <g
                      key={key}
                      // onMouseEnter={() => setOpenTooltip(el.documentid)}
                      onMouseEnter={() => setOpenTooltip(pin[0].teasertitle)}
                      onMouseLeave={() => setOpenTooltip('')}
                    >
                      <circle
                        className='pin'
                        width={40}
                        height={40}
                        x={-20}
                        y={-20}
                        r={5}
                        transform={'translate(' + projection([pin[0].location.longitude, pin[0].location.latitude]) + ')'}
                        onMouseEnter={(e) => {
                          setAnchorEl(e.currentTarget);
                        }}
                      />
                      <RichTooltip
                        content={pin.map((item, key) => {
                          console.log('item', item);
                          return (
                            <div key={key}>
                            {key > 0 && (
                            <Divider style={{ margin: deviceSm ? '0 0 16px 0' : '0 0 32px 0' }} />)}
                            <MapTeaser title={item.teasertitle} key={item.teasertitle} content={item.content}/>
                            {/* <Teaser teaser={item} key={item.teasertitle} clickable={false}/> */}
                          </div>
                          )
                        })}
                        anchorEl={anchorEl}
                        // open={openTooltip === el.documentid}
                        open={openTooltip === pin[0].teasertitle}
                        placement='bottom'
                        onClose={() => setOpenTooltip('')}
                      />
                    </g>
                  );
                })}
            </g>
          </svg>
        </div>
      </Container>
    </section>
  );
};

export default Map;
