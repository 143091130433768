import { AxiosResponse } from 'axios';
import { User } from '../../components/types';
import { axiosInstance } from '../AppContext';
import { handler } from './common';

export const getHomeTop = async () => {
  const url = '/highlight';

  //try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  /*} catch (err) {
    handler(err);
  }*/
};

export const getUser = async () => {
  const url = '/user';

  try {
    const res: AxiosResponse<User> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getHighLightedTags = async () => {
  const url = '/highlightedtags';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getLatestNews = async () => {
  const url = '/document/list/types/%5B%22Nachricht%22%2C%22Fachstudie%22%2C%22Content%20hub%22%5D/6';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getAllNews = async () => {
  const url = '/document/list/types/%5B%22Nachricht%22%2C%22Fachstudie%22%2C%22Content%20hub%22%5D/0';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getLatestReports = async () => {
  const url =
    '/document/list/types/%5B%22Fallbericht%22%2C%22Podcast%20Fallbericht%22%2C%22Webcast%20Fallbericht%22%2C%22Infografik%20Fallbericht%22%2C%22Externe%20Resssource%20Fallbericht%22%5D/3';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getAllReports = async () => {
  const url =
    '/document/list/types/%5B%22Fallbericht%22%2C%22Podcast%20Fallbericht%22%2C%22Webcast%20Fallbericht%22%2C%22Infografik%20Fallbericht%22%2C%22Externe%20Resssource%20Fallbericht%22%5D/0';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

const expertsVal =
  '["Podcast Arztinfo","Podcast Diagnostik","Podcast Fachstudie","Podcast Fallbericht","Podcast Therapie"]';

export const getLatestExperts = async () => {
  const url = `/document/list/types/${encodeURIComponent(expertsVal)}/3`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getAllExperts = async () => {
  const url = `/document/list/types/${encodeURIComponent(expertsVal)}/0`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getLatestInterviews = async () => {
  // const url =
  //   '/document/list/types/%5B%22Externe%20Resssource%20Fallbericht%22%2C%22Podcast%20Fallbericht%22%2C%22Externe%20Resssource%20Fachstudie%22%2C%22Podcast%20Fachstudie%22%2C%22Externe%20Resssource%20Diagnostik%22%2C%22Podcast%20Diagnostik%22%2C%22Podcast%20Arztinfo%22%2C%22Externe%20Resssource%20Arztinfo%22%2C%22Externe%20Resssource%20Therapie%22%2C%22Podcast%20Therapie%22%5D/3';

  const url =
    '/document/list/types/%5B%22Externe%20Resssource%20Arztinfo%22%2C%22Externe%20Resssource%20Diagnostik%22%2C%22Externe%20Resssource%20Fachstudie%22%2C%22Externe%20Resssource%20Therapie%22%2C%22Webcast%20Arztinfo%22%2C%22Webcast%20Diagnostik%22%2C%22Webcast%20Fachstudie%22%2C%22Webcast%20Therapie%22%5D/3';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getAllInterviews = async () => {
  const url =
    '/document/list/types/%5B%22Externe%20Resssource%20Arztinfo%22%2C%22Externe%20Resssource%20Diagnostik%22%2C%22Externe%20Resssource%20Fachstudie%22%2C%22Externe%20Resssource%20Therapie%22%2C%22Webcast%20Arztinfo%22%2C%22Webcast%20Diagnostik%22%2C%22Webcast%20Fachstudie%22%2C%22Webcast%20Therapie%22%5D/0';

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const followTag = async (tagName: string) => {
  const url = `/followtag/${tagName}`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.put(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const unfollowTag = async (tagName: string) => {
  const url = `/unfollowtag/${tagName}`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.put(url);
    return res;
  } catch (err) {
    handler(err);
  }
};
