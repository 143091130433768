import axios, { AxiosResponse } from 'axios';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { IBookmark } from '../../components/types';
import { axiosInstance } from '../AppContext';

export const handler = (err: any) => {
  let error = err;

  if (err.response && err.response.data.hasOwnProperty('message')) {
    error = err.response.data;
  } else if (!err.hasOwnProperty('message')) {
    error = err.toJSON();
  }
  let statusCode=0;
  if(err.response && err.response.hasOwnProperty('status')){
    statusCode=err.response.status;
  }
  return {error:new Error(error.message),statusCode:statusCode};
};

export const range = (length: number) => Array.from(Array(length), (x, index) => index);

// TODO: Check whether it's needed when get more tags
export const humanizeTagTitle = (title: string) => title.replace('CONTENTHUB_', '').replace('_', ' ');
export const humanizeTagTitleDocTitle = (title: string) => {
  return title
  .replace('CONTENTHUB_', '')
  .replace('_', ' ')
  .split(' ')
  .map(word => {
    word = word.toLowerCase();

    return word[0].toUpperCase() + word.slice(1);
  })
  .join(' ');
}

export const formatDate = (date: string = '') => {
  if (!!date && typeof date === 'string') {
    const dd = parseInt(date.substring(0, 2));
    const mm = parseInt(date.substring(3, 5));
    const yyyy = parseInt(date.substring(6, 10));
    const dateObj = new Date(yyyy, mm - 1, dd);

    if (dateObj) {
      return format(dateObj, 'PP', { locale: de });
    }
  }

  return '';
};

export const addBookmark = async (type: string, id: string) => {
  const url = `/bookmark/${type}/${id}`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.post(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const removeBookmark = async (type: string, id: string) => {
  const url = `/bookmark/${type}/${id}`;

  try {
    const res: AxiosResponse<any[]> = await axiosInstance.delete(url);
    return res;
  } catch (err) {
    handler(err);
  }
};

export const getYouTubeMetadata = async (src?: string) => {
  const regExp = /^.*(youtu\.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  if (src) {
    const match = src.match(regExp);
    if (match && match[2].length === 11) {
      const videoId: string = match[2];
      const videoUrl: string = `https://www.youtube.com/watch?v=${videoId}`;
      const requestUrl: string = `http://youtube.com/oembed?url=${videoUrl}&format=json`;
      const result = await axios.get(requestUrl);
      return result.data;
    }

    return null;
  }

  return null;
};

export const getBookmarks = async () => {
  const url = '/bookmarks';

  try {
    const res: AxiosResponse<IBookmark[]> = await axiosInstance.get(url);
    return res;
  } catch (err) {
    handler(err);
  }
};
