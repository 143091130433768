import React, { ReactNode } from 'react';
import { createStyles, Divider, Fade, makeStyles, Popper, Theme } from '@material-ui/core';

import CloseIcon from '@material-ui/icons/Close';

type Props = {
  anchorEl: null | HTMLElement;
  setAnchorEl: (anchorEl: null | HTMLElement) => void;
  children: ReactNode | ReactNode[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      padding: theme.spacing(2),
      maxWidth: '340px',
      boxShadow: '0px 3px 26px rgb(0 0 0 / 16%)',
      borderRadius: '20px',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const LoginPopper = (props: Props) => {
  const { children, anchorEl, setAnchorEl } = props;

  const classes = useStyles();

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      style={{zIndex: 1102}}
      placement='bottom-end'
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <div className={classes.paper}>
            <Divider
              style={{
                width: '100%',
                marginTop: '36px',
                marginBottom: '16px',
                backgroundColor: '#00c3ff',
              }}
            />
            {children}
            <CloseIcon
              style={{position: 'absolute', top: '16px', right: '16px', fill: '#a32f88'}}
              onClick={() => setAnchorEl(null)}
            />
          </div>
        </Fade>
      )}
    </Popper>
  );
};
