import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';

const PDF = ({ src }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);


  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);       
  }

  return (
    <div>
      {/* <iframe src={`data:application/pdf;base64,${src}`} /> */}
      <embed src={`data:application/pdf;base64,${src}`} width="100%" height="800px"></embed>
      {/*<Document file={`data:application/pdf;base64,${src}`} onLoadSuccess={onDocumentLoadSuccess}>
        <Page pageNumber={pageNumber} />
      </Document>*/}
    </div>
  );
};

export default PDF;
