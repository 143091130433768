import React, { useState, MouseEvent, useEffect } from 'react';
import { Card, CardActionArea, CardMedia, Chip, Tooltip, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import { ReactComponent as PlayIcon } from '../../media/icons/play.svg';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '../../media/icons/bookmark-filled.svg';
import { Link } from 'react-router-dom';
import { addBookmark, getYouTubeMetadata, humanizeTagTitle, removeBookmark } from '../../context/actions/common';
import podcastImg from '../../media/placeholders/audio-hub.png';
import ImgPreview from '../reusable/ImgPreview';
import { IBookmark, ITeaser } from '../types';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useAppContext } from '../../context/AppContext';
import { Translate } from '@material-ui/icons';

interface Props {
  large?: boolean;
  teaser: any;
}

const HubTeaserCard = ({ teaser, large }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const less400 = useMediaQuery(theme.breakpoints.down(400));

  const { bookmarks, setBookmarks } = useAppContext();

  const [setInfo] = useCustomSnackbar('info');

  const [teaserData, setTeaserData] = useState<ITeaser>(() => teaser);
  const [preview, setPreview] = useState<boolean>(false);

  const withImage =
    teaser?.teaserimage &&
    teaser?.mediatype?.toLowerCase() !== 'video' &&
    teaser?.mediatype?.toLowerCase() !== 'audio' &&
    !teaser?.teaserurl?.includes('youtube');
  const withVideo = teaser?.mediatype?.toLowerCase() === 'video';
  const withAudio = teaser?.mediatype?.toLowerCase() === 'audio';
  const withUrl = teaser?.mediatype?.toLowerCase() === 'url';
  const withYouTube = teaser?.teaserurl?.includes('youtube');

  useEffect(() => {
    if (withYouTube) {
      getYouTubeMetadata(teaser?.teaserurl).then(
        (data) => data?.title && setTeaserData({ ...teaserData, teasertitle: data.title })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teaser, withAudio, withYouTube]);

  const toggleImgPreview = () => {
    if (teaser?.mediatype?.toLowerCase() === 'image') {
      setPreview(!preview);
    }
  };

  const handleBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();

    const objecttype = withVideo
      ? 'VIDEO'
      : withUrl
      ? 'URL'
      : withAudio
      ? 'AUDIO'
      : teaser?.mediatype?.toLowerCase() === 'image'
      ? 'IMAGE'
      : 'DOCUMENT';

    if (!teaserData?.bookmark) {
      addBookmark(objecttype, teaserData?.documentid || teaserData?.teaserid || teaserData?.objectid || '')
        .then(() => {
          setTeaserData({ ...teaserData, bookmark: 1 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks,
              {
                objectid: teaserData?.documentid || teaserData.teaserid || teaserData.objectid,
                objecttype,
                title: teaserData.teasertitle,
              },
            ]);
          }
          setInfo('Zu Lesezeichen hinzugefügt');
        })
        .catch((err) => console.log('Oops: ', err));
    } else {
      removeBookmark(objecttype, teaserData?.documentid || teaserData?.teaserid || teaserData?.objectid || '')
        .then(() => {
          setTeaserData({ ...teaserData, bookmark: 0 });
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks.filter(
                (el: IBookmark) =>
                  el.objectid !== teaserData?.documentid &&
                  el.objectid !== teaserData?.teaserid &&
                  el.objectid !== teaserData?.objectid
              ),
            ]);
          }
          setInfo('Aus Lesezeichen entfernt');
        })
        .catch((err) => console.log('Oops: ', err));
    }
  };

  return (
    <>
      <Link
        to={
          teaserData?.mediatype?.toLowerCase() === 'image'
            ? ''
            : withAudio
            ? { pathname: `/audio/${teaserData?.documentid || teaserData?.objectid}`, state: { teaser: teaserData } }
            : withVideo || withYouTube
            ? {
                pathname: `/video/${teaserData?.documentid || teaserData?.objectid}`,
                state: { teaser: teaserData },
              }
            : `/document/${teaserData?.documentid || teaserData?.objectid}`
        }
      >
        <Card
          elevation={0}
          style={{
            width: '100%',
            height: !large || (withVideo && deviceXs) ? '10rem' : 'auto',
            position: 'relative',
          }}
          onClick={toggleImgPreview}
        >
          <CardActionArea style={{ height: 'auto'}}>
            {(withImage || withAudio || withVideo) && (
              <CardMedia
                component='img'
                alt=''
                height='100%'
                src={
                  withAudio
                    ? podcastImg
                    : teaserData?.teaserimage
                    ? `data:image/png;base64, ${teaserData.teaserimage}`
                    : undefined
                }
              />
            )}

            <div className='card_gradient'>
              {withVideo && (
                <div
                  className='play-icon-wrapper'
                  style={
                    large
                      ? { width: '5rem', height: '5rem', borderRadius: '2.5rem' }
                      : { width: '2.2rem', height: '2.2rem', borderRadius: '1.1rem' }
                  }
                >
                  <PlayIcon style={large ? { width: '2rem', height: '2rem' } : { width: '1rem', height: '1rem' }} />
                </div>
              )}
            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                position: 'absolute',
                top: '1rem',
                left: '1rem',
                right: '1rem',
              }}
            >
              {teaserData?.tags && (
                <div style={{ margin: '-0.25rem', marginBottom: '0.5rem' }}>
                  {/* {teaserData.tags.map((tag: string, key: number) => (
                    <Chip
                      key={key}
                      color='primary'
                      label={<span dangerouslySetInnerHTML={{ __html: `${humanizeTagTitle(tag)}` }} />}
                      size='medium'
                      style={{ margin: '0.25rem' }}
                    />
                  ))} */}
                </div>
              )}
            {teaserData?.documenttypeid=="1fa9ca7c-a17e-4324-97b7-74a1bf7f5895" ?
            (
              <div>Anzeige</div>
            ):
            (  
            <div>
              {(
                !!bookmarks
                  ? bookmarks?.find(
                      (el: IBookmark) =>
                        el.objectid === teaserData?.documentid ||
                        el.objectid === teaserData?.teaserid ||
                        el.objectid === teaserData?.objectid
                    )
                  : !!teaserData?.bookmark
              ) ? (
                <BookmarkFilledIcon className='icon-button__light' onClick={handleBookmark} />
              ) : (
                <BookmarkIcon className='icon-button__light' onClick={handleBookmark} />
              )}
            </div>
            )
            }
            </div>

            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                overflow: 'hidden',
                padding: large ? '1rem' : '.5rem',
              }}
            >
              <Tooltip
                title={
                  <>
                    <Typography
                      variant='body2'
                      color='inherit'
                      dangerouslySetInnerHTML={{
                        __html: `${teaserData?.teasertitle || teaserData?.displayname || teaserData?.source || ''}`,
                      }}
                    />
                  </>
                }
              >
                <Typography
                  variant='body1'
                  noWrap={less400 ? true : false}
                  style={{
                    fontFamily: 'Noto Serif SC',
                    fontSize: large ? '2rem' : '0.875rem',
                    color: '#fff',
                    // whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                  dangerouslySetInnerHTML={{
                    __html: `${teaserData?.teasertitle || teaserData?.displayname || teaserData?.source || ''}`,
                  }}
                />
              </Tooltip>
            </div>
          </CardActionArea>
          <div
            style={{
              backgroundColor: theme.palette.primary.main,
              height: '122px', padding: '2rem 1rem',
            }}
          >
            {teaserData?.teaserdesc && (
              <Tooltip
                title={
                  teaserData.teaserdesc ? (
                    <Typography
                      variant='body2'
                      color='inherit'
                      dangerouslySetInnerHTML={{
                        __html: `${teaserData.teaserdesc}`,
                      }}
                    />
                  ) : (
                    ''
                  )
                }
              >
                <Typography
                  variant='body1'
                  style={{
                    fontSize: large ? '0.875rem' : '0.625rem',
                    textOverflow: 'ellipsis',
                    color: '#fff',
                    opacity: 1,
                  }}
                  className="hub_teaser_card_text"
                  dangerouslySetInnerHTML={{
                    __html: `${teaserData.teaserdesc}`,
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Card>
      </Link>
      {teaserData?.mediatype?.toLowerCase() === 'image' && (
        <ImgPreview teaser={teaser} open={preview} onClose={() => setPreview(false)} />
      )}
    </>
  );
};

export default HubTeaserCard;
