import { makeStyles } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .Mui-selected': {
      backgroundColor: '#2C364F',
      color: '#fff',
    },
  },
}));

interface Props {
  count: number;
  page: number;
  setPage: any;
}

const CustomPagination = ({ count, page, setPage }: Props) => {
  const classes = useStyles();

  return (
    <div className='pagination-wrapper'>
      <Pagination
        className={classes.root}
        count={count}
        page={page}
        shape='rounded'
        size='small'
        onChange={(e, value) => setPage(value)}
      />
    </div>
  );
};

export default CustomPagination;
