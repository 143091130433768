import React from 'react';

interface Props {
  title: string;
  content: string;
}

const MapTeaser = (props: Props) => {
  const {title, content} = props;

  return (
    <div>
      <h2 dangerouslySetInnerHTML={{__html: title}}></h2>
      <p dangerouslySetInnerHTML={{__html: content}}></p>
    </div>
  )
}

export default MapTeaser;

