import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {Button, Container, Divider, Grid, Tab, Tabs, Tooltip, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { ReactComponent as StethoscopeIcon } from '../../media/icons/stethoscope.svg';
import { ReactComponent as FirstAidKitIcon } from '../../media/icons/first-aid-kit.svg';
import { ReactComponent as PieChartIcon } from '../../media/icons/pie-chart.svg';
import { ReactComponent as VirusIcon } from '../../media/icons/virus.svg';
import { ReactComponent as NewspaperIcon } from '../../media/icons/newspaper.svg';
import { ReactComponent as DoctorIcon } from '../../media/icons/doctor.svg';
import { ReactComponent as PatientIcon } from '../../media/icons/patient.svg';
import { ReactComponent as AnalyticsIcon } from '../../media/icons/analytics.svg';
import Teaser from '../reusable/Teaser';
import { range } from '../../context/actions/common';
import TeaserSkeleton from '../reusable/TeaserSkeleton';
import { Skeleton } from '@material-ui/lab';
import { getHubTabTeasers } from '../../context/actions/hubs';
import { LandingButton, LandingButtonB } from '../landing/Landing';
import { ITeaser } from '../types';

const VerticalTabs = withStyles({
  indicator: {
    minWidth: '3px',
    right: '-2px',
  },
  root: {
    overflow: 'visible !important',
    padding: '0',
    '& .Mui-selected': {
      '& .MuiTab-wrapper': {
        color: '#a32f88',
        fontWeight: 'bold',
        lineHeight: '1.3rem',
      },
    },
    '& .MuiTabs-indicator': {
      backgroundColor: '#a32f88',
      height: '2px',
    }
  },
  fixed: {
    overflow: 'visible !important',
  },
})(Tabs);

const hardcodedTeaser: ITeaser = {
  bookmark: 0,
  documentdate: '',
  documentid: "255c267e-d4fb-4d99-83c4-3b09d503bbe5",
  documenttype: null,
  mediatype: "map",
  tags: [],
  teaserdesc: "Hier finden Sie Informationen und Kontaktdaten zu Krebszentren in Deutschland, die eine Car-T-Zelltherapie anbieten.",
  teaserimage: null,
  teasertitle: "CAR-T Zentren",
  teaserurl: '/map'
};

interface Props {
  displayGrid?: boolean;
  currentTab: string;
  setCurrentTab: Dispatch<SetStateAction<string>>;
  prevTab: string | null;
  setPrevTab: Dispatch<SetStateAction<string | null>>;
  parentLoading: boolean;
  hubName: string;
  tabs: any;
}

const ArticlesScroll = ({
  displayGrid,
  currentTab,
  setCurrentTab,
  prevTab,
  setPrevTab,
  parentLoading,
  hubName,
  tabs,
}: Props) => {
  const classes=LandingButton();
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [teasers, setTeasers] = useState<any[]>([]);
  const [blaueHandBriefTeasers, setBlaueHandBriefTeasers] = useState<any[]>([]);

  const arztinfoExtraCount: number =
    blaueHandBriefTeasers?.filter((teaser: any) => !!teaser?.tags?.includes('BHB_Fachkreise'))?.length || 0;

  const patienteninfoExtraCount: number =
    blaueHandBriefTeasers?.filter((teaser: any) => !!teaser?.tags?.includes('BHB_Patient'))?.length || 0;

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [shouldScroll, setShouldScroll] = useState(false);

  useEffect(() => {
    const handleCommonTabTeasers = async () => {
      try {
        const response: any = await getHubTabTeasers(hubName, '["blaue-hand-brief"]');
        const data = response?.data;
        setBlaueHandBriefTeasers(data);
      } catch (err) {
        setBlaueHandBriefTeasers([]);
      }
    };

    handleCommonTabTeasers();
  }, [hubName]);

  useEffect(() => {
    const handleTabTeasers = async (tabs: string) => {
      setIsLoading(true);

      try {
        const response: any = await getHubTabTeasers(hubName, tabs);
        const data = response?.data;
        setTeasers(data);
        setIsLoading(false);
      } catch (err) {
        setTeasers([]);
        setIsLoading(false);
      }
    };

    if (!parentLoading) {
      if (!!hubName) {
        if (currentTab === 'alle' && prevTab !== tabs.nachrichten.value && !!tabs.nachrichten.count) {
          handleTabTeasers(tabs.nachrichten.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          prevTab !== tabs.diagnostik.value &&
          !!tabs.diagnostik.count
        ) {
          handleTabTeasers(tabs.diagnostik.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          prevTab !== tabs.therapie.value &&
          !!tabs.therapie.count
        ) {
          handleTabTeasers(tabs.therapie.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          prevTab !== tabs.leitlinie.value &&
          !!tabs.leitlinie.count
        ) {        
          handleTabTeasers(tabs.leitlinie.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          prevTab !== tabs.fallbericht.value &&
          !!tabs.fallbericht.count
        ) {
          handleTabTeasers(tabs.fallbericht.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          !tabs.fallbericht.count &&
          prevTab !== tabs.fachstudie.value &&
          !!tabs.fachstudie.count
        ) {
          handleTabTeasers(tabs.fachstudie.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          !tabs.fallbericht.count &&
          !tabs.fachstudie.count &&
          prevTab !== tabs.krankheitsbild.value &&
          !!tabs.krankheitsbild.count
        ) {
          handleTabTeasers(tabs.krankheitsbild.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          !tabs.fallbericht.count &&
          !tabs.fachstudie.count &&
          !tabs.krankheitsbild.count &&
          prevTab !== tabs.arztinfo.value &&
          (!!tabs.arztinfo.count || !!arztinfoExtraCount)
        ) {
          handleTabTeasers(tabs.arztinfo.value);
        } else if (
          currentTab === 'alle' &&
          !tabs.nachrichten.count &&
          !tabs.diagnostik.count &&
          !tabs.therapie.count &&
          !tabs.fallbericht.count &&
          !tabs.fachstudie.count &&
          !tabs.krankheitsbild.count &&
          (!tabs.arztinfo.count || !arztinfoExtraCount) &&
          prevTab !== tabs.patienteninfo.value &&
          (!!tabs.patienteninfo.count || !!patienteninfoExtraCount)
        ) {
          handleTabTeasers(tabs.patienteninfo.value);
        } else {
          if (prevTab !== currentTab) {
            handleTabTeasers(currentTab);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTab, hubName, prevTab, parentLoading]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab((prevState) => {
      setPrevTab(prevState);
      setShouldScroll(false);
      return newValue;
    });
  };

  const handleLoadMore = () => setShouldScroll(prevState => !prevState);

  let currentTabName = currentTab;

  if (currentTab !== 'alle') {
    currentTabName = currentTab.slice(1, -1).split(',')[0].slice(1, -1);
  }

  return (
    <div className='section__dark section__dark_new' style={{ padding: '3rem' }}>
      <Container id='articles-scroll' maxWidth='lg' disableGutters={deviceXs}>
        <h3
          className='section_title section_title_new'
          style={{color: `${theme.palette.primary.main}`, margin: '0'}}
        >
          Themen
        </h3>
        <Divider style={{height: '4px', margin: '1rem 0 2rem', backgroundColor: 'white'}}/>
        <div style={{ display: 'flex' }}>
          <VerticalTabs
            orientation='vertical'
            value={
              currentTab === 'alle'
                ? !!tabs.nachrichten.count
                  ? tabs.nachrichten.value
                  : !!tabs.diagnostik.count
                  ? tabs.diagnostik.value
                  : !!tabs.therapie.count
                  ? tabs.therapie.value
                  : !!tabs.leitlinie.count
                  ? tabs.leitlinie.value                  
                  : !!tabs.fallbericht.count
                  ? tabs.fallbericht.value
                  : !!tabs.fachstudie.count
                  ? tabs.fachstudie.value
                  : !!tabs.krankheitsbild.count
                  ? tabs.krankheitsbild.value
                  : !!tabs.arztinfo.count || !!arztinfoExtraCount
                  ? tabs.arztinfo.value
                  : !!tabs.patienteninfo.count || !!patienteninfoExtraCount
                  ? tabs.patienteninfo.value
                  : false
                : currentTab
            }
            onChange={handleTabChange}
            style={{ borderRight: '1px solid rgba(225, 228, 233, 0.7)', padding: '2rem 0' }}
          >
            {tabs.nachrichten.count > 0 && (<Tab
              value={tabs.nachrichten.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 90} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.nachrichten.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <NewspaperIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${(currentTabName === 'alle' || currentTabName === 'nachricht') ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.nachrichten.name}</span>}
                      <span> ({tabs.nachrichten.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.nachrichten.count}
              style={{backgroundColor: (currentTabName === 'alle' || currentTabName === 'nachricht') ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.diagnostik.count > 0 && (<Tab
              value={tabs.diagnostik.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 90} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.diagnostik.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <StethoscopeIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'diagnostik' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.diagnostik.name}</span>}
                      <span> ({tabs.diagnostik.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.diagnostik.count}
              style={{backgroundColor: currentTabName === 'diagnostik' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.therapie.count > 0 && (<Tab
              value={tabs.therapie.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 120} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.therapie.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <FirstAidKitIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'therapie' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.therapie.name}</span>}
                      <span> ({tabs.therapie.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.therapie.count}
              style={{backgroundColor: currentTabName === 'therapie' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.leitlinie.count > 0 && (<Tab
              value={tabs.leitlinie.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 120} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.leitlinie.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <FirstAidKitIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'leitlinie' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.leitlinie.name}</span>}
                      <span> ({tabs.leitlinie.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.leitlinie.count}
              style={{backgroundColor: currentTabName === 'leitlinie' ? 'white' : '#E5F6FE'}}
            /> )}           
            {tabs.fallbericht.count > 0 && (<Tab
              value={tabs.fallbericht.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 100} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.fallbericht.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <AnalyticsIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'fallbericht' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.fallbericht.name}</span>}
                      <span> ({tabs.fallbericht.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.fallbericht.count}
              style={{backgroundColor: currentTabName === 'fallbericht' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.fachstudie.count > 0 && (<Tab
              value={tabs.fachstudie.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 110} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.fachstudie.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <PieChartIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'fachstudie' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.fachstudie.name}</span>}
                      <span> ({tabs.fachstudie.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.fachstudie.count}
              style={{backgroundColor: currentTabName === 'fachstudie' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.krankheitsbild.count > 0 && (<Tab
              value={tabs.krankheitsbild.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 80} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.krankheitsbild.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <VirusIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'krankheitsbild' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.krankheitsbild.name}</span>}
                      <span> ({tabs.krankheitsbild.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || !tabs.krankheitsbild.count}
              style={{backgroundColor: currentTabName === 'krankheitsbild' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.arztinfo.count > 0 && (<Tab
              value={tabs.arztinfo.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 95} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.arztinfo.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <DoctorIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'arztinfo' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.arztinfo.name}</span>}
                      <span> ({tabs.arztinfo.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || (!tabs.arztinfo.count && !arztinfoExtraCount)}
              style={{backgroundColor: currentTabName === 'arztinfo' ? 'white' : '#E5F6FE'}}
            />)}
            {tabs.patienteninfo.count > 0 && (<Tab
              value={tabs.patienteninfo.value}
              label={
                parentLoading ? (
                  <Skeleton width={deviceXs ? '2rem' : 105} height='2rem' />
                ) : (
                  <div className='tab-wrapper'>
                    <Tooltip title={deviceXs ? tabs.patienteninfo.name : ''} placement='right' arrow>
                      <div className='tab-icon-wrapper'>
                        <PatientIcon />
                      </div>
                    </Tooltip>
                    <div
                      style={{
                        borderBottom: `3px solid ${currentTabName === 'patienteninfo' ? theme.palette.primary.main : 'transparent'}`,
                        marginLeft: '1.5rem'}}
                    >
                      {!deviceXs && <span>{tabs.patienteninfo.name}</span>}
                      <span> ({tabs.patienteninfo.count})</span>
                    </div>
                  </div>
                )
              }
              disableRipple
              disabled={parentLoading || (!tabs.patienteninfo.count && !patienteninfoExtraCount)}
              style={{backgroundColor: currentTabName === 'patientinfo' ? 'white' : '#E5F6FE'}}
            />)}
          </VerticalTabs>
          <div
            style={{
              margin: deviceXs ? 0 : '2rem',
              position: 'relative',
              width: '100%',
              height: shouldScroll ? '600px' : 'auto',
              overflow: 'hidden',
            }}
          >
            <div
              style={{
                position: 'absolute',
                top: '-1px',
                left: 0,
                right: '5px',
                height: '2rem',
                background: 'linear-gradient(180deg, #E5F6FE 0%, rgba(250,250,250,0) 100%)',
              }}
            ></div>
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: '5px',
                height: '2rem',
                background: 'linear-gradient(0deg, #E5F6FE 0%, rgba(250,250,250,0) 100%)',
              }}
            ></div>
            <div className='articles-scroll-wrapper'>
              {isLoading || parentLoading ? (
                <Grid container spacing={deviceXs ? 2 : 4}>
                  {range(deviceXs ? 4 : 8).map((el: number, key: number) => (
                    <Grid item xs={12} md={!!displayGrid ? 6 : 12} key={key}>
                      <TeaserSkeleton />
                    </Grid>
                  ))}
                </Grid>
              ) : teasers?.length > 0 ? (
                <>
                  <Grid container spacing={deviceXs ? 2 : 4}>
                    {teasers
                      ?.filter((teaser: any, key: number, arr) => {
                        if (shouldScroll) {
                          return true;
                        } else {
                          return key < 6
                        }
                      })
                      ?.map((teaser: any, key: number) => (
                      <Grid item xs={12} md={!!displayGrid ? 6 : 12} key={key}>
                        <Teaser teaser={teaser} />
                      </Grid>
                    ))}
  
                    {(currentTab === tabs.arztinfo.value || currentTab === tabs.patienteninfo.value) &&
                      blaueHandBriefTeasers
                        ?.filter((teaser: any) =>
                          currentTab === tabs.arztinfo.value
                            ? !!teaser?.tags?.includes('BHB_Fachkreise')
                            : currentTab === tabs.patienteninfo.value
                            ? !!teaser?.tags?.includes('BHB_Patient')
                            : teaser
                        )
                        .map((teaser: any, key: number) => (
                          <Grid item xs={12} md={!!displayGrid ? 6 : 12} key={key}>
                            <Teaser teaser={teaser} />
                          </Grid>
                        ))}
                    {currentTabName === 'arztinfo' && hubName === 'CAR-T-CELLS' && <Grid item xs={12} md={!!displayGrid ? 6 : 12} key={'hardcodedTeaser'}>
                      <Teaser teaser={hardcodedTeaser} />
                    </Grid>}
                  </Grid>
                  {(!shouldScroll && teasers.length > 6) ? (
                  <div className='landing_buttons-wrapper' style={{marginTop: '2rem', zIndex: 1000, position: 'relative'}}>
                    <LandingButtonB  variant='contained' color='secondary' onClick={handleLoadMore}>
                      Mehr anzeigen
                    </LandingButtonB>
                  </div>) : false}
                </>
              ) : (
                <div style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                  <span className='no-results-msg'>Es gibt noch keine Dokumente zu diesem Thema</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default ArticlesScroll;
