/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Container, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

interface Props {}

const Page404 = (props: Props) => {
  const history = useHistory();

  return (
    <div className='page-404 content-wrapper'>
      <Container maxWidth='lg'>
        <Typography variant='body1' color='textSecondary' align='center'>
          Diese Seite existiert nicht
        </Typography>
        <p style={{ textAlign: 'center' }}>
          <a href='' onClick={() => history.replace('/')}>
            Startseite
          </a>
        </p>
      </Container>
    </div>
  );
};

export default Page404;
