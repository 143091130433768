import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import ContentHubs from '../components/home/ContentHubs';
import Home from '../components/home/Home';
import SeeAllExperts from '../components/home/SeeAllExperts';
import SeeAllInterviews from '../components/home/SeeAllInterviews';
import SeeAllNews from '../components/home/SeeAllNews';
import SeeAllReports from '../components/home/SeeAllReports';
import Hub from '../components/hub/Hub';
import Nav from '../components/Nav';
import Media from '../components/reusable/Media';
import Search from '../components/search/Search';
import Document from '../components/reusable/Document';
import Landing from '../components/landing/Landing';
import Impressum from '../components/legal/Impressum';
import Datenschutz from '../components/legal/Datenschutz';
import Nutzungsbedingungen from '../components/legal/Nutzungsbedingungen';
import AGB from '../components/legal/AGB';
import { useAppContext  } from '../context/AppContext';
import Page404 from '../components/Page404';
import Map from '../components/hub/map/Map';

interface Props {}

const AuthRouter = (props: Props) => {
  
  const { isLogged } = useAppContext();

  return isLogged === false ? (
    <Nav>
      <Switch>
        <Route exact path='/welcome' component={Landing} />
        <Route exact path='/impressum' component={Impressum} />
        <Route exact path='/kontakt' component={Impressum} />
        <Route exact path='/datenschutz' component={Datenschutz} />
        <Route exact path='/nutzungsbedingungen' component={Nutzungsbedingungen} />
        <Route exact path='/agb' component={AGB} />
        <Redirect to='/welcome' />
      </Switch>
    </Nav>
  ) : isLogged === true ? (
    <Nav>
      <Switch>
        <Route exact path='/' component={Home} />
        {/* <Route exact path='/profile' component={Profile} />
              <Route exact path='/profile/change-password' component={ChangePassword} /> */}
        <Route path='/hub/:hubId' component={Hub} />
        <Route exact path='/hubs/all' component={ContentHubs} />
        <Route exact path='/experts/all' component={SeeAllExperts} />
        <Route exact path='/reports/all' component={SeeAllReports} />
        <Route exact path='/interviews/all' component={SeeAllInterviews} />
        <Route exact path='/news/all' component={SeeAllNews} />
        <Route path='/document/:id' component={Document} />
        <Route exact path='/video/:id' render={() => <Media video />} />
        <Route exact path='/audio/:id' component={Media} />
        <Route exact path='/search/:searchTag' component={Search} />
        <Route exact path='/impressum' component={Impressum} />
        <Route exact path='/datenschutz' component={Datenschutz} />
        <Route exact path='/nutzungsbedingungen' component={Nutzungsbedingungen} />
        <Route exact path='/agb' component={AGB} />
        <Route exact path='/404' component={Page404} />
        <Route exact path='/map' component={Map} />
        <Redirect to='/' />
      </Switch>
    </Nav>
  ) : null;
};

export default AuthRouter;
