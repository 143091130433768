import React, { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ReactComponent as CloseIcon } from '../media/icons/cross.svg';

type Variant = 'default' | 'error' | 'success' | 'warning' | 'info' | undefined;

export default function useCustomSnackbar(variant: Variant) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, setState] = useState<null | string>(null);

  useEffect(() => {
    if (!!state && typeof state === 'string') {
      enqueueSnackbar(state, {
        variant,
        action: (key) => (
          <div className='icon-button-wrapper'>
            <CloseIcon
              className='icon-button__light'
              onClick={() => {
                closeSnackbar(key);
              }}
            />
          </div>
        ),
      });

      return setState(null);
    }
  }, [state, setState, variant, closeSnackbar, enqueueSnackbar]);

  return [setState];
}
