import { Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';

interface Props {}

const TeaserSkeleton = (props: Props) => {
  return (
    <div style={{ width: '100%' }}>
      <Skeleton width='80%'>
        <Typography variant='h5'>.</Typography>
      </Skeleton>
      <Skeleton width='60%'>
        <Typography variant='h5'>.</Typography>
      </Skeleton>
      <Skeleton width='100%'>
        <Typography variant='body1'>.</Typography>
      </Skeleton>
      <Skeleton width='100%'>
        <Typography variant='body1'>.</Typography>
      </Skeleton>
      <Skeleton width='70%'>
        <Typography variant='body1'>.</Typography>
      </Skeleton>
    </div>
  );
};

export default TeaserSkeleton;
