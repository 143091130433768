import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { InputAdornment, OutlinedInput, useMediaQuery, useTheme, withStyles } from '@material-ui/core';
import { ReactComponent as SearchIcon } from '../../media/icons/search.svg';
import { useHistory } from 'react-router-dom';

const CustomSearchInput = withStyles({
  root: {
    height: '2.5rem',
  },
  input: {
    padding: '0.6rem',
  },
})(OutlinedInput);

interface Props {
  searchTag: string;
  setSearchTag: Dispatch<SetStateAction<string>>;
}

const SearchInput = ({ searchTag, setSearchTag }: Props) => {
  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const history = useHistory();

  const submitSearch = (e: any) => {
    e.preventDefault();
    if (searchTag) {
      history.push(`/search/${searchTag}`);
    }
  };

  return (
    <form onSubmit={submitSearch}>
      <CustomSearchInput
        fullWidth={deviceXs}
        style={{ minWidth: deviceSm ? 0 : '320px' }}
        type='search'
        id='search'
        inputProps={{ autoComplete: 'off' }}
        value={searchTag}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTag(e.target.value)}
        endAdornment={
          <InputAdornment position='end'>
            <SearchIcon className='icon-button__dark' onClick={submitSearch} />
          </InputAdornment>
        }
      />
      <button type='submit' style={{ display: 'none' }} />
    </form>
  );
};

export default SearchInput;
