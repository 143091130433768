import React, { useEffect, useState, MouseEvent, useRef } from 'react';
import { Button, Chip, Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ReactComponent as ChevronRightIcon } from '../../media/icons/chevron-right.svg';
import { ReactComponent as BookmarkIcon } from '../../media/icons/bookmark.svg';
import { ReactComponent as BookmarkFilledIcon } from '../../media/icons/bookmark-filled.svg';
import { getArticle, getTeaser } from '../../context/actions/article';
import { getHub } from '../../context/actions/hubs';
import { addBookmark, humanizeTagTitle, humanizeTagTitleDocTitle, removeBookmark } from '../../context/actions/common';
import { Skeleton } from '@material-ui/lab';
import useCustomSnackbar from '../../hooks/useCustomSnackbar';
import { useAppContext } from '../../context/AppContext';
import { IBookmark, IDocument, IHub ,ITeaser} from '../types';
import PDF from './PDF';
import { useCookies } from 'react-cookie';

import docHeaderPic from '../../media/landing/landing-vidal.jpg';
import { LandingButton,LandingButtonB } from '../landing/Landing';
import { handler } from '../../context/actions/common';
import { FaFilePdf } from 'react-icons/fa';
import OverlayTeaser from './OverlayTeaser';


interface Props { }

interface IParams {
  id: string;
}

const Document = (props: Props) => {
  const classesB=LandingButton();
  const { isLogged, setIsLogged } = useAppContext();
  const [cookies, setCookie, removeCookie] = useCookies();
  const [document, setDocument] = useState<IDocument>();
  const [hub, setHub] = useState<IHub>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { id } = useParams<IParams>();

  const { bookmarks, setBookmarks } = useAppContext();

  const [setInfo] = useCustomSnackbar('info');

  const history = useHistory();

  let mounted = useRef(true);

  const theme = useTheme();
  const deviceXs = useMediaQuery(theme.breakpoints.down('xs'));
  const deviceUpXs = useMediaQuery(theme.breakpoints.up(600));

  const appbar = window.document.querySelector('.toolbar');

  const [headerHeight, setHeaderHeight] = useState(appbar?.clientHeight);

  /*const teasers: ITeaser[] = [
    {teasertitle:"xxx",bookmark:false,displayname:'yyy',teaserdesc:'vvvvv'}
  ];*/

  const [teasers, setTeasers] = useState<ITeaser[]>([]);

  useEffect(() => {
    setHeaderHeight(appbar?.clientHeight);
  }, [deviceXs, deviceUpXs, appbar?.clientHeight]);

  useEffect(() => {
    mounted.current = true;

    getArticle(id)
      .then(async (res: any) => {
        const { data, status }: any = await res;
        if (mounted.current) {
          if (status === 200) {
            setDocument(data);
            if(data){
              const chresponse: any = await getHub(data.contenthubs[0].hubid);
              const chdata = chresponse?.data;
              const chstatus = chresponse?.status;
              if (chstatus === 200) {
                setHub(chdata);
              }
              const promoresponse: any = await getTeaser('6a53c7b5-c26d-419b-8249-1472796858f0');
              const promodata = promoresponse?.data;
              //console.debug("teaser data : "+JSON.stringify(promodata));
              const promostatus = promoresponse?.status;
              if (promostatus === 200) {
                setTeasers([promodata]);
              }
            }
          } else {
            history.replace('/404');
          }
        }
        setIsLoading(false);
      })
      .catch((err) => {
        let handledError = handler(err);
        if (handledError.statusCode == 403) {
          setIsLogged(false);
          removeCookie('X-MEDU');
          history.push('/welcome');
        } else {
          console.error('Oops: ', handledError.error);
          history.replace('/404');
        }
      });

    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleBookmark = (e: MouseEvent<HTMLSpanElement | SVGSVGElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (
      !!bookmarks
        ? bookmarks?.find(
          (el: IBookmark) =>
            el.objectid === document?.documentid ||
            el.objectid === document?.documenid ||
            el.objectid === document?.teaserid ||
            el.objectid === document?.objectid
        )
        : !!document?.bookmark
    ) {
      removeBookmark(
        'DOCUMENT',
        document?.documentid || document?.documenid || document?.teaserid || document?.objectid || ''
      )
        .then(() => {
          setDocument({ ...document, bookmark: 0 } as IDocument);
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks.filter(
                (el: IBookmark) =>
                  el.objectid !== document?.documentid &&
                  el.objectid !== document?.documenid &&
                  el.objectid !== document?.teaserid &&
                  el.objectid !== document?.objectid
              ),
            ]);
          }
          setInfo('Aus Lesezeichen entfernt');
        })
        .catch((err) => console.log('Oops: ', err));
    } else {
      addBookmark(
        'DOCUMENT',
        document?.documentid || document?.documenid || document?.teaserid || document?.objectid || ''
      )
        .then(() => {
          setDocument({ ...document, bookmark: 1 } as IDocument);
          if (!!bookmarks) {
            setBookmarks([
              ...bookmarks,
              {
                objectid: document?.documentid || document?.documenid || document?.teaserid || document?.objectid,
                objecttype: 'DOCUMENT',
                title: document?.title,
              },
            ]);
          }
          setInfo('Zu Lesezeichen hinzugefügt');
        })
        .catch((err) => console.log('Oops: ', err));
    }
  };

  const handleBackToOverview = () => {
    history.goBack();
  }

  return (
    <div style={{ marginTop: headerHeight }}>
      <div className='document__header'>
        <img src={docHeaderPic} alt="" className='document__header_pic' />
        <h1
          className='document__header_title'
          style={{
            whiteSpace: deviceUpXs ? 'nowrap' : 'normal',
            top: deviceUpXs ? '21%' : '22%',
            transform: deviceUpXs ? 'translate(-50%, -21%)' : 'translate(-50%, -22%)',
          }} 
          dangerouslySetInnerHTML={{
            __html:hub?.displayname ||
            (hub?.name ? humanizeTagTitleDocTitle(hub.name) : '')
}}
        />
          

      </div>
      {/*<OverlayTeaser teasers={teasers} />*/}
      <Container
        className='document__content'
        style={{
          maxWidth: '1232px',
          padding: deviceXs ? '24px' : '40px 100px',
          marginBottom: '60px',
          backgroundColor: 'white',
          borderRadius: deviceXs ? '0' : '20px',
          boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 15px 0px',
        }}
      >
        <Grid
          container
          direction={deviceXs ? 'column' : 'row'}
          justify="space-between"
          alignItems='center'
          style={{ marginBottom: deviceXs ? '30px' : '60px' }}
        >
          <Grid item>
            {isLoading ? (
              <Skeleton width={310} height='2rem' style={{ marginBottom: '1.5rem' }} />
            ) : (
              !!document?.tags && (
                <div>
                  {document?.tags?.map((tag: string, key: number) => (
                    <Chip
                      key={key}
                      color='primary'
                      label={humanizeTagTitle(tag)}
                      size='medium'
                      style={{ marginRight: '0.5rem' }}
                    />
                  ))}
                </div>
              )
            )}
          </Grid>
          <Grid item style={{ margin: '4px 0' }}>
            <Link to='/' className='breadcrumb-button'>
              Startseite
            </Link>
            {(isLoading || (document?.contenthubs && document?.contenthubs[0]?.hubid)) && (
              <Link
                to={!isLoading ? { pathname: `/hub/${document?.contenthubs[0]?.hubid}` } : {}}
                className='breadcrumb-button'
              >
                <ChevronRightIcon />{' '}
                {isLoading ? (
                  <Skeleton width={100} height='2rem' style={{ display: 'inline-block' }} />
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html:
                        hub?.displayname ||
                        (hub?.name ? humanizeTagTitle(hub.name) : 'Hub'),
                    }}
                  ></span>
                )}
              </Link>
            )}
          </Grid>
        </Grid>
        {isLoading ? (
  <Skeleton width={320} height='5rem' style={{ marginBottom: '1.75rem' }} />
) : (
  (document?.sponsorship !== null && document?.sponsorship !== undefined) ? (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
      <img src={`data:image/png;base64, ${document.sponsorship.image}`} alt="Sponsorship Image" style={{ maxWidth: '100%', height: 'auto', maxHeight: '50px', marginBottom: '5px' }} />
      <div style={{ maxWidth: '200px' }} dangerouslySetInnerHTML={{ __html: document.sponsorship.description }} />
    </div>
  ) : null
)}
        {isLoading ? (
          <Skeleton width={320} height='5rem' style={{ marginBottom: '1.75rem' }} />
        ) : (
          <h2 className='article_title' dangerouslySetInnerHTML={{ __html: document?.title || '' }}></h2>
        )}

        <Divider style={{ marginBottom: deviceXs ? '10px' : '30px' }}></Divider>

        <Grid container justify='space-between' style={{ marginBottom: '30px' }} alignItems="flex-start">
          <Grid item>
            {isLoading ? (
              <Skeleton width={300} height='2rem' style={{ margin: '1rem 0' }} />
            ) : (
              (!!document?.source || !!document?.documenttype) && (
                <div className='article_label-wrapper doc'>
                  <span className='article_label doc'>
                    <span className='article_text-secondary'>von</span>{' '}{document?.source}{' '}
                    {!!document?.documenttype && !!document?.source && <span className='article_text-secondary'>in</span>}{' '}
                    {document?.documenttype}
                  </span>
                </div>
              )
            )}
          </Grid>
          <Grid item>
            <div className='article_info-wrapper doc'>
              {isLoading ? (
                <Skeleton width={280} height='2rem' />
              ) : (
                <span className='article_text-secondary doc'>
                  <span className='article_button' onClick={handleBookmark}>
                    {(
                      !!bookmarks
                        ? bookmarks?.find(
                          (el: IBookmark) =>
                            el.objectid === document?.documentid ||
                            el.objectid === document?.documenid ||
                            el.objectid === document?.teaserid ||
                            el.objectid === document?.objectid
                        )
                        : !!document?.bookmark
                    ) ? (
                      <>
                        <span style={{ marginRight: '12px' }}>Lesezeichen entfernen</span><BookmarkFilledIcon />
                      </>
                    ) : (
                      <>
                        <span className='' style={{ marginRight: '12px' }}>Zu Lesezeichen hinzufügen</span><BookmarkIcon />
                      </>
                    )}
                  </span>
                </span>)}
            </div>
          </Grid>
        </Grid>

        {isLoading ? (
          <Skeleton variant='rect' width='100%' height={500} style={{ marginBottom: '2rem' }} />
        ) : (
          document?.image && (
            <div
              style={{
                width: '100%',
                maxWidth: '100%',
                marginBottom: '2rem',
                overflow: 'hidden',
              }}
            >
              <img
                src={`data:image/png;base64, ${document?.image}`}
                alt=''
                style={{
                  width: '100%',
                  objectFit: 'cover',
                  borderRadius: '5px',
                }}
              />
            </div>
          )
        )}

        {!isLoading &&
          (document?.mediatype === 'PDF' ? (
            <PDF src={document?.content} />
          ) : document?.mediatype === 'IMAGE' ? (
            <img src={`data:image/png;base64,${document?.content}`} alt="Image" />
          ) : (
            <div className='content-wrapper' dangerouslySetInnerHTML={{ __html: `${document?.content || ''}` }} />
          ))}



{document?.documentAttachment && document.documentAttachment
    .filter(attachment => attachment.mediaType !== 'HTML')
    .length > 0 && (
          <div style={{ border: '1px solid #ccc', background: '#f9f9f9', padding: '10px', borderRadius: '5px', marginBottom: '20px' }}>
            <div><h2 style={{ marginBottom: '10px' }}>Relevante Dokumente</h2></div>
            <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))', gap: '10px' }}>
            {document?.documentAttachment
    .filter(attachment => attachment.mediaType !== 'HTML')
    .map((el: any, key: number) => (
                <div className='article_link' key={key} style={{ border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}>
                  <Link
                    to={!isLoading ? { pathname: `/document/${el.attachmentId}` } : {}}
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    {el.mediaType === "pdf" && <FaFilePdf style={{ marginRight: '5px' }} />} {/* Add PDF icon */}
                    {el.name}
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className='landing_buttons-wrapper'>
          <LandingButtonB  variant='contained' color='secondary' onClick={handleBackToOverview}>
            Zur&uuml;ck zur &Uuml;bersicht
          </LandingButtonB>
        </div>

        <div className='breadcrumbs_doc_bottom_wrapper'>
          <Link to='/' className='breadcrumb-button'>
            Startseite
          </Link>
          {(isLoading || (document?.contenthubs && document?.contenthubs[0]?.hubid)) && (
            <Link
              to={!isLoading ? { pathname: `/hub/${document?.contenthubs[0]?.hubid}` } : {}}
              className='breadcrumb-button'
            >
              <ChevronRightIcon />{' '}
              {isLoading ? (
                <Skeleton width={100} height='2rem' style={{ display: 'inline-block' }} />
              ) : (
                <span
                  dangerouslySetInnerHTML={{
                    __html:
                      document?.contenthubs[0]?.displayname ||
                      (document?.contenthubs[0]?.name ? humanizeTagTitle(document?.contenthubs[0]?.name) : 'Hub'),
                  }}
                ></span>
              )}
            </Link>
          )}
        </div>
      </Container>
    </div>
  );
};

export default Document;
