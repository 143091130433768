import { Button, Container, Divider, Grid, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import HubCard from '../reusable/HubCard';
import { IHub } from '../types';
import { Link } from 'react-router-dom';
import HubCardSkeleton from '../reusable/HubCardSkeleton';
import { range } from '../../context/actions/common';
import { getPopularHubs } from '../../context/actions/hubs';
import _ from 'lodash';
import { LandingButton, LandingButtonB } from '../landing/Landing';

const MostPopular = () => {
  const classes=LandingButton();
  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  const [popularHubs, setPopularHubs] = useState<IHub[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getAllHubs = async () => {
      await getPopularHubs().then((res: any) => {
        const data: any = res?.data;
        setPopularHubs(data);
      });

      setIsLoading(false);
    };

    getAllHubs();
  }, []);

  return (
    <div className='section__dark_new'>
      <Container maxWidth='lg' style={{paddingBottom: '3rem'}}>
        <div className='section_title-wrapper'>
          <h3
            className='section_title section_title_new_large'
            style={{color: `${theme.palette.primary.main}`}}
          >Unsere Themen</h3>
        </div>
        <Divider style={{height: '4px', marginBottom: '2.5rem', backgroundColor: 'white'}}/>
        <Grid container spacing={deviceSm ? 2 : 4}>
          {isLoading
            ? range(4).map((el: number) => (
                <Grid item xs={6} sm={3} key={el}>
                  <HubCardSkeleton />
                </Grid>
              ))
            : _.orderBy(popularHubs, ['score', 'sortorder'], ['asc', 'asc'])
                .slice(0, 4)
                .map((hub: IHub, key) => (
                  <Grid item xs={6} sm={3} key={key}>
                    <HubCard large={false} hub={hub} />
                  </Grid>
                ))}
          {(isLoading || popularHubs?.length > 4) && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}
          {isLoading
            ? range(4).map((el: number) => (
                <Grid item xs={6} sm={3} key={el}>
                  <HubCardSkeleton />
                </Grid>
              ))
            : _.orderBy(popularHubs, ['score', 'sortorder'], ['asc', 'asc'])
                .slice(4, 8)
                .map((hub: IHub, key) => (
                  <Grid item xs={6} sm={3} key={key}>
                    <HubCard large={false} hub={hub} />
                  </Grid>
                ))}
        </Grid>
        <div className='landing_buttons-wrapper' style={{marginTop: '2rem'}}>
          <Link
            to={{
              pathname: '/hubs/all',
              state: {
                parentPopularHubs: popularHubs,
              },
            }}
          >
            <LandingButtonB  variant='contained' color='secondary'>
              Alles sehen
            </LandingButtonB>
          </Link>
        </div>
      </Container>
    </div>
  );
};

export default MostPopular;
