import React from 'react'
import { useMemo, useRef, useState, useEffect } from 'react';
import { Button, Container, Grid, Typography, useMediaQuery, useTheme, withStyles, makeStyles } from '@material-ui/core';
import useRect from '../../hooks/useRect';
import sectionFiveImgOne from '../../media/landing/section-5-img-1.png';
import sectionFiveImgTwo from '../../media/landing/section-5-img-2.png';
import sectionFiveImgThree from '../../media/landing/section-5-img-3.png';
import sectionFourImg from '../../media/landing/landing-vidal.jpg';
import IconLeitmed_BG from '../../media/landing/IconLeitmed_BG.svg';
import LandingHub from './LandingHub';
import LandingTypographyComponent from './LandingTypography';
import { getGuestTeasers } from '../../context/actions/hubs';
import { ITeaser } from '../types';

import RegisterNow from './RegisterNow';

export const LandingButton = makeStyles({
  root: {
    borderRadius: '30px',
    fontSize: '1rem',
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    '& *': {
      fontFamily: 'inherit',
      textTransform: 'initial',
    },
  },
});

export const LandingButtonB = withStyles({
  root: {
    borderRadius: '30px',
    fontSize: '1rem',
    fontFamily: 'Noto Sans',
    fontWeight: 'bold',
    '& *': {
      fontFamily: 'inherit',
      textTransform: 'initial',
    },
  },
})(Button);


interface Props {}

const Landing = (props: Props) => {
  const callToAction = useRef(null);
  const callToActionDimensions = useRect(callToAction);
  const [callToActionHeight, setCallToActionHeight] = useState<number>(0);
  const [teasers, setTeasers] = useState<ITeaser[]>([]);

  useMemo(() => {
    setCallToActionHeight(callToActionDimensions.height);
  }, [callToActionDimensions.height]);
  let mounted = useRef(true);
  useEffect(() => {
    mounted.current = true;
    const getTeasers = async () => {
      try {
        const response: any = await getGuestTeasers();
        const data = response?.data;
        console.debug("data:::");
        console.debug(data);
        const status = response?.status;
        if (mounted.current) {
          if (status === 200) {
            setTeasers(data);
          }
        }
      } catch (err) {
          console.error('Oops: ', err);
        }
      }
    getTeasers();
    console.debug("teasers:");
    console.debug(teasers);
    return () => {
      mounted.current = false;
    };    
  });

  const theme = useTheme();
  const deviceSm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <main>
        <section
          className='landing_top-section'
          style={{
            paddingBottom: `calc(18rem + ${callToActionHeight * 0.4}px)`,
            paddingTop: '3rem',
          }}
        >
          <Container maxWidth='lg'>
            <Grid container spacing={4} alignContent='flex-start'>
              <Grid item xs={12} sm={9} md={6}>
                <LandingTypographyComponent
                  variant='h1'
                  className='landing_text__light'
                  style={{ marginBottom: '2rem' }}
                >
                  Wissen für die beste Therapie
                </LandingTypographyComponent>

                <LandingTypographyComponent variant='h4' className='landing_text__light' style={{marginBottom: '2rem'}}>
                  Spannende Einblicke in die Forschung, aktuelle Therapieleitlinien und neue Studien: LeitMed ist das Wissensportal für Ärzte.
                </LandingTypographyComponent>

                <RegisterNow withText={true} isLoginBtnWhite={true} />
              </Grid>
            </Grid>
          </Container>
        </section>

        {/* <section className='landing_second-section'>
          <Container maxWidth='lg'>
            <Grid container spacing={0} justify='center'>
              <Grid item xs={12} sm={10} md={8}>
                <div
                  ref={callToAction}
                  className='landing_call-to-action landing_box__padding'
                  style={{ marginBottom: `calc(8rem - ${callToActionHeight * 0.4}px - 32px)` }}
                >
                  <RegisterNow />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={0} justify='center'>
              <Grid item xs={12} sm={9} md={6}>
                <LandingTypography variant='h4' className='landing_text__blue' style={{ marginBottom: '3rem' }}>
                  In Zusammenarbeit mit einem wissenschaftlichen Beirat berichtet die Fachredaktion aus Medizinern und
                  Pharmazeuten über Leitlinien, innovative und neue Arzneimitteltherapien, Studien, Fallberichte,
                  Arzneimittelinformationen und vieles mehr.
                </LandingTypography>

                <LandingTypography variant='h4' className='landing_text__blue' style={{ marginBottom: '3rem' }}>
                  Dabei sind die Wissensgebiete modular aufgebaut, sodass Sie schnell und einfach die Informationen
                  finden, die Sie für die Entscheidung zur Therapie und Medikation Ihres Patienten benötigen.
                </LandingTypography>

                <LandingTypography variant='h4' color='primary'>
                  Leitlinien / Evidenz / Interdisziplinär / Therapie
                </LandingTypography>
              </Grid>
            </Grid>
          </Container>
        </section> */}

        <section className='landing_fifth-section'>
          <Container
            maxWidth='lg'
            style={{
              marginTop: '-18rem',
              position: 'relative'
            }}
          >
            <LandingTypographyComponent
              variant='h3'
              color='primary'
              style={{
                marginBottom: '3rem',
                textAlign: 'center',
                textTransform: 'uppercase',
                color: 'white'
              }
            }>
              Unsere aktuellen Themen:
            </LandingTypographyComponent>
            <Grid container spacing={3} justify='center'>
              {teasers.map((teaser:ITeaser, index:number) => (
              <LandingHub
                key={index} // Use the index or a unique property of the teaser as the key
                hubId=''
                img={`data:image;base64,${teaser.teaserimage}`} // Add base64 prefix
                title={teaser.teasertitle}
                content={teaser.teaserdesc ?? '...'}
              />
  ))}
</Grid>            
          </Container>
        </section>

        <section className='landing_fourth-section' style={deviceSm ? { paddingTop: '5rem' } : {}}>
          <img
            className="landing_fourth-section__bg-img"
            src={IconLeitmed_BG}
            alt="leimed background icon"
          />
          <Container maxWidth='lg'>
            <Grid container spacing={4} justify='center'>
              <Grid item xs={12} sm={9} md={6}>
                <div className='landing_box__padding'>
                  {/* <LandingTypography variant='h4' color='primary' style={{ marginBottom: '2rem' }}>
                    LOREM IPSUM DOLOR SIT AMET
                  </LandingTypography> */}
                  <LandingTypographyComponent  variant='h1' color='secondary' style={{ marginBottom: '2rem' }}>
                    Vidal MMI Germany
                  </LandingTypographyComponent>
                  <LandingTypographyComponent  variant='body1' color='textPrimary'>
                    „Wir sind Experte für die Kommunikation mit Ärzten. Wir bieten qualitativ hochwertige
                    Arzneimittelinformationen für den Praxis/Klinik-Alltag und sind zugleich Plattform für medizinisches
                    und pharmazeutisches Fachwissen/Nachrichten. Gemeinsam mit unserer Fachredaktion und in
                    Zusammenarbeit mit einem wissenschaftlichen Beirat bieten wir Informationen, die dem Arzt einen
                    Wissensvorsprung bieten.“
                  </LandingTypographyComponent>
                </div>
              </Grid>

              <Grid item xs={12} sm={9} md={6}>
                <div className='landing_img-wrapper__box landing_box' style={!deviceSm ? { marginTop: '-10rem' } : {}}>
                  <img src={sectionFourImg} alt='' />
                  <div className="landing_img_gradient"></div>
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>

        <section className='landing_seventh-section'>
          <div
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '100%',
              backgroundColor: '#c5e6f8',
              opacity: 0.95,
            }}
          ></div>
          <Container maxWidth='lg'>
            <Grid container spacing={0} justify='center'>
              <Grid item xs={12} sm={10} md={8}>
                <div className='landing_call-to-action__bottom landing_box__padding'>
                  <RegisterNow />
                </div>
              </Grid>
            </Grid>
          </Container>
        </section>
      </main>
    </>
  );
};

export default Landing;
