import React, { useState, useRef, MouseEvent, useEffect, ChangeEvent } from 'react';
import {
  CropFreeRounded,
  GetAppRounded,
  PictureInPictureRounded,
  SettingsRounded,
  VolumeDownRounded,
  VolumeOffRounded,
  VolumeUpRounded,
} from '@material-ui/icons';
import { ReactComponent as PlayIcon } from '../../media/icons/play.svg';
import { ClickAwayListener, Fade, Grow, Paper, Popper } from '@material-ui/core';
import { isSafari } from 'react-device-detect';
import { useFullScreen } from 'react-browser-hooks';

interface Props {
  src?: string;
  poster?: string;
}

const VideoPlayer = ({ src, poster }: Props) => {
  const [paused, setPaused] = useState<boolean>(true);
  const [muted, setMuted] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(100);
  const [duration, setDuration] = useState<number>(0);
  const [currentTime, setCurrentTime] = useState<any>(0);
  const [processValue, setProcessValue] = useState<any>(0);

  const [openSettingsMenu, setOpenSettingsMenu] = useState<boolean>(false);

  const [volumeButtonHover, setVolumeButtonHover] = useState<boolean>(false);
  const [volumeProgressHover, setVolumeProgressHover] = useState<boolean>(false);

  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLElement>(null);
  const videoProgressRef = useRef<HTMLInputElement>(null);
  const videoVolumeRef = useRef<HTMLInputElement>(null);
  const volumeButtonRef = useRef<HTMLButtonElement>(null);
  const settingsButtonRef = useRef(null);

  const { fullScreen } = useFullScreen();

  const setMetadata = (e: any) => {
    setDuration(e.target.duration);
    setCurrentTime(e.target.currentTime);
    setProcessValue((100 / e.target.duration) * e.target.currentTime);
  };

  useEffect(() => {
    const node: any = videoContainerRef.current;
    if (node) node.setAttribute('data-fullscreen', fullScreen);
  }, [fullScreen]);

  useEffect(() => {
    const volumeNode = videoVolumeRef.current;
    if (volumeNode) {
      volumeNode.style.background =
        'linear-gradient(to right, #a32f88 0%, #a32f88 ' +
        volumeNode.value +
        '%, #d1d8e1 ' +
        volumeNode.value +
        '%, #d1d8e1 100%)';
    }
  }, [videoVolumeRef.current]);

  useEffect(() => {
    const progressNode = videoProgressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #a32f88 0%, #a32f88 ' +
        progressNode.value +
        '%, #d1d8e1 ' +
        progressNode.value +
        '%, #d1d8e1 100%)';
    }
    setProcessValue((100 / duration) * currentTime);
    if (duration === currentTime) setPaused(true);
  }, [duration, currentTime]);

  useEffect(() => {
    const node = videoRef.current;
    if (node && !paused) setInterval(() => setCurrentTime(node.currentTime), 10);
  }, [paused]);

  const convertSeconds = (secs: number) => {
    let time = new Date(secs * 1000).toISOString().substr(14, 5);
    return time;
  };

  const handlePlay = (e: MouseEvent<HTMLButtonElement | HTMLVideoElement | HTMLDivElement>) => {
    if (videoRef.current) {
      if (paused) {
        videoRef.current.play();
        setPaused(false);
      } else {
        videoRef.current.pause();
        setPaused(true);
      }
    }
  };

  const handleRewind = (e: ChangeEvent<HTMLInputElement>) => {
    const node = videoRef.current;
    if (node) {
      setCurrentTime((duration / 100) * parseInt(e.target.value, 10));
      node.currentTime = (duration / 100) * parseInt(e.target.value, 10);
    }

    const progressNode = videoProgressRef.current;
    if (progressNode) {
      progressNode.style.background =
        'linear-gradient(to right, #a32f88 0%, #a32f88 ' +
        progressNode.value +
        '%, #d1d8e1 ' +
        progressNode.value +
        '%, #d1d8e1 100%)';
    }
  };

  const handleMute = (e: MouseEvent<HTMLButtonElement>) => {
    if (videoRef.current) {
      if (muted) {
        videoRef.current.muted = false;
        setMuted(false);
      } else {
        videoRef.current.muted = true;
        setMuted(true);
      }
    }
  };

  const handleVolume = (e: ChangeEvent<HTMLInputElement>) => {
    const node = videoRef.current;
    if (node) {
      setVolume(parseInt(e.target.value, 10));
      node.volume = parseInt(e.target.value, 10) / 100;
      if (parseInt(e.target.value, 10) === 0) {
        setMuted(true);
      } else {
        setMuted(false);
      }
    }

    const volumeNode = videoVolumeRef.current;
    if (volumeNode) {
      volumeNode.style.background =
        'linear-gradient(to right, #a32f88 0%, #a32f88 ' +
        volumeNode.value +
        '%, #d1d8e1 ' +
        volumeNode.value +
        '%, #d1d8e1 100%)';
    }
  };

  const handleFullscreen = () => {
    const node: any = videoContainerRef.current;
    const document: any = window.document;
    if (node) {
      if (fullScreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        }
      } else {
        if (node.requestFullscreen) {
          node.requestFullscreen();
        } else if (node.webkitRequestFullScreen) {
          node.webkitRequestFullScreen();
        } else if (node.mozRequestFullScreen) {
          node.mozRequestFullScreen();
        } else if (node.msRequestFullscreen) {
          node.msRequestFullscreen();
        }

        // const rfs =
        //   node['requestFullscreen'] ||
        //   node['webkitRequestFullScreen'] ||
        //   node['mozRequestFullScreen'] ||
        //   node['msRequestFullscreen'];
        // rfs.call(node);
      }
    }
  };

  const handlePiP = () => {
    setOpenSettingsMenu(false);
    const node: any = videoRef.current;
    if (node) {
      if (node.requestPictureInPicture) {
        node.requestPictureInPicture();
      } else if (node.webkitRequestPictureInPicture) {
        node.webkitRequestPictureInPicture();
      } else if (node.mozRequestPictureInPicture) {
        node.mozRequestPictureInPicture();
      } else if (node.msRequestPictureInPicture) {
        node.msRequestPictureInPicture();
      }
    }
  };

  return (
    <figure id='video-player' ref={videoContainerRef}>
      {paused && (
        <div className='video_gradient' onClick={handlePlay}>
          <div
            className='play-icon-wrapper'
            style={{
              width: '5rem',
              height: '5rem',
              borderRadius: '2.5rem',
              opacity: 1,
              transition: 'all 0.3 ease-out 0s',
            }}
            onClick={handlePlay}
          >
            <PlayIcon style={{ width: '2rem', height: '2rem' }} />
          </div>
        </div>
      )}
      <video
        ref={videoRef}
        preload='metadata'
        onLoadedMetadata={setMetadata}
        onClick={handlePlay}
        width='100%'
        height='auto'
        poster={poster ? `data:image/png;base64, ${poster}` : ''}
      >
        <source src={src} type='video/mp4' />
        <p>
          Your browser doesn't support HTML5 video. You can <a href={src}>download</a> this video instead.
        </p>
      </video>
      <div id='video-controls' data-state='hidden' style={isSafari ? {} : { zIndex: 2147483647 }}>
        {/* <button className='control-btn' type='button' data-state='play' onClick={handlePlay}>
      {paused ? <PlayArrowRounded /> : <PauseRounded />}
    </button> */}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            padding: '0 0.5rem',
          }}
        >
          <Fade in={!fullScreen && (volumeButtonHover || volumeProgressHover)}>
            <div
              className='video_volume-wrapper'
              onMouseEnter={() => setVolumeProgressHover(true)}
              onMouseLeave={() => (!volumeButtonHover ? setVolumeProgressHover(false) : null)}
            >
              <input
                className='video_progress video_volume-progress'
                ref={videoVolumeRef}
                value={volume}
                type='range'
                min='0'
                max='100'
                step='1'
                onChange={handleVolume}
              />
            </div>
          </Fade>
        </div>
        <div className='video_buttons-wrapper'>
          <span className='media-time'>
            {convertSeconds(currentTime)} / {convertSeconds(duration)}
          </span>
          <button
            className='control-btn video_mute-button'
            type='button'
            data-state='mute'
            onClick={handleMute}
            ref={volumeButtonRef}
            onMouseEnter={() => setVolumeButtonHover(true)}
            onMouseLeave={() => (!volumeProgressHover ? setVolumeButtonHover(false) : null)}
          >
            {muted ? <VolumeOffRounded /> : volume < 60 ? <VolumeDownRounded /> : <VolumeUpRounded />}
          </button>
          <button
            className='control-btn'
            type='button'
            onClick={() => setOpenSettingsMenu(true)}
            ref={settingsButtonRef}
          >
            <SettingsRounded />
          </button>
          <Popper
            open={openSettingsMenu}
            anchorEl={settingsButtonRef.current}
            role={undefined}
            placement='top'
            transition
            disablePortal
            style={{ zIndex: 1 }}
          >
            {({ TransitionProps }) => (
              <Grow {...TransitionProps}>
                <Paper elevation={1}>
                  <ClickAwayListener onClickAway={() => setOpenSettingsMenu(false)}>
                    <div
                      style={{
                        padding: '0.75rem',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                      }}
                    >
                      <a
                        className='action-button__default'
                        href={src}
                        download
                        onClick={() => setOpenSettingsMenu(false)}
                        style={{ lineHeight: '1rem' }}
                      >
                        <GetAppRounded style={{ margin: '0 0.5rem -7px 0' }} />
                        Download video
                      </a>
                      {isSafari && (
                        <span
                          className='action-button__default'
                          onClick={handlePiP}
                          style={{ marginTop: '0.5rem', lineHeight: '1rem' }}
                        >
                          <PictureInPictureRounded style={{ margin: '0 0.5rem -7px 0' }} />
                          Picture in picture
                        </span>
                      )}
                    </div>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
          <button className='control-btn' type='button' data-state='go-fullscreen' onClick={handleFullscreen}>
            <CropFreeRounded />
          </button>
        </div>

        <input
          className='video_progress'
          ref={videoProgressRef}
          value={processValue}
          type='range'
          min='0'
          max='100'
          step='0.1'
          onChange={handleRewind}
          style={{ marginTop: '10px' }}
        />
      </div>
    </figure>
  );
};

export default VideoPlayer;
